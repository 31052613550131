import React from 'react';
import { ICountry } from '../../models/country';

import './carbon_footprint.scss';

interface ICarbonFootprint {
  country: ICountry
}

const CarbonFootprint = ({country}: ICarbonFootprint) => (
  <div className="carbon-footprint">
    The <strong>average</strong> footprint in the <span className={`carbon-footprint-country carbon-footprint-${country.code}`}>{country.country}</span> is {country.low.toFixed(2)} tonnes.
  </div>
);

export { CarbonFootprint };