import React from "react";
import { ICountry, COUNTRY_LIST, IUserCountry } from "../../models/country";
import { Button, FormControl } from "@material-ui/core";

import "./country_selector.scss";
import { AutocompleteCountryList } from "../forms/autocomplete_country_list";

export enum SelectorMode {
  SinglePage = "single",
  ExtendedPage = "extended",
}

interface ICountrySelector {
  title?: string;
  subtitle?: string;
  formTitle?: string;
  buttonText?: string;
  mode?: SelectorMode;
  country: ICountry;
  enabled: boolean;
  onChange: (country: ICountry) => void;
  onClick: () => void;
}

const CountrySelector = ({
  title = "Which country are you based in?",
  subtitle = "We use country data from UC Berkeley and the World Bank to baseline your carbon footprint.",
  mode = SelectorMode.SinglePage,
  buttonText = "Get Started",
  formTitle,
  country,
  enabled,
  onChange,
  onClick,
}: ICountrySelector) => {
  const onValueChanged = (country: IUserCountry) => {
    const countries = COUNTRY_LIST.filter((c) => country.code === c.code);

    if (countries.length > 0) {
      onChange(countries[0]);
    }
  };

  const options = COUNTRY_LIST.map((c) => ({
    name: c.country,
    code: c.code,
  }));

  const value = { name: country.country, code: country.code };

  return (
    <div className={`country-selector country-selector-${mode}`}>
      <h3 className={"country-selector-title"}>{title}</h3>
      <p className={"country-selector-subtitle"}>{subtitle}</p>
      {formTitle !== undefined && (
        <h4 className={"country-selector-form-title"}>{formTitle}</h4>
      )}
      <FormControl className={"country-selector-select"}>
        <AutocompleteCountryList
          country={value}
          countries={options}
          onCountryChange={onValueChanged}
        />
      </FormControl>
      <Button
        onClick={onClick}
        disabled={!enabled}
        type="submit"
        variant="contained"
        className="btn btn-earthly"
      >
        {buttonText}
      </Button>
    </div>
  );
};

export { CountrySelector };
