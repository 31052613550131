import React, { useEffect } from "react";
import { Login as LoginForm } from "../components/forms/login_form";
import { AccountTree } from "../components/accounts/account_tree";
import { PageTitle } from "../components/page/page-title";
import { Section } from "../components/page/section";
import { Page } from "../components/page";

const Login = function () {
  const title = "Login | Earthly";

  useEffect(() => {
    document.title = `${title} Earthly`;
  }, [title]);

  return (
    <Page className="login">
      <Section>
        <div className="col-sm col-md-6">
          <PageTitle title={"Log in"} className={"trees"} />
        </div>
      </Section>
      <Section>
        <div className="col-sm col-md-6">
          <LoginForm includeForgottenLink={true} includeSignup={true} />
          <AccountTree />
        </div>
      </Section>
    </Page>
  );
};

export { Login };
