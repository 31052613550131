import React from "react";
import styled from "styled-components";
import { ProcessStep } from "./process-step";
import processOne from "../../icons/process_2.png";
import processTwo from "../../icons/process_1.png";
import processThree from "../../icons/process_3.png";


const Container = styled.div`
  margin-bottom: 50px;

  @media (min-width: 805px) {
    display: flex;
    justify-content: center;
  }
`;

const Process = () => (
  <Container>
    <ProcessStep
      image={processOne}
      number={"1"}
      title={"Calculate your footprint"}
      text={
        "We have used established benchmarks to make it easy for you to estimate your team’s footprint."
      }
    />
    <ProcessStep
      image={processTwo}
      number={"2"}
      title={"Invest in Nature"}
      text={
        "Remove your team's footprint by selecting your preferred nature-based project for your monthly investment."
      }
    />
    <ProcessStep
      image={processThree}
      number={"3"}
      title={"Show your    impact"}
      text={
        "Promote your positive impact! Share your Earthly dashboard on your website, Social channels, etc."
      }
    />
  </Container>
);

export { Process };
