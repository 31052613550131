
import axios, { AxiosResponse, AxiosError } from 'axios';
import { Api } from '../api';
import { IPayment } from '../../models/payment';

export interface IPaymentMethodRequest {
  paymentMethodId?: string;
  token: string;
}

export interface IPaymentMethodResponse {
  object: string;
  data: IPayment[];
  has_more: boolean;
  url: string;
}


export interface IPaymentSecretRequest {
  token: string;
  projectId: string;
  amount: number
}

export interface IPaymentSecretResponse {
  client_secret: string;
}

export interface IPaymentsService {
  fetch(request: IPaymentMethodRequest): Promise<IPaymentMethodResponse>;
  fetchById(request: IPaymentMethodRequest): Promise<IPaymentMethodResponse>;
  intent(request: IPaymentSecretRequest): Promise<IPaymentSecretResponse>;
}


class Payments extends Api implements IPaymentsService {

  public fetch(request: IPaymentMethodRequest): Promise<IPaymentMethodResponse> {
    return axios.get(`${this.baseUrl}/api/v2/stripe/payment-methods`, {
      headers: {
        'Authorization': request.token,
      }
    })
      .then((rsp: AxiosResponse) => (rsp.data as unknown as IPaymentMethodResponse))
      .catch((err: AxiosError) => (
        Promise.reject({
          ...{status: err.response?.status},
          ...{message: err.response?.data.messages[0]}
        })
      ));
  }

  public fetchById(request: IPaymentMethodRequest): Promise<IPaymentMethodResponse> {
    return axios.get(`${this.baseUrl}/api/v2/stripe/payment-method-data?paymentMethodId=${request.paymentMethodId}`, {
      headers: {
        'Authorization': request.token,
      }
    })
      .then((rsp: AxiosResponse) => (rsp.data as unknown as IPaymentMethodResponse))
      .catch((err: AxiosError) => (
        Promise.reject({
          ...{status: err.response?.status},
          ...{message: err.response?.data.messages[0]}
        })
      ));
  }

  public intent(request: IPaymentSecretRequest): Promise<IPaymentSecretResponse> {
    return axios.post(`${this.baseUrl}/api/v2/stripe/payment-intent/project`, {
      projectId: request.projectId,
      amount: request.amount
    }, {
      headers: {
        'Authorization': request.token,
      }
    })
      .then((rsp: AxiosResponse) => (rsp.data as unknown as IPaymentSecretResponse))
      .catch((err: AxiosError) => (
        Promise.reject({
          ...{status: err.response?.status},
          ...{message: err.response?.data.messages[0]}
        })
      ));
  }
}

export { Payments }
