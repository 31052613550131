import React from "react";
import { useSelector } from "react-redux";
import { ProjectImage } from "../projects/project-image";
import {
  selectSupportedProjects as selectSupportedProjectsProfile,
} from "../../slices/profileSlice";
import { selectAttemptLogin } from '../../slices/authenticationSlice';
import "./support.scss";

interface IProjectDisplayProps {
  title: string;
  image: string;
  url: string;
}

const ProjectDisplay = ({ title, image, url }: IProjectDisplayProps) => (
  <div className="project-support">
    <div className="project-support-display">
      <h4 className="project-support-title">{title}</h4>
      <ProjectImage src={image} alt={title} className="project-support-image" />
    </div>
    <a className="project-support-link" href={url}>
      See Project
    </a>
  </div>
);

const Support = () => {

  const supportedProjects = useSelector(selectSupportedProjectsProfile);

  const isLoggingIn = useSelector(selectAttemptLogin);

  if (supportedProjects.length === 0) {
    return <div />;
  }
  return (
    <div className="profile-support">
      <h3 className="profile-support-title">Projects supported</h3>
      <div className="profile-support-projects">
        {supportedProjects
          .map((p) => (
            <ProjectDisplay
              key={p.id}
              title={p.displayName}
              image={p.image}
              url={`https://earthly.org/projects/${p.wordpressUrl}`}
            />
          ))}
      </div>
      {isLoggingIn && (
      <div className="profile-support-title">
        <a href="https://earthly.org/products/buy-now" className="btn">
          Support other Projects
        </a>
      </div>
      )}
    </div>
  );
};

export { Support };
