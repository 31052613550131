import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENV === 'dev-msw') {
  const { worker } = require('./mocks/browser')
  worker.start()
  worker.printHandlers()
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//TODO: update readme:
// where to add new handlers +
// infrastructure logic,
// what script to run,
// what happens when script is executed;
// MSW resources
