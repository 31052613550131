import React from "react";
import { PATHS } from "../routes";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuthenticated } from "../../slices/authenticationSlice";

interface IPublicRouteProps {
  children: any;
  path: string;
  exact?: boolean;
}

const PublicRoute = ({ children, ...rest }: IPublicRouteProps) => {
  const authenticated = useSelector(selectAuthenticated);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: PATHS.ACCOUNT,
            }}
          />
        )
      }
    />
  );
};

export { PublicRoute };
