import React from 'react';

interface IColumnProps {
  children: any,
  className?: string
};

const Column = ({children, className}: IColumnProps) => {
  return (
    <div className={`${className ? className : 'col'}`}>
      { children }
    </div>
  );
};

export { Column }