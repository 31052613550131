import React from "react";
import { Dropdown } from "./dropdown";
import { useSelector } from "react-redux";
import { selectAuthenticated } from "../../../slices/authenticationSlice";

interface INavigation {
  showMenu: boolean;
}

export const Navigation = ({ showMenu }: INavigation) => {
  const isLoggedIn = useSelector(selectAuthenticated);

  return (
    <nav
      className={`Navigation_mobileNavigation__1d5Rc ${
        showMenu ? "Navigation_mobileNavigationOpen__3qxv1" : ""
      }`}
      role="menu"
    >
      <div className="Navigation_bottomLinks__xXMF0" role="presentation">
        {isLoggedIn && <Dropdown />}
        {!isLoggedIn && (
          <>
            <a
              role="menuitem"
              className="Navigation_bottomLinks__PrimaryAction__1ikV-"
              href="https://earthly.org/climate-solutions"
            >
              Get Started
            </a>
            <a
              role="menuitem"
              className="Navigation_bottomLinks__SecondaryAction__1oGO8"
              href={`${process.env.REACT_APP_TEAMS_URL}/login`}
            >
              Log In
            </a>
          </>
        )}
      </div>
    </nav>
  );
};
