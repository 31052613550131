import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  Auth,
  IRegistrationRequest,
  IRegistrationResponse,
  IRegistrationWithClientResponse
} from '../services/auth';

enum RegistrationState {
  PENDING = 'pending',
  REQUEST = 'request',
  REGISTERED = 'registered',
  FAILED = 'failed'
}

interface Address {
  street: string;
  city: string;
  zipCode: string;
  country: string;
}

export interface IRegistrationAndClientRequest extends IRegistrationRequest {
  businessName: string,
  address: Address,
  productData: {
    teams: {
      buckets: {
        high: string;
        low: string;
        med: string;
      },
      projectIds: string[]
    }
  },
  public: boolean
}

interface AuthenticationState {
  state: RegistrationState;
  error: string | null;
  uuid: string | null;
}

const initialState: AuthenticationState = {
  state: RegistrationState.PENDING,
  error: null,
  uuid: null,
};

export interface IRegistrationPayload {
  token: string,
  uuid: string,
  client: string,
  created: boolean
}

const usersApi = new Auth();

export const registerUser = createAsyncThunk(
  'registration/user',
  async (request: IRegistrationRequest) => {
    const response: IRegistrationResponse = await usersApi.register(request)
    return response.userId;
  }
);

export const registerUserAndClient = createAsyncThunk(
  'registration/full',
  async (request: IRegistrationAndClientRequest): Promise<IRegistrationPayload> => {

    if (request.password !== request.confirmPassword) {
      return Promise.reject({
        message: 'Passwords do not match'
      });
    }
    const response: IRegistrationWithClientResponse = await usersApi.registerWithClient({
      user: {
        username: request.username,
        password: request.password,
        firstName: request.firstName,
        lastName: request.lastName,
      },
      client: {
        displayName: request.businessName,
        address: {
          street: request.address.street,
          city: request.address.city,
          zipCode: request.address.zipCode,
          country: request.address.country,
        },
        productData : {
          teams: {
            buckets: {
              high: request.productData.teams.buckets.high,
              med: request.productData.teams.buckets.med,
              low: request.productData.teams.buckets.low
            },
            projectIds: request.productData.teams.projectIds
          }
        },
        public: request.public
      }
    });

    const auth = await usersApi.authenticate(request.username, request.password);

    return {
      token: auth.authToken,
      uuid: response.userId,
      client: response.clientId,
      created: response.created
    };
  }
)

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    reset: state => {
      state.error = null;
      state.state = RegistrationState.PENDING;
    },
  },
  extraReducers: builder => {
    builder.addCase(registerUser.pending, (state) => {
      state.state = RegistrationState.REQUEST
      state.error = null;
    });
    builder.addCase(registerUser.fulfilled, (state, action: PayloadAction<string>) => {
      state.state = RegistrationState.REGISTERED;
      state.uuid = action.payload;
      state.error = null;
    });
    builder.addCase(registerUser.rejected, (state, action: any) => {
      state.state = RegistrationState.FAILED;
      state.error = action.error.message;
    });

    builder.addCase(registerUserAndClient.pending, (state) => {
      state.state = RegistrationState.REQUEST
      state.error = null;
    });
    builder.addCase(registerUserAndClient.fulfilled, (state, action: PayloadAction<IRegistrationPayload>) => {
      state.state = RegistrationState.REGISTERED;
      state.uuid = action.payload.uuid;
      state.error = null;
    });
    builder.addCase(registerUserAndClient.rejected, (state, action: any) => {
      state.state = RegistrationState.FAILED;
      state.error = action.error.message;
    });
  }
});

export const { reset } = registrationSlice.actions;

export const selectPending = (state: RootState): boolean => state.registration.state === RegistrationState.PENDING;

export const selectRegistered = (state: RootState): boolean => state.registration.state === RegistrationState.REGISTERED;

export const selectRegistrationLogin = (state: RootState): boolean => state.registration.state === RegistrationState.REQUEST || state.registration.state === RegistrationState.REGISTERED;

export const selectError = (state: RootState): string | null => state.registration.error;

export default registrationSlice.reducer;
