import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PATHS } from "../../routes";
import { Avatar } from "../../profile/avatar";
import { Username } from "../../profile/username";
import { selectClient } from "../../../slices/clientSlice";
import { useSelector } from "react-redux";

import "./dropdown.scss";
import {IClient} from "../../../services/clients";

// Sep 13, 2023, 00:00 BST
const cutOffDateInMillis = 1694559600000;

function V3ProfileRedirect({client}: { client: IClient | undefined }) {
  return (
    <a
      href={`https://dashboard.earthly.org/${client?.id}`}
      className={"link-icon icon-profile"}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="link-inner">Go to dashboard</span>
    </a>
  )
}

function V2ProfileRedirect(props: { client: IClient }) {
  return <>
    {props.client.profileUrlSlug && (
        <Link
          to={`${PATHS.PROFILE}/${props.client.profileUrlSlug}/edit`}
          className={"link-icon icon-profile"}
        >
          <span className="link-inner">Go to dashboard</span>
        </Link>
    )}
  </>;
}

const Dropdown = () => {
  const [open, setOpen] = useState(false);

  const client = useSelector(selectClient);

  let timer: NodeJS.Timeout;

  const toggleClass = () => {
    setOpen(!open);
  };

  const onLeave = () => {
    timer = setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  const onEnter = () => {
    clearTimeout(timer);
    setOpen(true);
  };

  const menuClass = open ? "dropdown-open" : "dropdown-close";

  return (
    <div
      className={`dropdown ${menuClass}`}
      onClick={() => toggleClass()}
      onMouseOut={() => onLeave()}
      onMouseOver={() => onEnter()}
      tabIndex={0}
    >
      <span className="avatar menu-toggle">
        <button className="dropdown-button">
          <Avatar size="small"/>
          <Username/>
        </button>

        <ul className={"submenu"}>
          <li>
          {client && cutOffDateInMillis > client.createdAt ?
            <V2ProfileRedirect client={client}/>
              :
            <V3ProfileRedirect client={client} />
          }
          </li>
          <li>
            <Link to={PATHS.ACCOUNT} className={"link-icon icon-account"}>
              <span className="link-inner">Account settings</span>
            </Link>
          </li>
          <li>
            <Link to={PATHS.LOGOUT} className={"link-icon icon-logout"}>
              <span className="link-inner">Logout</span>
            </Link>
          </li>
        </ul>
      </span>
    </div>
  );
};

export { Dropdown };
