import React from 'react';
import icons from '../../icons/account-trees.svg';

import './account_tree.scss';

const AccountTree = () => (
    <div className='account-trees'>
        <img src={icons} alt='Tree' />
    </div>
);

export { AccountTree };