
import axios, { AxiosResponse } from 'axios';
import { Api } from './api';
import { onError } from '../utils/error-response';

export interface IRegistrationRequest {
  username: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
}

export interface IRegistrationWithClientRequest {
  user: {
    username: string;
    password: string;
    firstName: string;
    lastName: string;
  },
  client: {
    displayName: string,
    address: {
      street: string;
      city: string;
      zipCode: string;
      country: string;
    },
    productData: {
      teams: {
        buckets: {
          high: string;
          low: string;
          med: string;
        },
        projectIds: string[]
      }
    },
    public: boolean
  }
}

export interface IRegistrationResponse {
  userId: string;
  referralRewards: boolean;
  created: boolean;
  createdAt: number;
}

export interface IRegistrationWithClientResponse {
  userId: string;
  clientId: string;
  created: boolean;
  createdAt: number;
}

export interface IChangePasswordRequest {
  token: string;
  currentPassword: string;
  newPassword: string;
}

interface IAuthService {
  authenticate(email: string, password: string): Promise<IAuthResponse>;
  register(request: IRegistrationRequest): Promise<IRegistrationResponse>;
  registerWithClient(request: IRegistrationWithClientRequest): Promise<IRegistrationWithClientResponse>;
  changePassword(request: IChangePasswordRequest): Promise<void>;
  acceptInvite(user: string, password: string, token: string): Promise<IAuthResponse>;
}

export interface IAuthResponse {
  authToken: string
}

class Auth extends Api implements IAuthService {

  public register(request: IRegistrationRequest): Promise<IRegistrationResponse> {
    return axios.post(`${this.baseUrl}/api/v2/auth/register`, request)
    .then((rsp: AxiosResponse) => rsp.data as IRegistrationResponse)
    .catch(onError);
  }

  public registerWithClient(request: IRegistrationWithClientRequest): Promise<IRegistrationWithClientResponse> {
    return axios.post(`${this.baseUrl}/api/v2/auth/register-with-client`, request)
    .then((rsp: AxiosResponse) => rsp.data as IRegistrationWithClientResponse)
    .catch(onError);
  }

  public authenticate(email: string, password: string): Promise<IAuthResponse> {
    return axios.post(`${this.baseUrl}/api/v2/auth/login`, {
      username: email,
      password
    })
    .then((rsp: AxiosResponse) => ({authToken: rsp.headers['x-auth-token']}))
    .catch(onError);
  }

  public acceptInvite(user: string, password: string, token: string): Promise<IAuthResponse> {
    return axios.post(`${this.baseUrl}/api/v2/auth/invite/${user}/accept`, {
      password,
      inviteToken: token
    })
      .then((rsp: AxiosResponse) => ({authToken: rsp.headers['x-auth-token']}))
      .catch(onError);
  }

  public recoverPassword(newPassword: string, token: string) {
    return axios.put(`${this.baseUrl}/api/v2/auth/password/recovery/verify/${token}`, {
      newPassword,
      token: token
    })
      .then((rsp) => (rsp))
      .catch(onError);
  }

  public resetPassword(email: string) {
    return axios.post(`${this.baseUrl}/api/v2/auth/password/recovery/email`, {
      username: email
    })
      .then((rsp: AxiosResponse) => (rsp))
      .catch(onError);
  }

  public changePassword(request: IChangePasswordRequest): Promise<void> {
    return axios.put(`${this.baseUrl}/api/v2/auth/password/change`, {
      currentPassword: request.currentPassword,
      newPassword: request.newPassword
    }, {
      headers: {
        'Authorization': request.token,
      }
    })
    .then(() => undefined)
    .catch(onError);
  }

}

export { Auth }
