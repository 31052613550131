import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { COUNTRY_LIST, DEFAULT_COUNTRY, ICountry } from '../models/country';
import { Country, ICountryResponse } from '../services/country';

enum CountrySelectionStatus {
  IDLE = 'idle',
  COUNTRY_SELECTED = 'country_selected',
  COUNTRY_FETCH = 'country_fetch',
  COUNTRY_DETECTED = 'country_detected',
  COUNTRY_FAILED = 'failed'
}

interface OnboardingState {
  country: ICountry;
  state: CountrySelectionStatus;
  error: string | null;
}

const initialState: OnboardingState = {
  country: DEFAULT_COUNTRY,
  state: CountrySelectionStatus.IDLE,
  error: null
};

const countriesApi = new Country();

export const fetchCountry = createAsyncThunk(
  'countries/fetch',
  async () => {
    const response: ICountryResponse = await countriesApi.fetch();
    return response;
  }
);


export const onboardingSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    country: (state, action: PayloadAction<ICountry>) => {
      state.state = CountrySelectionStatus.COUNTRY_SELECTED;
      state.country = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCountry.pending, (state) => {
      state.state = CountrySelectionStatus.COUNTRY_FETCH;
      state.error = null;
    });
    builder.addCase(fetchCountry.fulfilled, (state, action: PayloadAction<ICountryResponse>) => {
      if (action.payload.country === 'ZZ') {
        state.state = CountrySelectionStatus.COUNTRY_FAILED;
        state.error = 'Cannot detect your country, please select from the list below';
        state.country = DEFAULT_COUNTRY;
        return;
      }

      const country = COUNTRY_LIST.filter(c => c.code.toLowerCase() === action.payload?.country?.toLowerCase())[0];

      if (country === undefined) {
        state.state = CountrySelectionStatus.COUNTRY_DETECTED;
        state.error = 'Your country is not currently available, please select from the list below';
        state.country = DEFAULT_COUNTRY;
        return;
      }
      state.state = CountrySelectionStatus.COUNTRY_DETECTED;
      state.country = country;
    });
    builder.addCase(fetchCountry.rejected, (state, action: any) => {
      state.state = CountrySelectionStatus.COUNTRY_FAILED;
      state.error = 'Unable to detect your country, please select from the list below';
      state.country = DEFAULT_COUNTRY;
    });
  }
});

export const { country } = onboardingSlice.actions;

export const selectIsPending = (state: RootState): boolean => state.country.state === CountrySelectionStatus.IDLE;

export const selectCountry = (state: RootState): ICountry => state.country.country;

export default onboardingSlice.reducer;
