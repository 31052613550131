import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { PATHS } from "../components/routes";
import { Page } from "../components/page";
import { Section } from "../components/page/section";
import { PageTitle } from "../components/page/page-title";
import { Progress } from "../components/progress/progress";
import { COUNTRY_LIST } from "../models/country";
import { CarbonFootprint } from "../components/onboarding/carbon_footprint";
import { EmployeeSelector } from "../components/onboarding/employee_selector";
import { EmployeeLevel } from "../slices/employeeSlice";
import { useSelector, useDispatch } from "react-redux";
import { EmployeeTotal } from "../components/onboarding/employee_total";
import { Column } from "../components/page/column";
import { Button } from "@material-ui/core";
import { NotFound } from "./not-found";
import { withRouter, RouteComponentProps } from "react-router-dom";
import logos from "../icons/client_logos.png";
import logosMobile from "../icons/client_logos_mobile.png";
import {
  employee,
  selectEmployeeCount,
  selectTotalEmployees,
  employeeChosen,
} from "../slices/employeeSlice";

import {
  selectIsPending,
  selectCountry,
  country as updateCountry,
} from "../slices/countrySlice";

interface IClimatePositiveTeamProps {
  country: string;
}

const ClimatePositiveTeamPage = ({
  match,
}: RouteComponentProps<IClimatePositiveTeamProps>) => {
  const title = "Invest in Nature";

  const dispatch = useDispatch();

  const history = useHistory();

  const country = useSelector(selectCountry);

  const counts = useSelector(selectEmployeeCount);

  const total = useSelector(selectTotalEmployees);

  const requiresCountry = useSelector(selectIsPending);

  const [notFound, setNotFound] = useState(true);

  useEffect(() => {
    document.title = `${title} | ${country.country} | Earthly`;

    if (!requiresCountry) {
      setNotFound(false);
      return;
    }
    const query = COUNTRY_LIST.filter(
      (c) => c.code.toLowerCase() === match.params.country.toLowerCase()
    )[0];

    if (query === undefined) {
      return;
    }
    setNotFound(false);
    dispatch(updateCountry(query));
  }, [country, requiresCountry, dispatch, match]);

  const onEmployeeSelected = useCallback(
    (level: EmployeeLevel, value: number) => {
      dispatch(employee({ level, value }));
    },
    [dispatch]
  );

  const onNext = useCallback(() => {
    dispatch(employeeChosen());
    history.push(PATHS.PROJECT);
  }, [dispatch, history]);

  if (notFound === true) {
    return <NotFound />;
  }

  return (
    <Page className="onboarding">
      <Section>
        <Progress current={1} />
      </Section>
      <Section>
        <Column className="col-sm col-md-8">
          <PageTitle className={"onboarding"}>
            <h2>
            Invest in Nature
            </h2>
          </PageTitle>
        </Column>
      </Section>
      <Section className="pb-8">
        <Column className="col-sm col-md-8">
          <CarbonFootprint country={country} />
        </Column>
      </Section>

      <Section>
        <Column className="col-lg-4">
          <EmployeeSelector
            level={EmployeeLevel.AVERAGE}
            title={"Average"}
            subtitle={"Remote Worker"}
            points={[
              "2 hours driving per week",
              "6 hours flying annually (3 short haul)",
            ]}
            value={counts.average}
            onChange={onEmployeeSelected}
          />
        </Column>
        <Column className="col-lg-4">
          <EmployeeSelector
            level={EmployeeLevel.MEDIUM}
            title={"Medium"}
            subtitle={"City-Hopper"}
            points={[
              "10 hours driving per week",
              "12 hours flying annually (6 short haul, 1 long haul)",
            ]}
            value={counts.medium}
            onChange={onEmployeeSelected}
          />
        </Column>
        <Column className="col-lg-4">
          <EmployeeSelector
            level={EmployeeLevel.HIGH}
            title={"High"}
            subtitle={"Globe-Trotter"}
            points={[
              "20 hours driving per week",
              "24 hours flying annually (12 short haul, 2 long haul)",
            ]}
            value={counts.high}
            onChange={onEmployeeSelected}
          />
        </Column>
      </Section>
      <Section centred={false} className="pt-8">
        <Column>
          <EmployeeTotal total={total} />
        </Column>
      </Section>
      <Section>
        <Column>
          <Button
            onClick={onNext}
            disabled={total === 0}
            type="submit"
            variant="contained"
            className="btn btn-earthly"
          >
            Next Step
          </Button>
        </Column>
      </Section>
      <Section centred={false} className="pt-8 pb-8">
        <Column>
        <div className="client-logos-wrapper">
          <h2>Trusted by businesses all over the world</h2>
          
            <img src={logos} alt="Client Logos" className="img-client-logos" />
            <img src={logosMobile} alt="Client Logos" className="img-client-logos-mobile" />
          </div>
        </Column>
      </Section>
    </Page>
  );
};

const ClimatePositiveTeam = withRouter(ClimatePositiveTeamPage);

export { ClimatePositiveTeam };
