import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  shape: {
    borderRadius: 0
  },
  palette: {
    primary: {
      light: '#E2FAEB',
      main: '#338252',
    }
  }
});

export { theme }
