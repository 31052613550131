import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Auth } from '../services/auth';

enum PasswordState {
  IDLE = 'idle',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed',
}

interface IRecoverPasswordRequest {
  password: string,
  confirmPassword: string,
  token: string,
}

interface PassowrdChangeState {
  state: PasswordState;
  error: string | null;
}

const initialState: PassowrdChangeState = {
  state: PasswordState.IDLE,
  error: null
};

const usersApi = new Auth();

export const recoverPassword = createAsyncThunk(
  'authentication/passwordRecovery',
  async ({password, token, confirmPassword}: IRecoverPasswordRequest) => {

    if (password !== confirmPassword) {
      return Promise.reject('Your passwords do not match');
    }
    return await usersApi.recoverPassword(password, token);
  }
);

export const recoverPasswordSlice = createSlice({
  name: 'recover-password',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(recoverPassword.pending, state => {
      state.state = PasswordState.REQUEST;
      state.error = null;
    });
    builder.addCase(recoverPassword.fulfilled, state => {
      state.state = PasswordState.SUCCESS;
      state.error = null;
    });
    builder.addCase(recoverPassword.rejected, (state, action: any) => {
      state.state = PasswordState.FAILED;
      state.error = action.error.message;
    });
  }
});

export const selectIdle = (state: RootState): boolean => state.recoverPassword.state === PasswordState.IDLE;

export const selectSuccess = (state: RootState): boolean => state.recoverPassword.state === PasswordState.SUCCESS;

export const selectRequest = (state: RootState): boolean => state.recoverPassword.state === PasswordState.REQUEST;

export const selectError = (state: RootState): string | null => state.recoverPassword.error;

export default recoverPasswordSlice.reducer;
