import React from "react";

interface IMobileToggle {
  open: boolean;
  onClick: () => void;
}

export const MenuToggle = ({ open, onClick }: IMobileToggle) => (
  <button
    className={`Navigation_mobileMenuToggle__3yjKh ${
      open ? "Navigation_mobileMenuToggleOpen__1wxdJ" : ""
    }`}
    onClick={onClick}
    title="Open Menu"
    tabIndex={0}
    type="button"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <span>Open Menu</span>
  </button>
);
