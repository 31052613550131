
import { Api } from './api';
import axios, { AxiosResponse } from 'axios';
import { onError } from '../utils/error-response';

export interface ICreateClientParams {
  token: string;
  client: {
    displayName: string;
    address: {
      street: string;
      city: string;
      zipCode: string;
      country: string;
    },
    productData: {
      teams: {
        buckets: {
          high: string;
          low: string;
          med: string;
        },
        projectIds: string[]
      }
    },
    public: boolean
  }
}

export interface ICreateClientParamsWithToken extends ICreateClientParams {
  token: string
}

interface IProductItem {
  projectIds: string[]
}

interface IProductCatalog {
  api: IProductItem,
  business: IProductItem,
  product: IProductItem,
  teams: IProductItem
}

export interface IClient {
  entityName: string;
  id: string;
  createdAt: number;
  lastUpdated: number;
  name: string;
  displayName: string;
  enabledProducts: string[];
  ownerId: string;
  image?: string,
  projectIds: string[],
  teamBuckets: {
    low: number,
    med: number,
    high: number
  },
  productData: IProductCatalog,
  address: {
    street: string;
    city: string;
    zipCode: string;
    country: string;
  },
  public: boolean;
  profileUrlSlug: string;
}

export interface ICreateClientResponse {
  id: string,
  created: boolean,
}

export interface IUpdateClientRequest {
  client: IClient;
  token: string;
}

export interface IUpdateImageRequest {
  image: File,
  name: string,
  client: IClient,
  token: string,
}

export interface IUpdateImageResponse {
  createdAt: number,
  identifier: string,
  path: string,
  uploaded: boolean
}


interface IClientService {
  create(request: ICreateClientParams, token: string): Promise<ICreateClientResponse>;
  get(token: string): Promise<IClient>;
  update(request: IClient, token: string): Promise<void>;
}

class ClientService extends Api implements IClientService {

  public create(request: ICreateClientParams, token: string): Promise<ICreateClientResponse> {
    return axios.post(`${this.baseUrl}/api/v2/clients`, {
      displayName: request.client.displayName,
      'address.city': request.client.address.city,
      'address.street': request.client.address.street,
      'address.zipCode': request.client.address.zipCode,
      'address.country': request.client.address.country
    }, {
      headers: {
        'Authorization': token,
      }
    })
    .then((rsp: AxiosResponse) => {
      return {
        id: rsp.data.id,
        created: rsp.data.created
      };
    })
    .catch(onError);
  }

  public get(token: string): Promise<IClient> {
    return axios.get(`${this.baseUrl}/api/v2/clients`, {
      headers: {
        'Authorization': token,
      }
    })
    .then((rsp: AxiosResponse) => rsp.data as IClient)
    .catch(onError);
  }

  public update(request: IClient, token: string): Promise<void> {
    return axios.put(`${this.baseUrl}/api/v2/clients/${request.id}`, request, {
      headers: {
        'Authorization': token,
      }
    })
    .then((rsp: AxiosResponse) => undefined)
    .catch(onError);
  }

  public updateImage(req: IUpdateImageRequest): Promise<IUpdateImageResponse> {
    const formData = new FormData()
    formData.append(
      'image',
      req.image,
      req.name
    );
    return axios.post(`${this.baseUrl}/api/v2/clients/image`, formData, {
      headers: {
        'Authorization': req.token,
      }
    })
    .then((rsp: AxiosResponse) => (rsp.data as unknown as IUpdateImageResponse))
    .catch(onError);
  }
}

export { ClientService }
