
import axios, { AxiosResponse } from 'axios';
import { onError } from '../utils/error-response';

interface ICountryService {
  fetch(): Promise<ICountryResponse>;
}

export interface ICountryResponse {
    country: string,
    location: string,
}

class Country implements ICountryService {

  public fetch(): Promise<ICountryResponse> {
    return axios.get(process.env.REACT_APP_LOCATION_URL + '/location')
      .then((rsp: AxiosResponse) => (rsp.data as unknown as ICountryResponse))
      .catch(onError);
  }

}

export { Country }
