import React, {
  Component,
  FormEvent,
  ChangeEvent,
  useState,
  useCallback,
} from "react";
import { RootState } from "../../app/store";
import { IUserCountry, USER_COUNTRY_LIST } from "../../models/country";
import {
  FormGroup,
  FormControlLabel,
  FormControl,
  Checkbox,
  Button,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { AlertSnackbar } from "../forms/alert_snackbar";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  selectError,
  registerUserAndClient,
  IRegistrationAndClientRequest,
} from "../../slices/registrationSlice";
import { IProject } from "../../models/project";
import { IEmployeeCounts } from "../../slices/employeeSlice";
import { selectCountry } from "../../slices/countrySlice";

import "./registration_form.scss";
import { AutocompleteCountryList } from "./autocomplete_country_list";

interface IRegistrationFormProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCountryChange: (country: IUserCountry) => void;
}

enum InputName {
  EMAIL = "email",
  PASSWORD = "password",
  CONFIRM_PASSWORD = "password-confirmation",
  BUSINESS_NAME = "business_name",
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  CITY = "city",
  POST_CODE = "post_code",
  COUNTRY = "country",
  TERMS_AND_CONDITIONS = "tandcs",
}

const Form = ({
  onSubmit,
  onChange,
  onCountryChange,
}: IRegistrationFormProps) => {
  const error = useSelector(selectError);

  const [valid, setValid] = useState(true);

  const [acceptTerms, setAcceptTerms] = useState(false);

  const country = useSelector(selectCountry);

  const onTermsAndConditionsChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAcceptTerms(event.target.checked as boolean);
      onChange(event);
    },
    [setAcceptTerms, onChange]
  );

  const validate = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      setValid(true);

      if (!acceptTerms) {
        setValid(false);
        event.preventDefault();
        return false;
      }
      onSubmit(event);
    },
    [setValid, acceptTerms, onSubmit]
  );

  return (
    <div className="registration-form">
      <h2 className="form-title">Personal Details</h2>
      {error !== null && <AlertSnackbar message={error} />}
      {valid === false && (
        <AlertSnackbar
          message={"You must accept the Terms and Conditions to register"}
        />
      )}
      <form onSubmit={validate}>
        <FormControl fullWidth={true}>
          <div className={"form-row"}>
            <TextField
              onChange={onChange}
              name="email"
              id="email"
              label="Email"
              variant="filled"
              type="email"
              autoComplete="username"
              className="registration-form-input"
            />
          </div>
          <div className={"form-row"}>
            <div className={"form-field"}>
              <TextField
                onChange={onChange}
                name="password"
                id="password"
                label="Password"
                variant="filled"
                autoComplete="current-password"
                type="password"
                className="registration-form-input"
              />
            </div>
            <div className={"form-field"}>
              <TextField
                onChange={onChange}
                name="password-confirmation"
                id="password-confirmation"
                label="Confirm Password"
                variant="filled"
                autoComplete="current-password"
                type="password"
                className="registration-form-input"
              />
            </div>
          </div>
          <div className={"form-row"}>
            <TextField
              onChange={onChange}
              name="business_name"
              id="business_name"
              label="Business Name / User Name"
              variant="filled"
              type="text"
              autoComplete="business"
              className="registration-form-input"
            />
          </div>
          <div className={"form-row"}>
            <div className={"form-field"}>
              <TextField
                onChange={onChange}
                name="first_name"
                id="first_name"
                label="First Name"
                variant="filled"
                type="text"
                autoComplete="first_name"
                className="registration-form-input"
              />
            </div>
            <div className={"form-field"}>
              <TextField
                onChange={onChange}
                name="last_name"
                id="last_name"
                label="Last Name"
                variant="filled"
                type="text"
                autoComplete="last_name"
                className="registration-form-input"
              />
            </div>
          </div>
          <div className={"form-row"}>
            <div className={"form-field"}>
              <TextField
                onChange={onChange}
                name="city"
                id="city"
                label="City"
                variant="filled"
                type="text"
                autoComplete="city"
                className="registration-form-input"
              />
            </div>
            <div className={"form-field"}>
              <TextField
                onChange={onChange}
                name="post_code"
                id="post_code"
                label="Postcode"
                variant="filled"
                type="text"
                autoComplete="post_code"
                className="registration-form-input"
              />
            </div>
          </div>
          <div className={"form-row"}>
            <FormControl className={"country-selector-select"}>
              <AutocompleteCountryList
                country={{ name: country.country, code: country.code }}
                countries={USER_COUNTRY_LIST}
                onCountryChange={onCountryChange}
              />
            </FormControl>
          </div>
          <div className={"form-row form-row-terms"}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={onTermsAndConditionsChange}
                    name={InputName.TERMS_AND_CONDITIONS}
                    color="primary"
                  />
                }
                label={
                  <span className="form-terms">
                    I agree with the{" "}
                    <a
                      className="form-terms-link"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={"https://earthly.org/terms-conditions/"}
                    >
                      Terms and Conditions
                    </a>
                  </span>
                }
              />
            </FormGroup>
          </div>
          <div className={"form-row"}>
            <Button
              type="submit"
              variant="contained"
              className="registration-form-btn btn"
            >
              Register your account
            </Button>
          </div>
        </FormControl>
      </form>
    </div>
  );
};

interface IRegistrationFormState {
  [InputName.EMAIL]: string;
  [InputName.PASSWORD]: string;
  [InputName.CONFIRM_PASSWORD]: string;
  [InputName.BUSINESS_NAME]: string;
  [InputName.FIRST_NAME]: string;
  [InputName.LAST_NAME]: string;
  [InputName.CITY]: string;
  [InputName.POST_CODE]: string;
  [InputName.COUNTRY]: string;
  [InputName.TERMS_AND_CONDITIONS]: boolean;
}

interface IRegistrationState extends IRegistrationFormState {
  error: null | Error;
}

interface IRegistrationProps {
  registerUserAndClient: (request: IRegistrationAndClientRequest) => void;
  project: IProject;
  employees: IEmployeeCounts;
}

class RegistrationComponent extends Component<
  IRegistrationProps,
  IRegistrationState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      [InputName.EMAIL]: "",
      [InputName.PASSWORD]: "",
      [InputName.CONFIRM_PASSWORD]: "",
      [InputName.BUSINESS_NAME]: "",
      [InputName.FIRST_NAME]: "",
      [InputName.LAST_NAME]: "",
      [InputName.CITY]: "",
      [InputName.POST_CODE]: "",
      [InputName.COUNTRY]: "",
      [InputName.TERMS_AND_CONDITIONS]: false,
      error: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
  }

  handleChange(event: ChangeEvent<HTMLInputElement>) {
    let value = event.target.value as string | boolean;
    const name = event.target.name as InputName;

    if (name === InputName.TERMS_AND_CONDITIONS) {
      value = event.target.checked as boolean;
    }
    this.setState({ [name]: value } as Pick<IRegistrationState, InputName>);
  }

  onCountryChange(country: IUserCountry) {
    this.setState({ [InputName.COUNTRY]: country.code });
  }

  handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    this.props.registerUserAndClient({
      username: this.state[InputName.EMAIL],
      password: this.state[InputName.PASSWORD],
      confirmPassword: this.state[InputName.CONFIRM_PASSWORD],
      firstName: this.state[InputName.FIRST_NAME],
      lastName: this.state[InputName.LAST_NAME],
      businessName: this.state[InputName.BUSINESS_NAME],
      address: {
        street: "",
        city: this.state[InputName.CITY],
        zipCode: this.state[InputName.POST_CODE],
        country: this.state[InputName.COUNTRY],
      },
      productData: {
        teams: {
          buckets: {
            high: `${this.props.employees.high}`,
            low: `${this.props.employees.average}`,
            med: `${this.props.employees.medium}`,
          },
          projectIds: [this.props.project.id],
        },
      },
      public: true,
    });
  }

  render() {
    return (
      <Form
        onSubmit={this.handleSubmit}
        onChange={this.handleChange}
        onCountryChange={this.onCountryChange}
      />
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  employees: state.employee.employees,
  project: state.project.project,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      registerUserAndClient,
    },
    dispatch
  );

export const RegistrationForm: any = connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-ignore
)(RegistrationComponent);
