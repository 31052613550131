import React, { useState, useEffect } from 'react';

import  './logo.scss';

interface ILogoProps {
    src: string;
    alt: string;
}

const PlaceHolderLogo = () => (
  <div className='profile-image'>
    <div className={`profile-image-content profile-image-failed`} />
  </div>
);

const Logo = ({src, alt}: ILogoProps) => {

  const [failed, setFailed] = useState(false);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const imageLoader = new Image();
    imageLoader.src = src;

    imageLoader.onload = () => {
      setLoaded(true);
    };

    imageLoader.onerror = () => {
      setFailed(true);
    }
  }, [src]);

  if (loaded === false && failed === true) {
    return (<PlaceHolderLogo />);
  }
  return (
    <div className={'profile-image'} style={{backgroundImage: `url(${src})`}}></div>
  );
};

export { Logo, PlaceHolderLogo };
