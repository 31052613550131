import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { AlertSnackbar } from "./alert_snackbar";
import { FormControl, Button, CircularProgress } from "@material-ui/core";
import { StripeTextField } from "./stripe-text-field";
import { selectError } from "../../slices/stripe/paymentSlice";

import {
  selectProject,
  selectAmount,
} from "../../slices/projectSelectionSlice";

import "./one-time-payment-form.scss";
import { AssetType } from "../../services/assets";
import axios from "axios";
import { selectToken } from "../../slices/authenticationSlice";

interface IOneTimePaymentFormProps {
  onComplete: () => void;
}

const OneTimePaymentForm = ({ onComplete }: IOneTimePaymentFormProps) => {
  const stripe = useStripe();

  const elements = useElements();

  const [processing, setProcessing] = useState<boolean>(false);

  const [paymentError, setPaymentError] = useState<string>();

  const error = useSelector(selectError);

  const project = useSelector(selectProject);

  const amount = useSelector(selectAmount);

  const token = useSelector(selectToken);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    if (!stripe || !elements || !token) {
      return;
    }
    setProcessing(true);
    const cardEl = elements.getElement(CardNumberElement);

    if (!cardEl) {
      setProcessing(false);
      return;
    }
    const result = await stripe.createPaymentMethod({
      type: "card",
      card: cardEl,
    });

    if (result.error) {
      setProcessing(false);
      setPaymentError(result.error.message);
      return;
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/v2/stripe/flow/one-off-invoice`,
        {
          projectId: project?.id,
          paymentMethodId: result?.paymentMethod?.id,
          amount:
            project?.asset.type === AssetType.TREE ? amount : amount * 1000,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data?.error) {
          setPaymentError(res.data?.error?.message);
        } else {
          if (res.data?.status === "paid") {
            onComplete();
          }
        }
        setProcessing(false);
      });
  };

  return (
    <div className="payment-form">
      <h2 className="form-title">Credit Card</h2>
      {error !== null && <AlertSnackbar message={error} />}
      {paymentError !== undefined && <AlertSnackbar message={paymentError} />}

      <form onSubmit={handleSubmit}>
        <FormControl fullWidth={true}>
          <div className={"form-row"}>
            <StripeTextField
              label="Card Number"
              inputProps={{
                options: {
                  showIcon: false,
                },
              }}
              stripeElement={CardNumberElement}
              variant={"filled"}
              className="payment-form-input"
            />
          </div>

          <div className={"form-row"}>
            <div className={"form-field"}>
              <StripeTextField
                label="Expiration date"
                stripeElement={CardExpiryElement}
                variant={"filled"}
                className="payment-form-input"
              />
            </div>
            <div className={"form-field"}>
              <StripeTextField
                label="CVC"
                stripeElement={CardCvcElement}
                variant={"filled"}
                className="payment-form-input"
              />
            </div>
          </div>

          <div className={"form-row"}>
            {!processing && (
              <Button
                className="btn btn-payment"
                type="submit"
                data-test-id={"submitPayment"}
                disabled={!stripe}
              >
                Confirm &amp; Pay
              </Button>
            )}
            {processing && (
              <Button
                type="submit"
                disabled={true}
                className="btn login-form-btn"
              >
                <CircularProgress />
              </Button>
            )}
          </div>
        </FormControl>
      </form>
    </div>
  );
};

export { OneTimePaymentForm };
