import React, { FunctionComponent } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { countryToFlag } from "../../utils/flags";
import { IUserCountry } from "../../models/country";
import { makeStyles } from "@material-ui/core/styles";

interface AutocompleteCountryListProps {
  country: IUserCountry;
  countries: IUserCountry[];
  onCountryChange: (country: IUserCountry) => void;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export const AutocompleteCountryList: FunctionComponent<AutocompleteCountryListProps> = ({
  country,
  countries,
  onCountryChange,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id="country-selector-select"
      options={countries}
      classes={{
        option: classes.option,
      }}
      disableClearable={true}
      fullWidth={true}
      defaultValue={country}
      onChange={(event: any, newValue: IUserCountry | null) => {
        if (newValue) {
          onCountryChange(newValue);
        }
      }}
      autoHighlight
      getOptionSelected={(option, value) => option.code === value.code}
      getOptionLabel={(option: IUserCountry) => option.name}
      renderOption={(option: IUserCountry) => (
        <React.Fragment>
          <span>{countryToFlag(option.code)}</span>
          {option.name}
        </React.Fragment>
      )}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label="Choose a country"
          variant="filled"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
