import React from "react";

import { Page } from "./index";
import { PageTitle } from "./page-title";
import { Section } from "./section";

const NotFound = () => (
  <Page className="register">
    <Section>
      <div className="col-sm">
        <PageTitle title={"404"} className={"trees"} />
      </div>
    </Section>
  </Page>
);

export { NotFound };
