import React from "react";

import { Routes as TeamRoutes, PATHS as TEAM_PATHS } from './routes/teams';
import { Routes as PurchaseRoutes, PATHS as PURCHASE_PATHS } from './routes/purchase';

const Routes = () => {
  if (process.env.REACT_APP_PURCHASE) {
    return <PurchaseRoutes />;
  }
  return <TeamRoutes />;
};

const PATHS = process.env.REACT_APP_PURCHASE && process.env.REACT_APP_PURCHASE === 'true' ? PURCHASE_PATHS : TEAM_PATHS;

export { Routes, PATHS };
