import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectToken } from "../../slices/authenticationSlice";
import { selectProfile, profile as fetch } from "../../slices/userSlice";

const Username = () => {
  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const profile = useSelector(selectProfile);

  useEffect(() => {
    if (profile === null && token !== null) {
      dispatch(fetch(token));
    }
  }, [dispatch, profile, token]);

  if (profile) {
    return <div className="name">{profile.firstName}</div>;
  }
  return <div />;
};

export { Username };
