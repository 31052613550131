import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  ISubscriptionRequest,
  StripeSubscriptions,
  IUpdateSubscriptionRequest,
  ISubscriptionCalculationRequest,
  IPendingSubscription,
} from '../../services/stripe/subscriptions';

export enum SubscriptionStatus {
  IDLE = 'idle',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed',
  CREATE = 'create',
  CREATE_SUCCESS = 'create_success',
  CREATE_FAILED = 'create_failed',
  CANCEL = 'cancel',
  CANCEL_SUCCESS = 'cancel_success',
  CANCEL_FAILED = 'cancel_failed',
  UPDATE = 'update',
  UPDATE_SUCESS = 'update_success',
  UPDATE_FAILED = 'update_failed',
}

interface PaymentCreation {
  state: SubscriptionStatus,
  error: string | null
}

interface SubscriptionState {
  subscription?: any;
  state: SubscriptionStatus;
  error: string | null;
  payment: PaymentCreation;
  calculation_state: SubscriptionStatus;
  pending_subscription?: IPendingSubscription;
}

const initialState: SubscriptionState = {
  state: SubscriptionStatus.IDLE,
  error: null,
  subscription: undefined,
  calculation_state: SubscriptionStatus.IDLE,
  payment: {
    state: SubscriptionStatus.IDLE,
    error: null
  }
};

interface ICreatePaymentRequest {
  token: string,
  stripe: any,
  element: any,
  projectId: string,
  country: string,
  buckets: {
    low: number,
    medium: number,
    high: number
  },
}

const subscriptionService = new StripeSubscriptions();

export const fetchSubscription = createAsyncThunk(
  'stripe/subscription/fetch',
  async (req: ISubscriptionRequest) => {
    return await subscriptionService.fetch(req);
  }
);

export const createPayment = createAsyncThunk(
  'stripe/payment/create',
  async (req: ICreatePaymentRequest) => {
    const { stripe, element } = req;

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: element
    });

    if (payload.error) {
      return Promise.reject(payload.error)
    }
    const subscription = await subscriptionService.create({
      token: req.token,
      paymentMethodId: payload.paymentMethod.id,
      projectId: req.projectId,
      country: req.country,
      buckets: {
        low: req.buckets.low,
        medium: req.buckets.medium,
        high: req.buckets.high
      }
    });

    if (subscription.actionRequired) {
      const verification = await handle3DSVerification(stripe, subscription)
      if (verification.isFailed) return Promise.reject(verification)
    }
    return {
      payment: payload,
      subscription: subscription
    };
  }
);

async function handle3DSVerification(stripe: any, subscription: any) {
  const { paymentIntent, error } = await stripe.confirmCardPayment(subscription.clientSecret)

  if (error) {
    return {
      isFailed: true,
      type: error.type,
      message: error.message
    }
  }

  if (paymentIntent?.status === "succeeded") return {
    isFailed: false,
  }

  return {
    isFailed: true,
    type: "unknown",
    message: "Unknown error. Please contact sales@earthly.org"
  }
}


export const calculateSubscription = createAsyncThunk(
  'stripe/subscription/calculate',
  async (req: ISubscriptionCalculationRequest) => {
    return await subscriptionService.calculate(req);
  }
);

export const cancelSubscription = createAsyncThunk(
  'stripe/subscription/cancel',
  async (req: ISubscriptionRequest) => {
    return await subscriptionService.cancel(req);
  }
);

export const updateSubscription = createAsyncThunk(
  'stripe/subscription/update',
  async (req: IUpdateSubscriptionRequest) => {
    return await subscriptionService.update(req);
  }
);

export const subscriptionSlice = createSlice({
  name: 'stripe/payment',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    }
  },
  extraReducers: builder => {
    // Fetch
    builder.addCase(fetchSubscription.pending, state => {
      state.state = SubscriptionStatus.REQUEST;
      state.error = null;
    });
    builder.addCase(fetchSubscription.fulfilled, state => {
      state.state = SubscriptionStatus.SUCCESS;
      state.error = null;
    });
    builder.addCase(fetchSubscription.rejected, (state, action: any) => {
      state.state = SubscriptionStatus.FAILED;
      state.error = action.error.message;
    });

    // Create
    builder.addCase(createPayment.pending, (state) => {
      state.payment.state = SubscriptionStatus.REQUEST;
      state.payment.error = null;
    });
    builder.addCase(createPayment.fulfilled, (state) => {
      state.payment.state = SubscriptionStatus.SUCCESS;
      state.payment.error = null;
    });
    builder.addCase(createPayment.rejected, (state, action: any) => {
      state.payment.state = SubscriptionStatus.FAILED;
      state.payment.error = action.error.message;
    });

    // Calculate
    builder.addCase(calculateSubscription.pending, (state) => {
      state.calculation_state = SubscriptionStatus.REQUEST;
      state.error = null;
    });
    builder.addCase(calculateSubscription.fulfilled, (state, action: PayloadAction<IPendingSubscription>) => {
      state.calculation_state = SubscriptionStatus.SUCCESS;
      state.pending_subscription = action.payload;
      state.error = null;
    });
    builder.addCase(calculateSubscription.rejected, (state, action: any) => {
      state.calculation_state = SubscriptionStatus.FAILED;
      state.error = action.error.message;
    });

    // Cancel
    builder.addCase(cancelSubscription.pending, (state) => {
      state.state = SubscriptionStatus.CANCEL;
      state.error = null;
    });
    builder.addCase(cancelSubscription.fulfilled, (state) => {
      state.state = SubscriptionStatus.CANCEL_SUCCESS;
      state.error = null;
    });
    builder.addCase(cancelSubscription.rejected, (state, action: any) => {
      state.state = SubscriptionStatus.CANCEL_FAILED;
      state.error = action.error.message;
    });

    // Update
    builder.addCase(updateSubscription.pending, (state) => {
      state.state = SubscriptionStatus.UPDATE;
      state.error = null;
    });
    builder.addCase(updateSubscription.fulfilled, (state) => {
      state.state = SubscriptionStatus.UPDATE_SUCESS;
      state.error = null;
    });
    builder.addCase(updateSubscription.rejected, (state, action: any) => {
      state.state = SubscriptionStatus.UPDATE_FAILED;
      state.error = action.error.message;
    });
  }
});

export const { reset } = subscriptionSlice.actions;

export const selectPending = (state: RootState): boolean => state.stripeSubscription.state === SubscriptionStatus.IDLE;

export const selectStatus = (state: RootState): SubscriptionStatus => state.stripeSubscription.state;

export const selectError = (state: RootState): string | null => state.stripeSubscription.error;

export const selectCancelRequest = (state: RootState): boolean => state.stripeSubscription.state === SubscriptionStatus.CANCEL;

export const selectCancelled = (state: RootState): boolean => state.stripeSubscription.state === SubscriptionStatus.CANCEL_SUCCESS;

export const selectCancellationFailed = (state: RootState): boolean => state.stripeSubscription.state === SubscriptionStatus.CANCEL_FAILED;

export const selectCalculationStatus = (state: RootState): string | null => state.stripeSubscription.calculation_state;

export const selectPendingSubscription = (state: RootState): undefined | IPendingSubscription => state.stripeSubscription.pending_subscription;

export const selectPaymentStatus = (state: RootState): SubscriptionStatus => state.stripeSubscription.payment.state;

export const selectPaymentError = (state: RootState): string | null => state.stripeSubscription.payment.error;

export const selectIsRequestingPayment = (state: RootState): boolean => state.stripeSubscription.payment.state === SubscriptionStatus.REQUEST;

export const selectPaymentAccepted = (state: RootState): boolean => state.stripeSubscription.payment.state === SubscriptionStatus.SUCCESS;

export const selectUpdateRequested = (state: RootState): boolean => state.stripeSubscription.state === SubscriptionStatus.UPDATE;

export const selectUpdateSuccess = (state: RootState): boolean => state.stripeSubscription.state === SubscriptionStatus.UPDATE_SUCESS;

export default subscriptionSlice.reducer;
