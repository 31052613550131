
import axios, { AxiosResponse, AxiosError } from 'axios';
import { IInvoice } from '../../models/invoice';
import { Api } from '../api';

export interface IRetryInvoiceRequest {
  paymentMethodId: string;
  invoiceId: number;
}

export interface IRetryInvoiceResponse {

}

export interface IInvoiceRequest {
  token: string;
}

export interface IInvoiceResponse {
  data: IInvoice[],
  has_more: boolean,
  object: string,
  url: string,
}

export interface IInvoiceService {
  retryInvoice(request: IRetryInvoiceRequest): Promise<IRetryInvoiceResponse>;
  fetch(request: IInvoiceRequest): Promise<IInvoiceResponse>;
}


class Invoice extends Api implements IInvoiceService {

  public retryInvoice(request: IRetryInvoiceRequest): Promise<IRetryInvoiceResponse> {
    return axios.post(`${this.baseUrl}/api/v2/stripe/retry-invoice`, request)
      .then((rsp: AxiosResponse) => (rsp.data as unknown as IRetryInvoiceResponse))
      .catch((err: AxiosError) => (
        Promise.reject({
          ...{status: err.response?.status},
          ...{message: err.response?.data.messages[0]}
        })
      ));
  }

  public fetch(request: IInvoiceRequest): Promise<IInvoiceResponse> {
    return axios.get(`${this.baseUrl}/api/v2/stripe/list-invoices`, {
      headers: {
        'Authorization': request.token,
      }
    })
      .then((rsp: AxiosResponse) => (rsp.data as unknown as IInvoiceResponse))
      .catch((err: AxiosError) => (
        Promise.reject({
          ...{status: err.response?.status},
          ...{message: err.response?.data.messages[0]}
        })
      ));
  }
}

export { Invoice }
