
import React from 'react';
import { Alert } from './alert';
import { Snackbar, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

export enum AlertSnackbarState {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
}

export interface IAlertSnackbarProps {
  message: string;
  autoHideDuration?: number;
  severity?: AlertSnackbarState
}

const SlideTransition = (props: TransitionProps) =>  <Slide {...props} direction="up" />;

const AlertSnackbar = ({
  message,
  autoHideDuration = 6000,
  severity = AlertSnackbarState.ERROR
}: IAlertSnackbarProps) => {

  const [open, setOpen] = React.useState(true);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      TransitionComponent={SlideTransition}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export { AlertSnackbar };