
import axios, { AxiosResponse, AxiosError } from 'axios';
import { Api } from '../api';

export interface ICreateSubscriptionRequest {
  token: string;
  projectId: string;
  paymentMethodId: string;
  buckets: {
    low: number,
    medium: number,
    high: number
  },
  country: string;
}

export interface ICreateSubscriptionResponse {
  clientSecret: string;
  actionRequired: boolean;

}

export interface ISubscriptionRequest {
  subscriptionId: string;
  token: string,
}

export interface ICancelSubscriptionResponse {

}

export interface IGetSubscriptionResponse {

}

export interface ISubscriptionCalculationRequest {
  projectId: string;
  buckets: {
    low: number,
    medium: number,
    high: number
  },
  country: string;
}

export interface ISubscriptionCalculationRequest {

}

export interface IUpdateSubscriptionRequest {
  token: string;
  subscriptionId: string;
  projectId: string;
  buckets: {
    low: number,
    medium: number,
    high: number
  },
  country: string;

}

export interface IUpdateSubscriptionResponse {

}

export interface IPendingSubscription {
  highOffset: number,
  lowOffset: number,
  mediumOffset: number,
  monthlyPrice: number,
  totalCo2Offset: number
}

interface IStripeSubscriptionService {
  create(request: ICreateSubscriptionRequest): Promise<ICreateSubscriptionResponse>;
  fetch(request: ISubscriptionRequest): Promise<IGetSubscriptionResponse>;
  calculate(request: ISubscriptionCalculationRequest): Promise<IPendingSubscription>;
  cancel(request: ISubscriptionRequest): Promise<ICancelSubscriptionResponse>;
  update(request: IUpdateSubscriptionRequest): Promise<IUpdateSubscriptionResponse>;
}

class StripeSubscriptions extends Api implements IStripeSubscriptionService {

  public create(request: ICreateSubscriptionRequest): Promise<ICreateSubscriptionResponse> {
    return axios.post(`${this.baseUrl}/api/v2/stripe/create-subscription`, {
      paymentMethodId: request.paymentMethodId,
      projectId: request.projectId,
      country: request.country,
      buckets: {
        low: request.buckets.low,
        medium: request.buckets.medium,
        high: request.buckets.high
      }
    }, {
      headers: {
        'Authorization': request.token,
      }
    })
      .then((rsp: AxiosResponse) => (rsp.data as unknown as ICreateSubscriptionResponse))
      .catch((err: AxiosError) => (
        Promise.reject({
          ...{status: err.response?.status},
          ...{message: err.response?.data.messages[0]}
        })
      ));
  }

  public calculate(request: ISubscriptionCalculationRequest): Promise<IPendingSubscription> {
    return axios.post(`${this.baseUrl}/api/v2/stripe/calculate-subscription-data`, {
      projectId: request.projectId,
      country: request.country,
      buckets: {
        low: request.buckets.low,
        medium: request.buckets.medium,
        high: request.buckets.high
      }
    })
      .then((rsp: AxiosResponse) => (rsp.data as unknown as IPendingSubscription))
      .catch((err: AxiosError) => (
        Promise.reject({
          ...{status: err.response?.status},
          ...{message: err.response?.data.messages[0]}
        })
      ));
  }

  public fetch(request: ISubscriptionRequest): Promise<IGetSubscriptionResponse> {
    return axios.get(`${this.baseUrl}/api/v2/stripe/subscription-data?subscriptionId=${request.subscriptionId}`, {
      headers: {
        'Authorization': request.token,
      }
    })
      .then((rsp: AxiosResponse) => (rsp.data as unknown as IGetSubscriptionResponse))
      .catch((err: AxiosError) => (
        Promise.reject({
          ...{status: err.response?.status},
          ...{message: err.response?.data.messages[0]}
        })
      ));
  }

  public cancel(request: ISubscriptionRequest): Promise<ICancelSubscriptionResponse> {
    return axios.post(`${this.baseUrl}/api/v2/stripe/cancel-subscription`, {
      subscriptionId: request.subscriptionId,
    }, {
      headers: {
        'Authorization': request.token,
      }
    })
      .then((rsp: AxiosResponse) => (rsp.data as unknown as ICancelSubscriptionResponse))
      .catch((err: AxiosError) => (
        Promise.reject({
          ...{status: err.response?.status},
          ...{message: err.response?.data.messages[0]}
        })
      ));
  }

  public update(request: IUpdateSubscriptionRequest): Promise<IUpdateSubscriptionResponse> {
    return axios.put(`${this.baseUrl}/api/v2/stripe/update-subscription`,  {
        projectId: request.projectId,
        subscriptionId: request.subscriptionId,
        country: request.country,
        buckets: {
          low: request.buckets.low,
          medium: request.buckets.medium,
          high: request.buckets.high
        }
      }, {
        headers: {
          'Authorization': request.token,
        }
      })
      .then((rsp: AxiosResponse) => (rsp.data as unknown as IUpdateSubscriptionResponse))
      .catch((err: AxiosError) => (
        Promise.reject({
          ...{status: err.response?.status},
          ...{message: err.response?.data.messages[0]}
        })
      ));
  }

}

export { StripeSubscriptions }
