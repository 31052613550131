import React from "react";
import { useSelector } from "react-redux";
import { selectOffsetInTonnes } from "../../slices/assetSlice";
import { selectOffsetInTonnes as selectOffsetInTonnesProfile } from "../../slices/profileSlice";
import { formatNumber } from "../../utils/number-format";
import { selectAttemptLogin } from '../../slices/authenticationSlice';
import "./comparison.scss";

interface IComparisonItem {
  value: string;
  label: string;
  comparisonType: string;
}

const CARS = 0.1224;
const CHEESEBURGERS = 4;
const FLIGHTS = 250;

const impactFigure = (value: number): string => {
  if (value === 0) {
    return '0';
  }

  if (value >= 1000000) {
    return `${formatNumber((value / 1000000).toFixed(0))}m`;
  }
  return formatNumber(value.toFixed(0));
};

const ComparisonItem = ({ value, label, comparisonType }: IComparisonItem) => (
  <div
    className={`profile-comparison-item profile-comparison-item-${comparisonType}`}
  >
    <h5 className="profile-comparison-item-value">{value}</h5>
    <p className="profile-comparison-item-label">{label}</p>
  </div>
);

interface IComparisonProps {
  publicProfile?: boolean;
}

const Comparison = ({ publicProfile = false }: IComparisonProps) => {
  const tonnes = useSelector(
    publicProfile ? selectOffsetInTonnesProfile : selectOffsetInTonnes
  );

  const isLoggingIn = useSelector(selectAttemptLogin);

  const impact = tonnes === undefined ? 0 : tonnes * 1000;

  const cars = impact === 0 ? 0 : impact / CARS;

  const cheeseburgers = impact === 0 ? 0 : impact / CHEESEBURGERS;

  const flights = impact === 0 ? 0 : impact / FLIGHTS;

  return (
    <div className="profile-comparison">
      <h4 className="profile-comparison-title">Impact Comparisons</h4>
      <p className="profile-comparison-subtitle">
        {tonnes?.toFixed(2)} tonnes of carbon removed is the equivalent to removing the
        footprint of either:
      </p>
      <div className="profile-comparison-list">
        <ComparisonItem
          value={impactFigure(cars)}
          label="Km driven in a car"
          comparisonType="car"
        />
        <ComparisonItem
          value={formatNumber(cheeseburgers.toFixed(0))}
          label="Cheeseburgers"
          comparisonType="burger"
        />
        <ComparisonItem
          value={formatNumber(flights.toFixed(0))}
          label="Hours flown"
          comparisonType="flights"
        />
      </div>
      {isLoggingIn && (
      <div className="profile-comparison-title">
        <a href="https://earthly.org/products/buy-now" className="btn">
          Grow Your Impact
        </a>
      </div>
      )}
    </div>
  );
};

export { Comparison };
