import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Auth } from '../services/auth';

enum PasswordState {
  IDLE = 'idle',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed',
}

interface IResetPasswordRequest {
  email: string
}

interface PassowrdChangeState {
  state: PasswordState;
  error: string | null;
}

const initialState: PassowrdChangeState = {
  state: PasswordState.IDLE,
  error: null
};

const usersApi = new Auth();

export const resetPassword = createAsyncThunk(
  'authentication/passwordReset',
  async ({email}: IResetPasswordRequest) => {
    return await usersApi.resetPassword(email);
  }
);

export const resetPasswordSlice = createSlice({
  name: 'reset-password',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(resetPassword.pending, state => {
      state.state = PasswordState.REQUEST;
      state.error = null;
    });
    builder.addCase(resetPassword.fulfilled, state => {
      state.state = PasswordState.SUCCESS;
      state.error = null;
    });
    builder.addCase(resetPassword.rejected, (state, action: any) => {
      state.state = PasswordState.FAILED;
      state.error = action.error.message;
    });
  }
});

export const selectIdle = (state: RootState): boolean => state.resetPassword.state === PasswordState.IDLE;

export const selectSuccess = (state: RootState): boolean => state.resetPassword.state === PasswordState.SUCCESS;

export const selectRequest = (state: RootState): boolean => state.resetPassword.state === PasswordState.REQUEST;

export const selectError = (state: RootState): string | null => state.resetPassword.error;

export default resetPasswordSlice.reducer;
