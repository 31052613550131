import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { useDispatch } from 'react-redux';
import authenticationReducer from '../slices/authenticationSlice';
import clientReducer from '../slices/clientSlice';
import countryReducer from '../slices/countrySlice';
import changePasswordReducer from '../slices/changePasswordSlice';
import employeeReducer from '../slices/employeeSlice';
import registrationReducer from '../slices/registrationSlice';
import projectReducer from '../slices/projectSlice';
import projectSelectionSlice from '../slices/projectSelectionSlice';
import usersReducer from '../slices/userSlice';
import customerReducer from '../slices/stripe/customerSlice';
import paymentReducer from '../slices/stripe/paymentSlice';
import invoiceReducer from '../slices/stripe/invoiceSlice';
import profileReducer from '../slices/profileSlice';
import subscriptionReducer from '../slices/stripe/subscriptionSlice';
import assetReducer from '../slices/assetSlice';
import configReducer from '../slices/configSlice';
import resetPasswordSlice from '../slices/resetPasswordSlice';
import recoverPasswordSlice from '../slices/recoverPasswordSlice';

const appReducer = combineReducers({
  authentication: authenticationReducer,
  asset: assetReducer,
  changePassword: changePasswordReducer,
  resetPassword: resetPasswordSlice,
  recoverPassword: recoverPasswordSlice,
  client: clientReducer,
  employee: employeeReducer,
  project: projectReducer,
  projectSelection: projectSelectionSlice,
  country: countryReducer,
  registration: registrationReducer,
  user: usersReducer,
  profile: profileReducer,
  stripeCustomer: customerReducer,
  stripePayment: paymentReducer,
  stripeInvoice: invoiceReducer,
  stripeSubscription: subscriptionReducer,
  config: configReducer,
});

const rootReducer = (state: any, action: any) => {

  if (action.type === 'authentication/logout') {
    sessionStorage.removeItem('token');
    state = {};
  }
  return appReducer(state, action);
}

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
