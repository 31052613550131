import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from '@material-ui/core';
import { Column } from '../page/column';
import { PageTitle } from '../page/page-title';
import { OrderSummary } from './summary';
import {
  selectProject,
  selectAmount
} from "../../slices/projectSelectionSlice";

import { selectAuthenticated } from "../../slices/authenticationSlice";
import { Login } from '../forms/login_form';
import { RegistrationForm } from '../forms/registration_form';
import { OneTimePaymentForm } from '../forms/one-time-payment-form';
import { PATHS } from '../routes/purchase';

export enum Stage {
  PREAUTH = 'pre-auth',
  LOGIN = 'login',
  REGISTER = 'registration',
  AUTHED = 'authed',
};

interface IAuthStageProps {
  onBack: () => void;
}

interface IAuthSelectorProp {
  onLogin: () => void;
  onRegistration: () => void;
}

interface IPaymentStageProps {
  onComplete: () => void;
}

const AuthSelector = ({onLogin, onRegistration}: IAuthSelectorProp) => (
  <>
    <PageTitle title="Need an account?" className="selection"></PageTitle>
    <Button
      type="submit"
      variant="contained"
      className="registration-form-btn btn"
      onClick={onRegistration}
    >
      Create an account
    </Button>
    &nbsp; or
    <Button onClick={onLogin}>Login</Button>
  </>
);

const LoginStage = ({ onBack }: IAuthStageProps) => (
  <>

    <PageTitle title="Login" className="selection"></PageTitle>
    <Login includeForgottenLink={false} includeSignup={false} />
    <div className='auth-selector-back'>
      <Button onClick={onBack}>Need an account? Register</Button>
    </div>
  </>
);

const RegisterStage = ({ onBack }: IAuthStageProps) => (
  <>
    <PageTitle title="Register" className="selection"></PageTitle>
    <RegistrationForm />
    <div className='auth-selector-back'>
      <Button onClick={onBack}>Have an account? Login</Button>
    </div>
  </>
);

const PaymentStage = ({ onComplete }: IPaymentStageProps) => (
  <>
    <PageTitle title="Confirm &amp; Pay" className="selection"></PageTitle>
    <OneTimePaymentForm onComplete={onComplete} />
  </>
);

interface ICheckoutProps {
  onStageChange: (stage: Stage) => void;
}

const Checkout = ({ onStageChange }: ICheckoutProps) => {

  const history = useHistory();

  const [stage, setStage] = useState<Stage>(Stage.PREAUTH);

  const [complete, setComplete] = useState<boolean>(false);

  const project = useSelector(selectProject);

  const amount = useSelector(selectAmount);

  const authenticated = useSelector(selectAuthenticated);

  const onLoginSelected = useCallback(() => {
    setStage(Stage.LOGIN);
    onStageChange(Stage.LOGIN);
  }, []);

  const onRegisterSelected = useCallback(() => {
    setStage(Stage.REGISTER);
    onStageChange(Stage.REGISTER);
  }, []);

  useEffect(() => {
    if (authenticated) {
      setStage(Stage.AUTHED);
      onStageChange(Stage.AUTHED);
    }
  }, [authenticated]);

  const onPaymentSucceeded = () => {
    setComplete(true);
  };

  if (complete) {
    history.push(PATHS.COMPLETION);
  }

  return (
    <>
      <Column className="col col-lg-6 auth-selector">
        {stage === Stage.PREAUTH && (
          <AuthSelector onLogin={onLoginSelected} onRegistration={onRegisterSelected} />
        )}
        {stage === Stage.LOGIN && (
          <LoginStage onBack={onRegisterSelected} />
        )}
        {stage === Stage.REGISTER && (
          <RegisterStage onBack={onLoginSelected} />
        )}
        {stage === Stage.AUTHED && (
          <PaymentStage onComplete={onPaymentSucceeded} />
        )}
      </Column>
      <Column className="col col-lg-6">
        {amount > 0 && (<OrderSummary project={project} amount={amount} />)}
      </Column>
    </>
  );
};

export { Checkout };
