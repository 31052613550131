import React from "react";

import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../security/private-route";
import { PublicRoute } from "../security/public-route";
import { ClimatePositiveTeam } from "../../pages/climate-positive-team";
import { Login } from "../../pages/login";
import { Profile } from "../../pages/account/profile";
import { Profile as ProfilePage } from "../../pages/profile";
import { PublicProfile } from "../../pages/public-profile";
import { ChangePassword } from "../../pages/account/change-password";
import { NotFound } from "../../pages/not-found";
import { BillingInformation } from "../../pages/account/billing-information";
import { Home } from "../../pages/home";
import { ApiKeys } from "../../pages/account/api-key";
import { Subscriptions } from "../../pages/account/subscriptions";
import { UpdateSubscription } from "../../pages/account/update-subscription";
import { ForgottenPassword } from "../../pages/forgotten-password";
import { Registration } from "../../pages/registration";
import { Payment } from "../../pages/payment";
import { ProjectSelection } from "../../pages/project-selection";
import { Congratulations } from "../../pages/congratulations";
import { Logout } from "../../pages/logout";
import { Welcome } from "../../pages/welcome";
import { RecoverPassword } from "../../pages/recover-password";

export enum PATHS {
  HOME = "/",
  TEAM = "/climate-positive-team/:country",
  PROJECT = "/climate-positive-team/select-project",
  REGISTER = "/climate-positive-team/register",
  PAYMENT = "/climate-positive-team/payment",
  COMPLETION = "/climate-positive-team/complete",
  LOGIN = "/login",
  FORGOTTEN_PASSWORD = "/forgotten-password",
  RECOVER_PASSWORD = "/recover-password",
  PROFILE = "/profile",
  PUBLIC_PROFILE = "/profile/:slug",
  ACCOUNT = "/account",
  CHANGE_PASSWORD = "/account/change-password",
  BILLING = "/account/billing",
  API = "/account/api",
  SUBSCRIPTIONS = "/account/manage-subscription",
  UPDATE_SUBSCRIPTION = "/account/update-subscription",
  LOGOUT = "/account/logout",
  WELCOME = "/welcome-to-earthly",
}

const Routes = () => {
  return (
    <Switch>
      <Route path={PATHS.HOME} exact={true}>
        <Home />
      </Route>
      <Route path={PATHS.PROJECT} exact={true}>
        <ProjectSelection />
      </Route>
      <Route path={PATHS.COMPLETION} exact={true}>
        <Congratulations />
      </Route>
      <Route path={PATHS.REGISTER} exact={true}>
        <Registration />
      </Route>
      <Route path={PATHS.PAYMENT} exact={true}>
        <Payment />
      </Route>
      <Route path={PATHS.TEAM} exact={true}>
        <ClimatePositiveTeam />
      </Route>
      <Route path={PATHS.WELCOME} exact={true}>
        <Welcome />
      </Route>
      <Route path={PATHS.RECOVER_PASSWORD} exact={true}>
        <RecoverPassword />
      </Route>

      <PublicRoute path={PATHS.LOGIN}>
        <Login />
      </PublicRoute>
      <PublicRoute path={PATHS.FORGOTTEN_PASSWORD}>
        <ForgottenPassword />
      </PublicRoute>
      <PrivateRoute path={`${PATHS.PROFILE}/:slug/edit`}>
        <ProfilePage />
      </PrivateRoute>
      <PublicRoute path={PATHS.PUBLIC_PROFILE}>
        <PublicProfile />
      </PublicRoute>
      <PrivateRoute path={PATHS.ACCOUNT} exact={true}>
        <Profile />
      </PrivateRoute>
      <PrivateRoute path={PATHS.CHANGE_PASSWORD} exact={true}>
        <ChangePassword />
      </PrivateRoute>
      <PrivateRoute path={PATHS.BILLING} exact={true}>
        <BillingInformation />
      </PrivateRoute>
      <PrivateRoute path={PATHS.API} exact={true}>
        <ApiKeys />
      </PrivateRoute>
      <PrivateRoute path={PATHS.SUBSCRIPTIONS} exact={true}>
        <Subscriptions />
      </PrivateRoute>
      <PrivateRoute path={PATHS.UPDATE_SUBSCRIPTION} exact={true}>
        <UpdateSubscription />
      </PrivateRoute>
      <PrivateRoute path={PATHS.LOGOUT} exact={true}>
        <Logout />
      </PrivateRoute>

      <Route component={NotFound} />
    </Switch>
  );
};

export { Routes };
