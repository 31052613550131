



import React from 'react';
import { ProgressStep } from './progress-step';

import './steps.css';

interface IStep {
  number: number;
  title: string;
}

const STEPS: IStep[] = [
  {
    number: 1,
    title: "Choose your plan"
  },
  {
    number: 2,
    title: "Select your natural climate solutions"
  },
  {
    number: 3,
    title: "Register"
  },
  {
    number: 4,
    title: "Pay"
  }
];

export const PURCHASE_STEPS: IStep[] = [
  {
    number: 1,
    title: "Choose your project"
  },
  {
    number: 2,
    title: "Login or Register"
  },
  {
    number: 3,
    title: "Pay"
  }
];

interface IProgressProp {
  current: number;
  steps?: IStep[];
  className?: string;
}

const Progress = ({current, steps = STEPS, className = ''}: IProgressProp) => (
  <div className={`progress-steps ${className}`}>
    {steps.map(i => (
      <ProgressStep
        key={i.number}
        number={`${i.number}`}
        title={i.title}
        current={current === i.number}
        next={(current + 1) === i.number}
        done={current > i.number}
        />
      ))
    }
  </div>
);

const PurchaseProgress = ({ current }: IProgressProp) => (
  <Progress current={current} steps={PURCHASE_STEPS} className='progress-steps--purchase' />
);

export { Progress, PurchaseProgress };
