import React from 'react';
import { Row } from './row';

interface ISectionProps {
  children: any,
  centred?: boolean,
  className?: string,
};

const Section = ({children, className = '', centred = true}: ISectionProps) => {
  return (
    <section className={`${centred ? 'text-center' : ''} wow animate__fadeInUp ${className}`}>
      <div className="container">
        <Row className={`${centred ? 'justify-content-md-center' : ''}`}>
          { children }
        </Row>
      </div>
    </section>
  );
};

export { Section }