import React from 'react';

import './employee_total.css';


interface IEmployeeTotal {
    total: number;
}

const EmployeeTotal = ({total}: IEmployeeTotal) => (
    <div className={`employee-total`}>
        <div className="row">
            <div className="col-sm col-sm-8 employee-total-title">Total number of employees</div>
            <div className="col-sm col-sm-4 employee-total-value">{ total }</div>
        </div>
    </div>
);

export { EmployeeTotal };