
import axios, { AxiosResponse } from 'axios';
import { onError } from '../../utils/error-response';
import { Api } from '../api';

export interface ICreateCustomerRequest {
  userId: string;
}

export interface ICreateCustomerResponse {

}

export interface IStripeCustomerService {
  create(request: ICreateCustomerRequest): Promise<ICreateCustomerResponse>;
}


class StripeCustomerService extends Api implements IStripeCustomerService {

  public create(request: ICreateCustomerRequest): Promise<ICreateCustomerResponse> {
    return axios.post(`${this.baseUrl}/api/v2/stripe/create-customer`, request)
    .then((rsp: AxiosResponse) => (rsp.data as unknown as ICreateCustomerResponse))
    .catch(onError);
  }

}

export { StripeCustomerService }
