import React, { useEffect } from "react";
import { PageTitle } from "../components/page/page-title";
import { Section } from "../components/page/section";
import { CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { logout } from "../slices/authenticationSlice";
import { Page } from "../components/page";

const Logout = function () {
  const dispatch = useDispatch();
  const title = "Logout | Earthly";

  useEffect(() => {
    document.title = `${title} Earthly`;

    dispatch(logout());
  }, [title, dispatch]);

  return (
    <Page className="login">
      <Section>
        <div className="col-sm col-md-6">
          <PageTitle title={"Logging out"} className={"trees"} />
        </div>
      </Section>
      <Section>
        <div className="col-sm col-md-6">
          <CircularProgress />
        </div>
      </Section>
    </Page>
  );
};

export { Logout };
