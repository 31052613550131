import React from "react";
import * as _ from 'lodash';
import { useSelector } from "react-redux";
import { selectTransactions } from "../../slices/profileSlice";
import { formatNumber } from "../../utils/number-format";
import "./breakdown.scss";
import {Transactions} from "../../services/profile";

type Props = Transactions;

const BreakdownRow = ({ name, unitVolume, offset, createdAt, type }: Props) => {

  const unitValues = (unitVolume: number, type: string) => {
    if (type === 'LAND') return `${formatNumber(unitVolume.toFixed(2))} sqm`
    if (type === 'TREE') return `${formatNumber(unitVolume.toFixed(0))} trees`
  }

  return (
    <tr className="profile-breakdown-data-row">
      <td className="profile-breakdown-data-item">{name}</td>
      <td className="profile-breakdown-data-item">
        <span className="profile-breakdown-data-value">
          {unitValues(unitVolume, type)}
        </span>
      </td>
      <td className="profile-breakdown-data-item">
        <span className="profile-breakdown-data-value">
          {(offset/1000).toFixed(2)}
        </span>
      </td>
      <td className="profile-breakdown-data-item">
        <span className="profile-breakdown-data-value">{new Date(createdAt).toLocaleDateString()}</span>
      </td>
    </tr>
  );
};


const Breakdown = () => {

  const transactions = useSelector(
    selectTransactions
  );

  if (!transactions || transactions.length === 0) {
    return <div />;
  }

  return (
    <div className="profile-breakdown">
      <h4 className="profile-breakdown-title">Transaction History</h4>
      <div className="profile-breakdown-table">
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <tr className="profile-breakdown-data-header">
              <th className="profile-breakdown-data-title">
                Projects Supported
              </th>
              <th className="profile-breakdown-data-title profile-breakdown-data-title-value">
                Units
              </th>
              <th className="profile-breakdown-data-title profile-breakdown-data-title-value">
                CO<sub>2</sub>t removed
              </th>
              <th className="profile-breakdown-data-title profile-breakdown-data-title-value">
                Date
              </th>
            </tr>
          </thead>
          <tbody>
          {_.orderBy(transactions, (t) => t.createdAt, 'desc')?.map(transaction => (
            <BreakdownRow key={`${transaction.projectId}${transaction.createdAt}`} {...transaction}/>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { Breakdown };
