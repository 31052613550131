import React from "react";

import "./page-title.css";

interface IPageTitleProps {
  title?: string;
  className: string;
  children?: any;
}

const PageTitle = ({ title, className, children }: IPageTitleProps) => (
  <header className={`page-title page-title-${className}`}>
    {title && <h2>{title}</h2>}
    {children}
  </header>
);

export { PageTitle };
