import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Page } from "../../components/page";
import { Column } from "../../components/page/column";
import { Section } from "../../components/page/section";
import { AccountHeader } from "../../components/accounts/header";
import { AccountNavigation } from "../../components/accounts/navigation";
import { ChangePasswordForm } from "../../components/forms/change_password";
import { profile as fetch, selectIdle } from "../../slices/userSlice";
import { selectToken } from "../../slices/authenticationSlice";

const ChangePassword = function () {
  const title = "Change Password";

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const awaitingProfile = useSelector(selectIdle);

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (awaitingProfile && token) {
      dispatch(fetch(token));
    }
  }, [title, dispatch, token, awaitingProfile]);

  return (
    <Page className="change-password">
      <AccountHeader />
      <Section>
        <Column className="col col-lg-4">
          <AccountNavigation current="profile" />
        </Column>
        <Column className="col col-lg-8">
          <ChangePasswordForm></ChangePasswordForm>
        </Column>
      </Section>
    </Page>
  );
};

export { ChangePassword };
