import React, {ChangeEvent, FormEvent, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {resetPassword} from '../../slices/resetPasswordSlice';
import {AlertSnackbar, AlertSnackbarState} from './alert_snackbar';
import {Button, FormControl, TextField,} from '@material-ui/core';

import './password_reset_form.scss';
import {selectError, selectSuccess} from '../../slices/resetPasswordSlice';

enum InputName {
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirm_password',
}

const PasswordResetForm = () => {

  const dispatch = useDispatch();


  const error = useSelector(selectError);
  const success = useSelector(selectSuccess)

  const [email, setEmail] = useState('');

  const onSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await dispatch(resetPassword({
      email
    }));

  }, [dispatch, email]);


  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = (event.target.value as string);

    setEmail(value);
  }, [email]);


  return (
    <div className="welcome-form">
      <h2 className="welcome-form-header">Please enter your email so we can send you the password reset instructions.</h2>
      {error && (<AlertSnackbar message={error} />)}
      {success && (<AlertSnackbar message={'Password reset email sent'} severity={AlertSnackbarState.SUCCESS} />)}
      <form onSubmit={onSubmit}>
        <FormControl fullWidth={true}>
          <TextField onChange={onChange} value={email} name={InputName.EMAIL} id={InputName.EMAIL} label="Email" variant="filled" type="email" autoComplete="username" className="login-form-input" />

            <Button type="submit" variant="contained" className="welcome-form-btn btn">Reset password</Button>
        </FormControl>
      </form>
    </div>
  )
};

export { PasswordResetForm }
