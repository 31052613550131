import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  Profile,
  IPublicProfile,
  IProfileRequest, Transactions,
} from '../services/profile';

import { IProject } from '../models/project';

export enum ProfileStatus {
  IDLE = 'idle',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed',
}

interface ProfileState {
  profile?: IPublicProfile;
  state: ProfileStatus;
  error?: string;
}

const initialState: ProfileState = {
  state: ProfileStatus.IDLE,
};

const profile = new Profile();

export const fetch = createAsyncThunk(
  'profile/get',
  async (req: IProfileRequest) => {
    return await profile.fetch(req);
  }
);

export const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    reset: (state) => {
      state.state = ProfileStatus.IDLE;
      state.profile = undefined;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetch.pending, state => {
      state.state = ProfileStatus.REQUEST;
      state.error = undefined;
    });
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<IPublicProfile>) => {
      state.profile = action.payload;
      state.state = ProfileStatus.SUCCESS;
      state.error = undefined;
    });
    builder.addCase(fetch.rejected, (state, action: any) => {
      state.state = ProfileStatus.FAILED;
      state.error = action.error.message;
    });
  }
});

export const { reset } = ProfileSlice.actions;

export const selectIdle = (state: RootState): boolean => state.profile.state === ProfileStatus.IDLE;

export const selectLoaded = (state: RootState): boolean => state.profile.state === ProfileStatus.SUCCESS;

export const selectRequest = (state: RootState): boolean => state.profile.state === ProfileStatus.REQUEST;

export const selectError = (state: RootState): boolean => state.profile.state === ProfileStatus.FAILED;

export const selectStatus = (state: RootState): ProfileStatus => state.profile.state;

export const selectProfile = (state: RootState): IPublicProfile | undefined => state.profile.profile;

export const selectTransactions = (state: RootState): Transactions[] | undefined => state.profile.profile?.transactions;

export const selectTreesPlanted = (state:RootState): number => {

  const noTreesPlanted = state.profile.profile?.assetSummary.noTreesPlanted;
  if (!noTreesPlanted) return 0;
  return noTreesPlanted;
}

export const selectLandSupported = (state: RootState): number => {
  const landSupported = state.profile.profile?.assetSummary.sqmAreaSupported;
  if (!landSupported) return 0;
  return landSupported;
}

export const selectOffsetInTonnes = (state: RootState): number => {
  const offsetInTonnes = state.profile.profile?.assetSummary?.totalOffsetInTonnes;
  if (!offsetInTonnes) return 0;
  return offsetInTonnes;
}

export const selectSupportedProjects = (state: RootState): IProject[] => {

  if (state.profile.profile === undefined) {
    return [];
  }
  const supportedProjectNames = state.profile.profile.transactions.map(p => p.name);
  return state.profile.profile.projects.filter(p => supportedProjectNames.includes(p.displayName))
}

export default ProfileSlice.reducer;
