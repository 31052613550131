import React, { FunctionComponent } from "react";
import styled from "styled-components";
//import stepBackground from "../../icons/step-background.svg";

interface ProcessStepProps {
  image: string;
  number?: string;
  title: string;
  text: string;
}

interface StepImageProps {
  image: string;
}

const Container = styled.div`
  margin: 156px 30px 0;
  position: relative;
  background: #fff;
  padding: 94px 48px 48px;

  @media (min-width: 805px) {
    flex: 1 1 0;
    margin: 126px 0px 0;
    padding: 94px 12px 32px;
  }

  @media (min-width: 850px) {
    margin: 126px 5px 0;
    max-width: 359px;
    padding: 94px 12px 32px;
  }

  @media (min-width: 960px) {
    margin: 126px 8px 0;
    padding: 94px 12px 32px;
  }

  @media (min-width: 1124px) {
    margin: 186px 8px 0;
    padding: 94px 58px 58px 0px;
  }
`;

const StepImage = styled.div<StepImageProps>`
  position: absolute;
  top: -230px;
  
  background: url(${(props) => props.image}) no-repeat;
  background-size: 242px 242px;
  width: 242px;
  height: 242px;
`;

const Image = styled.div`
  position: relative;
  width: 242px;
  margin: 0 auto;
`;

/*const Step = styled.div`
  font-family: Druk;
  font-style: normal;
  font-weight: 500;
  font-size: 31px;
  height: 52px;
  line-height: 52px;
  width: 52px;
  margin: 0 auto 18px;
  text-align: center;
  color: #fff;
  background: url(${stepBackground}) no-repeat center center;
  background-size: 52px 52px;
`;*/

const Title = styled.h3`
  font-family: 'Inter';
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 14px;
  text-align: center;
  
`;

const Text = styled.p`
  font-family: 'Inter';
  font-size: 16px;
  line-height: 20px;
`;

const ProcessStep: FunctionComponent<ProcessStepProps> = ({
  image,
  number,
  title,
  text,
}) => (
  <Container>
    <Image>
      <StepImage image={image}></StepImage>
    </Image>
   {//<Step>{number}</Step>
   }
    <Title>{title}</Title>
    <Text>{text}</Text>
  </Container>
);

export { ProcessStep };
