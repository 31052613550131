import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PATHS } from "../../components/routes";
import { PageTitle } from "../../components/page/page-title";
import { Column } from "../../components/page/column";
import { PurchaseProgress } from "../../components/progress/progress";
import { Page } from "../../components/page";
import { Section } from "../../components/page/section";
import { OneTimePaymentForm } from "../../components/forms/one-time-payment-form";
import { OrderSummary } from "../../components/purchase/summary";
import { profile as fetch, selectIdle } from "../../slices/userSlice";
import {
  selectProject,
  selectAmount,
} from "../../slices/projectSelectionSlice";
import { selectToken } from "../../slices/authenticationSlice";

const Payment = () => {
  const title = "Payment Details";

  const history = useHistory();

  const [redirect, setRedirect] = useState(false);

  const [complete, setComplete] = useState(false);

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const project = useSelector(selectProject);

  const amount = useSelector(selectAmount);

  const awaitingProfile = useSelector(selectIdle);

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (token === null) {
      setRedirect(true);
    }

    if (project === undefined) {
      setRedirect(true);
    }

    if (amount === undefined) {
      setRedirect(true);
    }

    if (awaitingProfile && token) {
      dispatch(fetch(token));
    }
  }, [title, history, project, token, awaitingProfile, dispatch, setComplete]);

  const onPaymentSucceeded = () => {
    setComplete(true);
  };

  if (redirect) {
    return <Redirect to={PATHS.PROJECT} />;
  }

  if (complete) {
    history.push(PATHS.COMPLETION);
  }

  return (
    <Page className="payment">
      <Section>
        <PurchaseProgress current={4} />
      </Section>
      <Section>
        <Column className="col col-lg-6">
          <PageTitle title={`Confirm & Pay`} className={"payment"} />
          <OneTimePaymentForm onComplete={onPaymentSucceeded} />
        </Column>
        <Column className="col col-lg-6">
          <OrderSummary project={project} amount={amount} />
        </Column>
      </Section>
    </Page>
  );
};

export { Payment };
