import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Page } from "../../components/page";
import { Column } from "../../components/page/column";
import { Section } from "../../components/page/section";
import { AccountHeader } from "../../components/accounts/header";
import { AccountNavigation } from "../../components/accounts/navigation";
import { PaymentHistory } from "../../components/accounts/payment_history";
import { PaymentDetails } from "../../components/accounts/payment_details";
import { selectToken } from "../../slices/authenticationSlice";
import {
  fetchInvoices,
  selectPending,
  selectInvoices,
} from "../../slices/stripe/invoiceSlice";
import {
  fetchPayment,
  selectPayments,
  selectPending as selectPendingPayment,
} from "../../slices/stripe/paymentSlice";

const BillingInformation = function () {
  const title = "Billing Information";

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const awaitingInvoices = useSelector(selectPending);

  const invoices = useSelector(selectInvoices);

  const awaitingPayment = useSelector(selectPendingPayment);

  const payments = useSelector(selectPayments);

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (awaitingInvoices && token) {
      dispatch(fetchInvoices({ token }));
    }

    if (awaitingPayment && token) {
      dispatch(fetchPayment({ token }));
    }
  }, [title, dispatch, token, awaitingInvoices, awaitingPayment]);

  return (
    <Page className="login">
      <AccountHeader />
      <Section>
        <Column className="col col-lg-4">
          <AccountNavigation current="billing" />
        </Column>
        <Column className="col col-lg-8">
          <PaymentDetails payments={payments} />
          <PaymentHistory items={invoices} isLoading={awaitingInvoices} />
        </Column>
      </Section>
    </Page>
  );
};

export { BillingInformation };
