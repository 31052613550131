import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Auth, IChangePasswordRequest } from '../services/auth';

enum PasswordState {
  IDLE = 'idle',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed',
}

interface PassowrdChangeState {
  state: PasswordState;
  error: string | null;
}

const initialState: PassowrdChangeState = {
  state: PasswordState.IDLE,
  error: null
};

const usersApi = new Auth();

export const changePassword = createAsyncThunk(
  'authentication/change-password',
  async (req: IChangePasswordRequest) => {
    await usersApi.changePassword(req);
  }
);

export const changePasswordSlice = createSlice({
  name: 'change-password',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(changePassword.pending, state => {
      state.state = PasswordState.REQUEST;
      state.error = null;
    });
    builder.addCase(changePassword.fulfilled, state => {
      state.state = PasswordState.SUCCESS;
      state.error = null;
    });
    builder.addCase(changePassword.rejected, (state, action: any) => {
      state.state = PasswordState.FAILED;
      state.error = action.error.message;
    });
  }
});

export const selectIdle = (state: RootState): boolean => state.changePassword.state === PasswordState.IDLE;

export const selectSuccess = (state: RootState): boolean => state.changePassword.state === PasswordState.SUCCESS;

export const selectRequest = (state: RootState): boolean => state.changePassword.state === PasswordState.REQUEST;

export const selectError = (state: RootState): string | null => state.changePassword.error;

export default changePasswordSlice.reducer;
