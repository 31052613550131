import React from 'react';
import { IPayment } from '../../models/payment';

import './payment_details.scss';

interface IPaymentDetailsProps {
  payments: IPayment[];
}

interface IPaymentItemProps {
  payment: IPayment;
}

const PaymentItem = ({payment}: IPaymentItemProps) => (
  <div className='payment-details-item'>
    <div className='payment-details-payment-type'>{payment.card.brand}</div>
    <div className='payment-details-card'>*{payment.card.last4}</div>
  </div>
)

const PaymentDetails = ({payments}: IPaymentDetailsProps) => (
  <div className='payment-details'>
    <h2 className='payment-details-header'>Your card</h2>
    <h3 className='payment-details-payment-type'>Bank name</h3>
    {(payments.length === 0) && <p className='payment-details-no-payment'>No payment method registered to your account</p>}
    {(payments.length > 0) && payments.map((p, i) => <PaymentItem key={i} payment={p} />)}
  </div>
);

export { PaymentDetails };