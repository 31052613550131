import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as querystring from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Column } from "../components/page/column";
import { Page } from "../components/page";

import { Section } from "../components/page/section";
import { useParams } from "react-router-dom";
import {
  allProjects,
  selectPending,
  selectReady,
  selectAvailableProjects,
} from "../slices/projectSlice";
import {
  project as pickProject,
  selectProjectSelected,
} from "../slices/projectSelectionSlice";
import { Checkout, Stage } from "../components/purchase/checkout";
import { PurchaseProgress } from "../components/progress/progress";

interface ISelectionPath {
  projectId: string;
}

const Selection = () => {
  const title = "Purchase";

  const history = useHistory();

  const location = useLocation();

  const dispatch = useDispatch();

  const [amount, setAmount] = useState<number | undefined>();

  const [progress, setProgress] = useState<number>(2);

  const projects = useSelector(selectAvailableProjects);

  const isReady = useSelector(selectReady);

  const isPending = useSelector(selectPending);

  const isSelected = useSelector(selectProjectSelected);

  const { projectId } = useParams() as ISelectionPath;

  useEffect(() => {
    document.title = `${title} | Earthly`;
  }, [title]);

  useEffect(() => {
    const query = querystring.parse(location.search);

    if (query.amount === undefined) {
      history.goBack();
    }
    const value = parseInt(query.amount as string, 10);
    if (value <= 0) {
      return;
    }
    setAmount(value);
  }, [location, history]);

  useEffect(() => {
    if (isPending) {
      dispatch(allProjects());
    }
  }, [dispatch, isPending]);

  useEffect(() => {
    if (!isReady || amount === undefined) {
      return;
    }
    const p = projects.filter((p) => p.id === projectId)[0];

    if (p === undefined) {
      history.goBack();
    }
    dispatch(pickProject({ project: p, amount }));
  }, [isReady, amount, projectId, history, dispatch]);

  const onStageChange = useCallback((stage: Stage) => {
    setProgress(stage === Stage.AUTHED ? 3 : 2);
  }, []);

  return (
    <Page className="registration">
      <Section>{isSelected && <PurchaseProgress current={progress} />}</Section>
      <Section>
        {!isSelected && (
          <Column className="col col-md-6 auth-selector">
            <CircularProgress />
          </Column>
        )}
        {isSelected && <Checkout onStageChange={onStageChange} />}
      </Section>
    </Page>
  );
};

export { Selection };
