import React, { useEffect, useState } from "react";
import { useHistory, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { PATHS } from "../../components/routes";
import { PageTitle } from "../../components/page/page-title";
import { PurchaseProgress } from "../../components/progress/progress";
import { Page } from "../../components/page";
import { Section } from "../../components/page/section";
import { RegistrationForm } from "../../components/forms/registration_form";
import { selectRegistered } from "../../slices/registrationSlice";
import {
  selectToken,
  selectAuthenticated,
} from "../../slices/authenticationSlice";
import { selectProject } from "../../slices/projectSlice";

const Registration = () => {
  const title = "Register an account";

  const [redirect, setRedirect] = useState(false);

  const isRegistered = useSelector(selectRegistered);

  const project = useSelector(selectProject);

  const token = useSelector(selectToken);

  const isAuthenticated = useSelector(selectAuthenticated);

  const history = useHistory();

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (project === undefined) {
      setRedirect(true);
    }

    if (token !== null) {
      setRedirect(true);
    }
  }, [project, title, history, token]);

  if (redirect || isAuthenticated || isRegistered) {
    return <Redirect to={PATHS.PAYMENT} />;
  }

  return (
    <Page className="registration">
      <Section>
        <PurchaseProgress current={3} />
      </Section>
      <Section>
        <PageTitle title={`Register an account`} className={"register"} />
      </Section>
      <Section>
        <RegistrationForm />
      </Section>
    </Page>
  );
};

export { Registration };
