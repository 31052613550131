import React from "react";

export const Logo = () => (
  <div className="Header_logoWrapper__3edp8">
    <div className="Logo_logo__1UxF6" style={{ fontSize: "2rem" }}>
      <a href="https://earthly.org">
        <svg
          width="92"
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 944.7 396.98"
        >
          <polygon points="658.39 164.83 623.33 164.83 623.33 0 572.7 0 572.7 396.98 623.33 396.98 623.33 218.88 658.39 218.88 658.39 396.98 709.03 396.98 709.03 0 658.39 0 658.39 164.83"></polygon>
          <polygon points="783.19 0 732.56 0 732.56 396.98 837.07 396.98 837.07 344.75 783.19 344.75 783.19 0"></polygon>
          <polygon points="944.71 0 899.05 0 876.36 127.84 852.77 0 803.5 0 849.39 226.06 849.47 396.98 900.09 396.98 900.06 227.55 944.71 0"></polygon>
          <polygon points="0 396.98 105.41 396.98 105.41 344.75 50.63 344.75 50.63 218.88 84.64 218.88 84.64 164.83 50.63 164.83 50.63 49.02 105.41 49.02 105.41 0 0 0 0 396.98"></polygon>
          <path d="M164.47,0,118.35,397h50.33l5.24-64h38.81L218,397h50.33L222.26,0Zm13.76,282.57,13.88-166.45h2.45l13.88,166.45Z"></path>
          <polygon points="422.3 0 422.3 49.02 467.34 49.02 467.34 396.98 517.53 396.98 517.53 49.02 560.37 49.02 560.37 0 422.3 0"></polygon>
          <path d="M414.48,63.46,382.29,0H280.9V397h51.16V218.88h18.8l13.37,22.88V397H414.5V233.64l-20.39-41.79,20.39-41.22ZM364.23,142.3l-13.37,22.53h-18.8V49h18.77l13.4,22.92Z"></path>
        </svg>
      </a>
    </div>
  </div>
);
