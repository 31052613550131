import React, { useEffect } from "react";
import { NotFound as NotFoundComp } from "../components/page/not-found";

const NotFound = () => {
  useEffect(() => {
    document.title = "404 | Earthly";
  });

  return (<NotFoundComp />);
};

export { NotFound };
