import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Page } from "../../components/page";
import { Column } from "../../components/page/column";
import { Section } from "../../components/page/section";
import { AccountHeader } from "../../components/accounts/header";
import { AccountNavigation } from "../../components/accounts/navigation";
import { UpdateSubscription } from "../../components/accounts/update_subscription";
import { CancelSubscription } from "../../components/accounts/cancel_subscription";
import { selectToken } from "../../slices/authenticationSlice";
import {
  profile as fetch,
  selectIdle,
  selectProfile,
} from "../../slices/userSlice";
import {
  reset,
  cancelSubscription,
} from "../../slices/stripe/subscriptionSlice";

const Subscriptions = function () {
  const title = "Manage Account";

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const awaitingProfile = useSelector(selectIdle);

  const profile = useSelector(selectProfile);

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (awaitingProfile && token) {
      dispatch(fetch(token));
    }
    return () => {
      dispatch(reset());
    };
  }, [title, dispatch, token, awaitingProfile]);

  const onSubscriptionCancelled = () => {
    if (
      token === null ||
      profile === null ||
      profile?.activeStripeSubscriptions.length === 0
    ) {
      return;
    }
    dispatch(
      cancelSubscription({
        token,
        subscriptionId: profile.activeStripeSubscriptions[0],
      })
    );
  };

  return (
    <Page className="login">
      <AccountHeader />
      <Section>
        <Column className="col col-lg-4">
          <AccountNavigation current="subscriptions" />
        </Column>
        <Column className="col col-lg-8">
          <UpdateSubscription />
          {/* <PauseSubscription /> */}
          <CancelSubscription onCancel={onSubscriptionCancelled} />
          <p className="subscription-modifier">
            <a href="https://earthly.org/products/buy-now" className="btn subscription-page">
              Support more Projects
            </a>
          </p>
        </Column>
      </Section>
    </Page>
  );
};

export { Subscriptions };
