
import axios, { AxiosResponse } from 'axios';
import { onError } from '../utils/error-response';
import { Api } from './api';

interface IUsersService {
  profile(token: string): Promise<IUserProfile>;
  update(req: IProfileUpdateRequest): Promise<IUserProfile>;
}

export interface IProfileUpdateRequest {
  profile: IUserProfileUpdate,
  token: string,
}

export interface IProfileUpdateImageRequest {
  image: File,
  name: string,
  token: string,
}

export interface IUserProfileUpdate {
  username: string | undefined | null,
  firstName: string | undefined | null,
  lastName: string | undefined | null,
}

export interface IUserProfile {
  entityName: string,
  id: string,
  createdAt: number,
  lastUpdated: number,
  username: string,
  password: string | null,
  firstName: string | null,
  lastName: string | null,
  profileImage: string | null,
  coverImage: string | null,
  age: number | null,
  gender: string | null,
  active: boolean,
  locked: boolean,
  googleId: string | null,
  facebookId: string | null,
  roles: string[],
  surveyAnswers: string[],
  carbonFootprint:{
    Goods: string | null,
    'Goverenment Spend': string | null,
    Home: string | null,
    Travel: string | null,
    totalScore: string | null,
    'National Footprint': string | null
  },
  quizTakenDate: string | null,
  forest: string[],
  badges: string [],
  contact: {
    phone: {
      prefix: string | null,
      number: number | null
    },
    email: string | null,
    webpage: string | null
  },
  points: number,
  level: number,
  referralCode: string | null,
  referralLink: string | null,
  referred: boolean,
  deleted: boolean,
  deviceTokens: string[],
  spentMoney: boolean,
  rewardPercentage: number,
  offsetData: {
    lastUpdated: number,
    totalOffset: number,
    currentTreeOffset: number,
    totalLandOffset: number,
    totalLandArea: number,
    totalTreeOffset: number,
    periodOffset: number,
    neededCoins: string | null
  },
  wallet: {
    currentCoins: number,
    generatedCoins: number,
    change: number
  },
  registeredCards: string[],
  claimedRewards: string[],
  invitedUsers: string[],
  paymentDetails: {
    stripeId: string | null
  },
  subscriptionData: {
    treesPerMonth: number,
    fee: {
      amount: number,
      divider: number,
      currency: string,
      currencySymbol: string
    }
  },
  clientId: string,
  activeStripeSubscriptions: string[],
  permissions: string[],
  session: {
    entityName: string,
    id: string,
    createdAt: number,
    lastUpdated: number,
    userId: string | null,
    company: string | null,
    deviceId: string | null,
    expiresAt: number
  }
}

class Users extends Api implements IUsersService {

  public profile(token: string): Promise<IUserProfile> {
    return axios.get(`${this.baseUrl}/api/v2/users/me`, {
      headers: {
        'Authorization': token,
      }
    })
    .then((rsp: AxiosResponse) => (rsp.data as unknown as IUserProfile))
    .catch(onError);
  }

  public update(req: IProfileUpdateRequest): Promise<IUserProfile> {
    return axios.put(`${this.baseUrl}/api/v2/users/me/profile`, req.profile, {
      headers: {
        'Authorization': req.token,
      }
    })
    .then((rsp: AxiosResponse) => (rsp.data as unknown as IUserProfile))
    .catch(onError);
  }

  public profileImage(req: IProfileUpdateImageRequest): Promise<IUserProfile> {
    const formData = new FormData()
    formData.append(
      'profile',
      req.image,
      req.name
    );
    return axios.post(`${this.baseUrl}/api/v2/users/me/profile/image`, formData, {
      headers: {
        'Authorization': req.token,
      }
    })
    .then((rsp: AxiosResponse) => (rsp.data as unknown as IUserProfile))
    .catch(onError);
  }
}

export { Users }
