import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PATHS } from "../routes";
import { selectIdle, selectProfile } from "../../slices/userSlice";

interface IUpdateSubscriptionViewProps {
  title: string;
  subtext: string;
  children?: any;
}

const UpdateSubscriptionView = ({
  title,
  subtext,
  children,
}: IUpdateSubscriptionViewProps) => (
  <div className="subscription-modifier subscription-update">
    <h2 className="subscription-modifier-title">{title}</h2>
    <p className="subscription-modifier-subtext">{subtext}</p>
    {children}
  </div>
);

const UpdateSubscription = () => {
  const awaitingProfile = useSelector(selectIdle);

  const profile = useSelector(selectProfile);

  if (awaitingProfile) {
    return <div />;
  }

  if ((profile?.activeStripeSubscriptions.length === 0 || typeof profile?.activeStripeSubscriptions.length === 'undefined')) {
    return (
      <UpdateSubscriptionView
        title={"Teams subscriptions"}
        subtext={
          "Fancy removing even more carbon this month? Or need to reduce your charges for a little while? We've made it very easy to make these changes."
        }
      >
      <Link className={`btn`} to={PATHS.HOME}>
        Sign up
      </Link>
      </UpdateSubscriptionView>
    );
  }

  return (
    <UpdateSubscriptionView
      title={"Update Employees"}
      subtext={
        "Did your organisation grow? Or did more employees become remote workers? Easily edit your number of employees, so your plan is up-to-date."
      }
    >
      <Link className={`btn`} to={PATHS.UPDATE_SUBSCRIPTION}>
        Update Employees
      </Link>
    </UpdateSubscriptionView>
  );
};

export { UpdateSubscription };
