
import axios, { AxiosResponse } from 'axios';
import { IProject } from '../models/project';
import { onError } from '../utils/error-response';
import { Api } from './api';

interface IProjectService {
  fetch(): Promise<IProjectResponse>;
}

enum SortDirection {
    ASC = 'asc',
    DESC = 'desc'
}

interface IMetadata {
    sort: {
        direction: SortDirection
    },
    pagination: {
        pageNumber: number | null,
        perPage: number | null
    }
    filter: {},
    paged: boolean,
    filtered: boolean,
    sorted: boolean
}

export interface IProjectResponse {
    meta: IMetadata,
    data: IProject[]
}

class Projects extends Api implements IProjectService {

  public fetch(): Promise<IProjectResponse> {
    return axios.get(`${this.baseUrl}/api/v2/projects?filter[type]=string&filter[input]=LAND&filter[field]=asset.type`)
    .then((rsp: AxiosResponse) => (rsp.data as unknown as IProjectResponse))
    .catch(onError);
  }

  public all(): Promise<IProjectResponse> {
    return axios.get(`${this.baseUrl}/api/v2/projects`)
    .then((rsp: AxiosResponse) => (rsp.data as unknown as IProjectResponse))
    .catch(onError);
  }
}

export { Projects }
