import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { selectIdle, selectProfile } from "../../slices/userSlice";
import {
  selectCancelled,
  selectCancellationFailed,
} from "../../slices/stripe/subscriptionSlice";

interface ICancelSubscriptionProps {
  onCancel: () => void;
}

const CancelSubscription = ({ onCancel }: ICancelSubscriptionProps) => {
  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const awaitingProfile = useSelector(selectIdle);

  const profile = useSelector(selectProfile);

  const failed = useSelector(selectCancellationFailed);

  const isCancelled = useSelector(selectCancelled);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelSubscription = () => {
    setLoading(true);
    setOpen(false);
    onCancel();
  };

  if (awaitingProfile) {
    return <div />;
  }

  if (failed) {
    return (
      <div className="subscription-modifier subscription-cancel">
        <h2 className="subscription-modifier-title">Cancel plan</h2>
        <p className="subscription-modifier-subtext">
          Failed to cancel your subscription, please try again soon.
        </p>
      </div>
    );
  }

  if (isCancelled) {
    return (
      <div className="subscription-modifier subscription-cancel">
        <h2 className="subscription-modifier-title">Cancel plan</h2>
        <p className="subscription-modifier-subtext">
          Your subscription has been cancelled.
        </p>
      </div>
    );
  }

  if ((profile?.activeStripeSubscriptions.length === 0 || typeof profile?.activeStripeSubscriptions.length === 'undefined')) {
    return (
      <div className="subscription-modifier subscription-cancel">
        <h2 className="subscription-modifier-title">Cancel plan</h2>
        <p className="subscription-modifier-subtext">
          You have no active subscriptions.
        </p>
      </div>
    );
  }

  return (
    <div className="subscription-modifier subscription-cancel">
      <h2 className="subscription-modifier-title">Cancel Subscription</h2>
      <p className="subscription-modifier-subtext">
        Do you want to cancel your subscription? We’re sorry to see you go! We’d love to have you back whenever you’re ready and restart your membership.
      </p>
      {!loading && (
        <>
        <Button
          type="submit"
          variant="contained"
          className="btn"
          onClick={handleClickOpen}
        >
          Cancel Subscription
        </Button>
        </>

      )}
      {loading && (
        <Button
          disabled={true}
          type="submit"
          variant="contained"
          className="btn"
        >
          <CircularProgress />
        </Button>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Cancel Plan</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure wish to cancel your subscription?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            No
          </Button>
          <Button
            onClick={handleCancelSubscription}
            color="secondary"
            autoFocus
          >
            Yes, cancel my subscription
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { CancelSubscription };
