import React from 'react';
import {
  Redirect,
  Route
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PATHS } from '../routes';
import { selectAuthenticated } from '../../slices/authenticationSlice';

interface IPrivateRouteProps {
  children: any
  path: string
  exact?: boolean
}

const PrivateRoute = ({ children, ...rest }: IPrivateRouteProps) => {
  const authenticated = useSelector(selectAuthenticated);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: PATHS.LOGIN,
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export { PrivateRoute }
