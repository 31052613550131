import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  ICreateCustomerRequest,
  StripeCustomerService,
} from '../../services/stripe/customers';

enum CustomerStatus {
  IDLE = 'idle',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed',
}

interface CustomerState {
  state: CustomerStatus;
  error: string | null;

}

const initialState: CustomerState = {
  state: CustomerStatus.IDLE,
  error: null
};

const customerService = new StripeCustomerService();

export const createCustomer = createAsyncThunk(
  'stripe/customer/create',
  async (req: ICreateCustomerRequest) => {
    return await customerService.create(req);
  }
);

export const customerSlice = createSlice({
  name: 'stripe/customer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Fetch
    builder.addCase(createCustomer.pending, (state) => {
      state.state = CustomerStatus.REQUEST;
      state.error = null;
    });
    builder.addCase(createCustomer.fulfilled, (state) => {
      state.state = CustomerStatus.SUCCESS;
      state.error = null;
    });
    builder.addCase(createCustomer.rejected, (state, action: any) => {
      state.state = CustomerStatus.FAILED;
      state.error = action.error.message;
    });
  }
});

export const selectPending = (state: RootState): boolean => state.stripeCustomer.state === CustomerStatus.IDLE;

export const selectStatus = (state: RootState): CustomerStatus => state.stripeCustomer.state;

export const selectError = (state: RootState): string | null => state.stripeCustomer.error;

export default customerSlice.reducer;
