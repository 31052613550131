import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  Assets,
  IAsset,
  IAssetRequest,
  IUserAsset,
} from '../services/assets';

export enum AssetStatus {
  IDLE = 'idle',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed'
}

interface AssetState {
  assets: IAsset[];
  state: AssetStatus;
  error: string | null;
  data: {
    totalNumAssets: number,
    totalOffset: number,
    totalOffsetInTonnes: number
  }
}

const initialState: AssetState = {
  assets: [],
  state: AssetStatus.IDLE,
  error: null,
  data: {
    totalNumAssets: 0,
    totalOffset: 0,
    totalOffsetInTonnes: 0,
  }
};

const assetsApi = new Assets();

export const fetch = createAsyncThunk(
  'assets/fetch',
  async (req: IAssetRequest) => {
    return await assetsApi.fetch(req);
  }
);

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetch.pending, state => {
      state.state = AssetStatus.REQUEST;
      state.error = null;
    });
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<IUserAsset>) => {
      state.data = action.payload.data;
      state.assets = action.payload.assets;
      state.state = AssetStatus.SUCCESS;
      state.error = null;
    });
    builder.addCase(fetch.rejected, (state, action: any) => {
      state.state = AssetStatus.FAILED;
      state.error = action.error.message;
    });
  }
});

export const selectIdle = (state: RootState): boolean => state.asset.state === AssetStatus.IDLE;

export const selectLoaded = (state: RootState): boolean => state.asset.state === AssetStatus.SUCCESS;

export const selectRequest = (state: RootState): boolean => state.asset.state === AssetStatus.REQUEST;

export const selectAssets = (state: RootState): IAsset[] => state.asset.assets;

export const selectOffsetInTonnes = (state: RootState): number => state.asset.data.totalOffsetInTonnes;

export const selectSupportedProjects = (state: RootState): string[] => state.asset.assets.map(a => a.projectId);

export default assetsSlice.reducer;
