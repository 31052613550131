import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  IPaymentMethodRequest,
  IPaymentMethodResponse,
  IPaymentSecretRequest,
  IPaymentSecretResponse,
  Payments,
} from '../../services/stripe/payments';
import {
  IPayment
} from '../../models/payment';

enum PaymentStatus {
  IDLE = 'idle',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed',
  RETRY = 'retry',
  RETRY_SUCCESS = 'retry_success',
  RETRY_FAILED = 'retry_failed',
}

interface PaymentState {
  payments: IPayment[];
  state: PaymentStatus;
  error: string | null;
  secret?: string;
}

const initialState: PaymentState = {
  state: PaymentStatus.IDLE,
  error: null,
  payments: [],
};

const paymentService = new Payments();

export const fetchPayment = createAsyncThunk(
  'stripe/payment/fetch',
  async (req: IPaymentMethodRequest) => {
    return await paymentService.fetch(req);
  }
);

export const fetchClientSecret = createAsyncThunk(
  'stripe/payment/secret',
  async (req: IPaymentSecretRequest) => {
    return await paymentService.intent(req);
  }
);

export const paymentSlice = createSlice({
  name: 'stripe/payment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Fetch
    builder.addCase(fetchPayment.pending, (state) => {
      state.state = PaymentStatus.REQUEST;
      state.error = null;
    });
    builder.addCase(fetchPayment.fulfilled, (state, action: PayloadAction<IPaymentMethodResponse>) => {
      state.state = PaymentStatus.SUCCESS;
      state.error = null;
      state.payments = action.payload.data;
    });
    builder.addCase(fetchPayment.rejected, (state, action: any) => {
      state.state = PaymentStatus.FAILED;
      state.error = action.error.message;
    });

    // Secret
    builder.addCase(fetchClientSecret.pending, (state) => {
      state.state = PaymentStatus.REQUEST;
      state.error = null;
    });
    builder.addCase(fetchClientSecret.fulfilled, (state, action: PayloadAction<IPaymentSecretResponse>) => {
      state.state = PaymentStatus.SUCCESS;
      state.error = null;
      state.secret = action.payload.client_secret;
    });
    builder.addCase(fetchClientSecret.rejected, (state, action: any) => {
      state.state = PaymentStatus.FAILED;
      state.error = action.error.message;
    });
  }
});

export const selectPending = (state: RootState): boolean => state.stripePayment.state === PaymentStatus.IDLE;

export const selectStatus = (state: RootState): PaymentStatus => state.stripePayment.state;

export const selectError = (state: RootState): string | null => state.stripePayment.error;

export const selectPayments = (state: RootState): IPayment[] => state.stripePayment.payments;

export const selectSecret = (state: RootState): string | undefined => state.stripePayment.secret;

export default paymentSlice.reducer;
