import React, {FormEvent, ChangeEvent, MouseEvent, useRef} from "react";
import {Link} from "react-router-dom";
import {PATHS} from "../routes";
import {AlertSnackbar, AlertSnackbarState} from "./alert_snackbar";
import {useCopyToClipboard} from "../../utils/copy-to-clipboard";
import {useSelector, useDispatch} from "react-redux";
import {
  selectError,
  selectProfile,
  selectUpdating,
  selectUpdated,
  update,
  updateProfile,
} from "../../slices/userSlice";
import {
  selectUpdating as selectUpdatingClient,
  selectClient,
  selectError as selectClientError,
  update as updateClient,
  updateClient as saveClient,
  updateImage,
  updateVisibility,
  selectUploadError,
} from "../../slices/clientSlice";
import {selectToken} from "../../slices/authenticationSlice";
import {
  Button,
  FormControl,
  TextField,
  Switch,
  CircularProgress,
} from "@material-ui/core";

import "./profile-form.scss";
import {IClient} from "../../services/clients";

export enum InputName {
  BUSINESS_NAME = "client_name",
  PROFILE_VISIBILITY = "public",
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  EMAIL = "email",
}

interface IProfileFormProps {
  onModified: () => void;
}

// Sep 13, 2023, 00:00 BST
const cutOffDateInMillis = 1694559600000;

function V2ProfileRedirect(props: {
  client: IClient,
  copied: boolean,
}) {

  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  const onProfileVisibilityChange = (event: any) => {
    if (props.client === undefined || token === null) {
      return;
    }
    dispatch(
      updateVisibility({
        client: props.client,
        token,
      })
    );
  };

  return <div className="profile-form-visibilty">
    <div className="profile-form-visibilty-label">Public Dashboard</div>
    <div
      className={`profile-form-visibilty-switch ${
        props.client?.public ? "profile-form-visibilty-switch-checked" : ""
      }`}
    >
      <Switch
        color="default"
        defaultChecked={props.client?.public}
        onChange={onProfileVisibilityChange}
        name="profile-form-visibilty"
        inputProps={{"aria-label": "Profile visibility"}}
      />
    </div>
    <div className="profile-form-visibilty-link">
      <span className="profile-form-visibilty-url">
        {"Make your Earthly dashboard view public \n" +
          "and link it to Earthly's logo on your website, \n" +
          "allowing your clients to join your \n" +
          "journey.\n\n"}
      </span>
    </div>
    <div className="mt-3">
      {props.client?.public && (
        <a
          href={`${PATHS.PROFILE}/${props.client.profileUrlSlug}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View your dashboard
        </a>
      )}
    </div>
  </div>;
}

function V3ProfileRedirect({client}: { client: IClient }) {
  return (
      <a
        href={`https://dashboard.earthly.org/${client.id}`}
        className="mt-5 mb-3 text-center"
        target="_blank"
        rel="noopener noreferrer"
      >
        View your dashboard
      </a>
  )
}

const ProfileForm = ({onModified}: IProfileFormProps) => {
  const dispatch = useDispatch();

  const error = useSelector(selectError);

  const clientError = useSelector(selectClientError);

  const profile = useSelector(selectProfile);

  const client = useSelector(selectClient);

  const token = useSelector(selectToken);

  const isUpdatingUser = useSelector(selectUpdating);

  const isUpdatingClient = useSelector(selectUpdatingClient);

  const isUpdating = isUpdatingUser && isUpdatingClient;

  const uploadError = useSelector(selectUploadError);

  const isSuccess = useSelector(selectUpdated);

  const fileRef = useRef(null);

  const {isCopied} = useCopyToClipboard(2000);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    const name = event.target.name as InputName;

    if (name === InputName.BUSINESS_NAME) {
      return dispatch(updateClient({name, value}));
    }
    return dispatch(update({name, value}));
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!token || !profile || !client) {
      return;
    }
    dispatch(
      updateProfile({
        token,
        profile: {
          username: profile.username,
          firstName: profile.firstName,
          lastName: profile.lastName,
        },
      })
    );
    dispatch(saveClient({token, client}));
    onModified();
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.length ? event.target.files[0] : undefined;

    if (token && file && client) {
      dispatch(
        updateImage({
          token,
          image: file,
          name: file.name,
          client,
        })
      );
      onModified();
    }
  };

  const onAvatarClicked = (event: MouseEvent<HTMLElement>) => {
    (fileRef as any).current.click();
  };

  if (profile === null || client === undefined) {
    return <div/>;
  }
  let profileImage;

  if (client?.image) {
    profileImage = client?.image;
  }
  return (
    <div className="profile-form">
      <h2 className="profile-form-header">Edit Profile</h2>
      {isSuccess && (
        <AlertSnackbar
          message={"Profile updated"}
          severity={AlertSnackbarState.SUCCESS}
        />
      )}
      {error !== null && <AlertSnackbar message={error}/>}
      {clientError !== null && <AlertSnackbar message={clientError}/>}
      {uploadError !== null && <AlertSnackbar message={uploadError}/>}
      <form onSubmit={onSubmit}>
        <FormControl fullWidth={true}>
          <div
            className={`profile-form-avatar ${
              profileImage ? "profile-form-avatar-loaded" : ""
            }`}
          >
            <div
              className="avatar"
              onClick={onAvatarClicked}
              style={{backgroundImage: `url(${profileImage})`}}
            >
              <div className="avatar-button"></div>
            </div>
            <div className="avatar-text">Update your profile photo</div>
            <input
              ref={fileRef}
              type="file"
              accept="image/*"
              capture="user"
              onChange={onFileChange}
              className="avatar-input"
            />
          </div>
          {
            client.createdAt > cutOffDateInMillis ?
              <V3ProfileRedirect client={client}/>
              :
              <V2ProfileRedirect
                client={client}
                copied={isCopied}
              />
          }
          <div
            className="d-flex justify-content-center align-items-center"
          >
            <a
              href="https://earthly.org/products/buy-now"
              className="btn mt-0 mb-5 account-page"
            >
              Increase your investment in nature
            </a>
          </div>
          <div className="profile-form-details">
            <div className="form-row">
              <TextField
                onChange={onChange}
                defaultValue={client?.displayName}
                name="client_name"
                id="client_name"
                label="Business name / Username"
                variant="filled"
                type="text"
                className="profile-form-input"
              />
            </div>
            <div className="profile-help-text">This will be used in your dashboard URL</div>
            <div className="form-row">
              <TextField
                onChange={onChange}
                defaultValue={profile?.firstName}
                name="first_name"
                id="first_name"
                label="First name"
                variant="filled"
                autoComplete="first-name"
                type="text"
                className="profile-form-input"
              />
              <TextField
                onChange={onChange}
                defaultValue={profile?.lastName}
                name="last_name"
                id="last_name"
                label="Last name"
                variant="filled"
                autoComplete="last-name"
                type="text"
                className="profile-form-input"
              />
            </div>
            <div className="form-row">
              <TextField
                onChange={onChange}
                defaultValue={profile?.username}
                name="email"
                id="email"
                label="Email"
                variant="filled"
                autoComplete="email"
                type="text"
                className="profile-form-input"
              />
              <div className="change-password-wrapper">
                <TextField
                  value={"***"}
                  name="password"
                  id="password"
                  label="Password"
                  variant="filled"
                  autoComplete="password"
                  type="password"
                  className="profile-form-input"
                />
                <Link className="change-password" to={PATHS.CHANGE_PASSWORD}>
                  Change
                </Link>
              </div>
            </div>
          </div>
          {isUpdating && (
            <Button
              type="submit"
              disabled={true}
              variant="contained"
              className="btn login-form-btn"
            >
              <CircularProgress/>
            </Button>
          )}
          {!isUpdating && (
            <Button
              type="submit"
              variant="contained"
              className="login-form-btn btn"
            >
              Update
            </Button>
          )}
        </FormControl>
      </form>
    </div>
  );
};

export {ProfileForm as Profile};
