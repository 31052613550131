import React from "react";
import {PATHS} from "../routes";
import {Link} from "react-router-dom";
import {selectClient} from "../../slices/clientSlice";
import {useSelector} from "react-redux";

import "./navigation.scss";

interface IAccountNavigationProps {
  current: string;
}
// Sep 13, 2023, 00:00 BST
const cutOffDateInMillis = 1694559600000;

const AccountNavigation = ({current}: IAccountNavigationProps) => {
  const client = useSelector(selectClient);

  return (
    <div className="account-nav">
      <ul className="account-nav-menu account-nav-top">
        <li>
          <Link
            className={`account-nav-link ${
              current === "profile" ? "account-nav-current" : ""
            }`}
            to={PATHS.ACCOUNT}
          >
            Personal information
          </Link>
        </li>
        <li>
          <Link
            className={`account-nav-link ${
              current === "billing" ? "account-nav-current" : ""
            }`}
            to={PATHS.BILLING}
          >
            Billing information
          </Link>
        </li>
        <li>
          <Link
            className={`account-nav-link ${
              current === "subscriptions" ? "account-nav-current" : ""
            }`}
            to={PATHS.SUBSCRIPTIONS}
          >
            Manage account
          </Link>
        </li>
        <li>
          <a
            className={`account-nav-link ${current === "resources" ? "account-nav-current" : ""}`}
            href="https://20298501.fs1.hubspotusercontent-na1.net/hubfs/20298501/Customer%20Advocacy%20Decks/General%20Customer%20Advocacy%20(1).pdf"
            target='_blank'
            rel="noopener noreferrer"
          >
            Resources
          </a>
        </li>
        <li>
          <a
            className={`account-nav-link ${current === "feature-request" ? "account-nav-current" : ""}`}
            href="https://forms.gle/cRJjzHGagWM2HwMt7"
            target='_blank'
            rel="noopener noreferrer"
          >
            Feature requests
          </a>
        </li>
      </ul>
      <ul className="account-nav-menu account-nav-bottom">
        {client && client.profileUrlSlug && (
          <li>
            {
              client.createdAt < cutOffDateInMillis ? (
                <Link
                  className={`account-nav-link`}
                  to={`${PATHS.PROFILE}/${client.profileUrlSlug}/edit`}
                >
                  Your dashboard
                </Link>
              ) : (
                <a
                  className="account-nav-link"
                  href={`https://dashboard.earthly.org/${client.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Your dashboard
                </a>
              )
            }
          </li>
        )}
        <li>
          <Link className={`account-nav-link`} to={PATHS.LOGOUT}>
            Logout
          </Link>
        </li>
      </ul>
    </div>
  );
};

export {AccountNavigation};
