import { AssetType } from "../services/assets";

export interface ILocation {
    lat: number,
    lng: number
}

export interface IAsset {
  assetPrice: number,
  displayAssetPrice: number
  displayTonnePrice: number,
  kiloPrice: number,
  name: string,
  tonnePrice: number,
  type: AssetType
  unitVolume: number
}

export interface IAction {
    present: string,
    past: string,
    future: string
}

export interface IStripeInformation {
  stripeProductId: string;
  stripePriceId: string;
}

export interface ISDGParam {
  name: string;
  image: string;
}

export interface IProject {
  entityName: string,
  id: string,
  createdAt: number,
  lastUpdated: number,
  displayName: string,
  name: string,
  wordpressUrl: string,
  image: string,
  partnerId: string,
  description: string,
  descriptionSummary: string,
  coordinates: ILocation,
  coordinatesLastUpdated: number,
  asset: IAsset,
  offsetLifespan: number,
  certificates: string[],
  carbonCredit: boolean,
  tags: string[],
  sdgs: ISDGParam[],
  daysOfWork: number,
  actions: IAction[],
  achievments: [],
  co2Data: number[],
  stripeData: IStripeInformation
}

export const projectPrice = (project: IProject) => {
  if (project.asset.type === AssetType.TREE) {
    return project.asset.displayAssetPrice;
  }
  return project.asset.displayTonnePrice;
}
