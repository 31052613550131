import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NotFound } from "../components/page/not-found";
import { Page } from "../components/page";
import { Section } from "../components/page/section";
import { Avatar } from "../components/profile/avatar";
import { Impact } from "../components/profile/impact";
import { Overview } from "../components/profile/overview";
import { Support } from "../components/profile/support";
import { Breakdown } from "../components/profile/breakdown";
import { Comparison } from "../components/profile/comparison";
import { selectToken } from "../slices/authenticationSlice";
import { useParams } from "react-router-dom";

import {
  fetch,
  selectProfile,
  selectError,
  selectIdle as selectProfileIdle,
} from "../slices/profileSlice";

import {
  fetchProjects,
  selectPending as selectProjectsPending,
} from "../slices/projectSlice";

interface IProfilePath {
  slug: string;
  exclude?: string;
}

const PublicProfile = function () {
  const title = "Your Profile";

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const awaitingProfile = useSelector(selectProfileIdle);

  const profile = useSelector(selectProfile);

  const error = useSelector(selectError);

  const awaitingProjects = useSelector(selectProjectsPending);

  const { slug } = useParams() as IProfilePath;

  useEffect(() => {
    document.title = `Earthly`;

    if (awaitingProfile) {

      const exclude = 'assets';

      dispatch(
        fetch({
          token: token === null ? undefined : token,
          exclude,
          slug
        })
      );
    }

    if (awaitingProjects) {
      dispatch(fetchProjects());
    }

    if (profile?.client) {
      document.title = `${profile.client.displayName} | Earthly`;
    }

    if (error) {
      document.title = `Not found | Earthly`;
    }
  }, [
    title,
    error,
    awaitingProfile,
    awaitingProjects,
    token,
    profile,
    slug,
    dispatch,
  ]);

  if (error) {
    return <NotFound />;
  }
  if (Date.now() > 1714485600000) {
    return (
      <Page className="profile">
        <Section>
          <div style={{height:'50vh', display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h4>
          <a
            href={`https://dashboard.earthly.org/${profile?.client.id}`}
            title='Earthly Nature-based Solutions'>Click here {' '}
          </a>
          to access your new dashboard.
        </h4>
          </div>
        </Section>
      </Page>
    )
  }

  return (
    <Page className="profile">
      <Section className="section-profile-logo">
        {profile?.client.image && (
          <div className="profile-header">
            <Avatar size="medium" publicProfile={true}/>
          </div>
        )}
        {!profile?.client.image && (<h1 className="profile-title-name">{profile?.client.displayName}</h1>)}
        <Overview name={profile?.client.displayName ?? ""} />
      </Section>
      <Section className="section-profile-impact">
        <Impact />
      </Section>

      <div className="section-profile-support">
        <Section className="section-profile-support-bg">
          <Support />
        </Section>
      </div>
      <div className="section-profile-comparison">
        <Section className="section-profile-comparison-bg">
          <Comparison publicProfile={true} />
        </Section>
      </div>
      <div className="section-profile-history">
        <Section>
          <Breakdown />
        </Section>
      </div>
    </Page>
  );
};

export { PublicProfile };
