import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Routes } from "./components/routes";
import {
  checkAuthentication,
  selectPending,
} from "./slices/authenticationSlice";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./utils/theme";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
//import TagManager from "react-gtm-module";

import "./App.scss";
import "./fonts/GT-Cinetype-Bold.otf";
import "./fonts/GT-Cinetype-Regular.ttf";


interface iProps {
  checkAuthentication: () => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || "");

const history = createBrowserHistory();
/* emmbeding GTM hard way 
const gtmId = process.env.REACT_APP_GA_TRACKING_ID ? process.env.REACT_APP_GA_TRACKING_ID : 'GTM-KVGW56T'

TagManager.initialize({
  gtmId: gtmId || ""
});*/

const App = ({ checkAuthentication }: iProps) => {

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  const isPending = useSelector(selectPending);

  if (!isPending) {
    return (
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <Router history={history}>
            <Routes />
          </Router>
        </Elements>
      </ThemeProvider>
    );
  }
  return <div className="app-loading"></div>;
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ checkAuthentication }, dispatch);

export default connect(null, mapDispatchToProps)(App);
