
import React, { FormEvent, ChangeEvent, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectInviteRequested,
  selectError
} from '../../slices/authenticationSlice';
import { AlertSnackbar } from './alert_snackbar';
import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import { acceptInvite } from '../../slices/authenticationSlice';

import './welcome_form.scss';

interface IWelcomeFormProps {
  user: string,
  token: string,
  email: string
}

enum InputName {
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirm_password',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
}

const WelcomeForm = ({user, token, email}: IWelcomeFormProps) => {

  const isRequesting = useSelector(selectInviteRequested);

  const error = useSelector(selectError);

  const dispatch = useDispatch();

  const [password, setPassword] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');

  const [acceptTerms, setAcceptTerms] = useState(false);

  const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(acceptInvite({
      user,
      email,
      token,
      password,
      confirm_password: confirmPassword,
      accept_terms: acceptTerms,
    }));
  }, [dispatch, user, email, token, password, confirmPassword, acceptTerms]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const name = (event.target.name as InputName);
    const value = (event.target.value as string);

    if (name === InputName.PASSWORD) {
      setPassword(value);
    }

    if (name === InputName.CONFIRM_PASSWORD) {
      setConfirmPassword(value);
    }
  }, [setPassword, setConfirmPassword]);

  const onTermsAndConditionsChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setAcceptTerms(event.target.checked as boolean);
  }, [setAcceptTerms]);

  return (
    <div className="welcome-form">
      <h2 className="welcome-form-header">Set up your profile</h2>
      {error !== null && (<AlertSnackbar message={error} />)}
      <form onSubmit={onSubmit}>
        <FormControl fullWidth={true}>
          <TextField onChange={onChange} value={email} name={InputName.EMAIL} id={InputName.EMAIL} label="Email" disabled={true} variant="filled" type="email" autoComplete="username" className="login-form-input" />
          <TextField onChange={onChange} name={InputName.PASSWORD} value={password} id={InputName.PASSWORD} label="Password" variant="filled" autoComplete="current-password" type="password" className="login-form-input" />
          <TextField onChange={onChange} name={InputName.CONFIRM_PASSWORD} id={InputName.CONFIRM_PASSWORD} label="Confirm password" variant="filled" autoComplete="current-password" type="password" className="login-form-input" />
          <div className={'form-row'}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={onTermsAndConditionsChange}
                    name={InputName.TERMS_AND_CONDITIONS}
                    color="primary"
                  />
                }
                label={(
                  <span className='form-terms'>I agree with the <a className='welcome-form-link' rel="noopener noreferrer" target="_blank" href={'https://earthly.org/terms-conditions/'}>Terms and Conditions</a></span>
                )}
              />
            </FormGroup>
          </div>
          { isRequesting && (
            <Button type="submit" disabled={true} variant="contained" className="welcome-form-btn">
              <CircularProgress />
            </Button>
          )}
          { !isRequesting && (
            <Button type="submit" variant="contained" className="welcome-form-btn btn">Sign up</Button>
          )}
        </FormControl>
      </form>
    </div>
  )
}

export { WelcomeForm }
