import React, {ChangeEvent, FormEvent, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {recoverPassword, selectError, selectSuccess} from '../../slices/recoverPasswordSlice';
import {AlertSnackbar, AlertSnackbarState} from './alert_snackbar';
import {Button, FormControl, TextField,} from '@material-ui/core';

import './password_recovery_form.scss';
import {Link} from 'react-router-dom';

interface IPasswordRecoveryFormProps {
  token: string,
  email: string
}

enum InputName {
  EMAIL = 'email',
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirm_password',
}

const PasswordRecoveryForm = ({token, email}: IPasswordRecoveryFormProps) => {

  const error = useSelector(selectError);
  const success = useSelector(selectSuccess)

  const dispatch = useDispatch();

  const [password, setPassword] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');

  const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    dispatch(recoverPassword({
      token,
      password,
      confirmPassword
    }));
  }, [dispatch, token, password, confirmPassword]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const name = (event.target.name as InputName);
    const value = (event.target.value as string);

    if (name === InputName.PASSWORD) {
      setPassword(value);
    }

    if (name === InputName.CONFIRM_PASSWORD) {
      setConfirmPassword(value);
    }
  }, [setPassword, setConfirmPassword]);



  return (
    <div className="welcome-form">
      <h2 className="welcome-form-header">Set up your new password</h2>
      {error && (<AlertSnackbar message={error} />)}
      {success && (<AlertSnackbar message={'Password changed successfully'} severity={AlertSnackbarState.SUCCESS} />)}
      <form onSubmit={onSubmit}>
        <FormControl fullWidth={true}>
          <TextField onChange={onChange} value={email} name={InputName.EMAIL} id={InputName.EMAIL} label="Email" disabled={true} variant="filled" type="email" autoComplete="username" className="login-form-input" />
          <TextField onChange={onChange} name={InputName.PASSWORD} value={password} id={InputName.PASSWORD} label="Password" variant="filled" autoComplete="current-password" type="password" className="login-form-input" />
          <TextField onChange={onChange} name={InputName.CONFIRM_PASSWORD} id={InputName.CONFIRM_PASSWORD} label="Confirm password" variant="filled" autoComplete="current-password" type="password" className="login-form-input" />

            <Button type="submit" variant="contained" className="welcome-form-btn btn">Change password</Button>
          {success && (<h3 className={'login-redirect'}>Go to <Link to={'/login'}>Log in</Link></h3>)}
        </FormControl>
      </form>
    </div>
  )
};

export { PasswordRecoveryForm }
