import React, { useState, useCallback } from "react";
import { Logo } from "./header/logo";
import { ScrollToTop } from "./scroll-to-top";
import { MenuToggle } from "./header/menu-toggle";
import { Navigation } from "./header/navigation";

import "./header-v2.css";

const Header = () => {
  const [open, setOpen] = useState(false);

  const onMobileMenuToggled = useCallback(() => setOpen(!open), [
    setOpen,
    open,
  ]);

  return (
    <header
      style={{ overflow: "visible", backgroundColor: "white" }}
      className="Grid_container__1ghah Header_main__2XU7S"
    >
      <div className="Grid_row__3bzwQ Header_row__1gYR2">
        <ScrollToTop />
        <Logo />
        <div className="Header_navWrapper__1FhaG">
          <MenuToggle open={open} onClick={onMobileMenuToggled} />
          <Navigation showMenu={open} />
        </div>
      </div>
    </header>
  );
};

export { Header };
