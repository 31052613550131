import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

enum EmployeeStatus {
  PENDING = 'pending',
  EMPLOYEES_CHOOSEN = 'employees_choosen'
}

export enum EmployeeLevel {
  AVERAGE = 'average',
  MEDIUM = 'medium',
  HIGH = 'high'
}

export interface IEmployeeCounts {
  [EmployeeLevel.AVERAGE]: number;
  [EmployeeLevel.MEDIUM]: number;
  [EmployeeLevel.HIGH]: number;
}

interface OnboardingState {
  employees: IEmployeeCounts;
  state: EmployeeStatus;
  error: string | null;
}

const initialState: OnboardingState = {
  employees: {
    [EmployeeLevel.AVERAGE]: 0,
    [EmployeeLevel.MEDIUM]: 0,
    [EmployeeLevel.HIGH]: 0
  },
  state: EmployeeStatus.PENDING,
  error: null
};

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    employee: (state, action: PayloadAction<{level: EmployeeLevel, value: number}>) => {
      state.employees[action.payload.level] = action.payload.value;
    },
    employeeChosen: state => {
      state.state = EmployeeStatus.EMPLOYEES_CHOOSEN;
    },
    reset: state => {
      state.employees = {
        [EmployeeLevel.AVERAGE]: 0,
        [EmployeeLevel.MEDIUM]: 0,
        [EmployeeLevel.HIGH]: 0
      };
    }
  }
});

export const { employee, employeeChosen, reset } = onboardingSlice.actions;

export const selectStatus = (state: RootState): EmployeeStatus => state.employee.state;

export const selectTeamSelected = (state: RootState): boolean => state.employee.state === EmployeeStatus.EMPLOYEES_CHOOSEN;

export const selectEmployeeCount = (state: RootState): IEmployeeCounts => state.employee.employees;

export const selectTotalEmployees = (state: RootState): number => (
  state.employee.employees.average +
  state.employee.employees.medium +
  state.employee.employees.high
);

export default onboardingSlice.reducer;
