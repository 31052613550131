import React from "react";

import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../security/private-route";
import { PublicRoute } from "../security/public-route";
import { Login } from "../../pages/login";
import { Login as CheckoutLogin } from "../../pages/purchase/login";
import { Selection } from "../../pages/selection";
import { Profile } from "../../pages/account/profile";
import { Profile as ProfilePage } from "../../pages/profile";
import { PublicProfile } from "../../pages/public-profile";
import { ChangePassword } from "../../pages/account/change-password";
import { NotFound } from "../../pages/not-found";
import { BillingInformation } from "../../pages/account/billing-information";
import { Subscriptions } from "../../pages/account/subscriptions";
import { UpdateSubscription } from "../../pages/account/update-subscription";
import { ForgottenPassword } from "../../pages/forgotten-password";
import { Registration } from "../../pages/purchase/registration";
import { Payment } from "../../pages/purchase/payment";
import { Congratulations } from "../../pages/purchase/congratulations";
import { Logout } from "../../pages/logout";

export enum PATHS {
  HOME = "/",
  PROJECT = "/project/:projectId",
  REGISTER = "/register",
  PAYMENT = "/payment",
  COMPLETION = "/complete",
  LOGIN = "/",
  FORGOTTEN_PASSWORD = "/forgotten-password",
  PROFILE = "/profile",
  PUBLIC_PROFILE = "/profile/:slug",
  ACCOUNT = "/account",
  CHANGE_PASSWORD = "/account/change-password",
  BILLING = "/account/billing",
  API = "/account/api",
  SUBSCRIPTIONS = "/account/manage-subscription",
  UPDATE_SUBSCRIPTION = "/account/update-subscription",
  LOGOUT = "/account/logout",
  WELCOME = "/welcome-to-earthly",
  CHECKOUT_LOGIN = '/login',
}

const Routes = () => {
  return (
    <Switch>
      <PublicRoute path={PATHS.HOME} exact={true}>
        <Login />
      </PublicRoute>
      <Route path={PATHS.CHECKOUT_LOGIN} exact={true}>
        <CheckoutLogin />
      </Route>

      <Route path={PATHS.COMPLETION} exact={true}>
        <Congratulations />
      </Route>
      <Route path={PATHS.PROJECT} exact={true}>
        <Selection />
      </Route>
      <Route path={PATHS.REGISTER} exact={true}>
        <Registration />
      </Route>
      <Route path={PATHS.PAYMENT} exact={true}>
        <Payment />
      </Route>

      <PublicRoute path={PATHS.FORGOTTEN_PASSWORD}>
        <ForgottenPassword />
      </PublicRoute>
      <PrivateRoute path={`${PATHS.PROFILE}/:slug/edit`}>
        <ProfilePage />
      </PrivateRoute>
      <PublicRoute path={PATHS.PUBLIC_PROFILE}>
        <PublicProfile />
      </PublicRoute>
      <PrivateRoute path={PATHS.ACCOUNT} exact={true}>
        <Profile />
      </PrivateRoute>
      <PrivateRoute path={PATHS.CHANGE_PASSWORD} exact={true}>
        <ChangePassword />
      </PrivateRoute>
      <PrivateRoute path={PATHS.BILLING} exact={true}>
        <BillingInformation />
      </PrivateRoute>
      <PrivateRoute path={PATHS.SUBSCRIPTIONS} exact={true}>
        <Subscriptions />
      </PrivateRoute>
      <PrivateRoute path={PATHS.UPDATE_SUBSCRIPTION} exact={true}>
        <UpdateSubscription />
      </PrivateRoute>
      <PrivateRoute path={PATHS.LOGOUT} exact={true}>
        <Logout />
      </PrivateRoute>
      <Route component={NotFound} />
    </Switch>
  );
};

export { Routes };
