import React from 'react';

interface IRowProps {
  children: any,
  className?: string
};

const Row = ({children, className}: IRowProps) => {
  return (
    <div className={`row ${className}`}>
      { children }
    </div>
  );
};

export { Row }