import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { IConfigResponse, ConfigService} from '../services/config';

enum ConfigStatus {
  IDLE = 'idle',
  CONFIG_REQUESTED = 'config_requested',
  CONFIG_AVAILABLE = 'config_available',
  CONFIG_FAILED = 'config_failed',
}

interface ConfigState {
  config: any[];
  state: ConfigStatus;
  error: string | null;
}

const initialState: ConfigState = {
  config: [],
  state: ConfigStatus.IDLE,
  error: null
};

const configApi = new ConfigService();

export const fetchConfig = createAsyncThunk(
  'config/fetch',
  async () => {
    const response: IConfigResponse = await configApi.fetch();
    return response.data;
  }
);

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchConfig.pending, (state) => {
      state.state = ConfigStatus.CONFIG_REQUESTED
      state.error = null;
    });
    builder.addCase(fetchConfig.fulfilled, (state, action: PayloadAction<any[]>) => {
      state.config = action.payload;
      state.state = ConfigStatus.CONFIG_AVAILABLE;
      state.error = null;
    });
    builder.addCase(fetchConfig.rejected, (state, action: any) => {
      state.config = [];
      state.state = ConfigStatus.CONFIG_FAILED;
      state.error = action.error.message;
    });
  }
});

export const { reset } = configSlice.actions;

export const selectConfig = (state: RootState): any[] => state.config.config;

export const selectReady = (state: RootState): boolean => (
  state.config.state === ConfigStatus.CONFIG_AVAILABLE
);

export default configSlice.reducer;
