import React, { FunctionComponent } from "react";
import { DarkFooter, Footer } from "./footer";
import { Header } from "./header-v2";

import "./page.scss";

interface PageProps {
  className: string;
  footerType?: "dark" | "light";
  children: React.ReactNode;
}

export const Page: FunctionComponent<PageProps> = ({
  className,
  footerType = "light",
  children,
}) => (
  <div className={`page page--${className}`}>
    <Header />
    <main>{children}</main>
    {footerType === "dark" && <DarkFooter />}
    {footerType === "light" && <Footer />}
  </div>
);
