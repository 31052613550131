import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NotFound } from '../components/page/not-found';
import { Page } from '../components/page';
import { Section } from '../components/page/section';
import { ProfileLink } from '../components/profile/profile-link';
import { Avatar } from '../components/profile/avatar';
import { Impact } from '../components/profile/impact';
import { Overview } from '../components/profile/overview';
import { Support } from '../components/profile/support';
import { Breakdown } from '../components/profile/breakdown';
import { Comparison } from '../components/profile/comparison';
import { selectToken } from '../slices/authenticationSlice';
import { useParams } from 'react-router-dom';

import { fetch, selectProfile, selectError, selectIdle as selectProfileIdle } from '../slices/profileSlice';

import { selectPending, fetchClients } from '../slices/clientSlice';

import { fetchProjects, selectPending as selectProjectsPending } from '../slices/projectSlice';

interface IProfilePath {
  slug: string;
}

const Profile = function () {
  const title = 'Your Profile';

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const awaitingProfile = useSelector(selectProfileIdle);

  const profile = useSelector(selectProfile);

  const error = useSelector(selectError);

  const awaitingClient = useSelector(selectPending);

  const awaitingProjects = useSelector(selectProjectsPending);

  const { slug } = useParams() as IProfilePath;

  useEffect(() => {
    document.title = `Earthly`;

    if (awaitingProfile && token !== null) {
      const exclude = 'assets';

      dispatch(
        fetch({
          token,
          exclude,
          slug,
        })
      );
    }

    if (awaitingClient && token !== null) {
      dispatch(fetchClients(token));
    }

    if (awaitingProjects) {
      dispatch(fetchProjects());
    }

    if (profile?.client) {
      document.title = `${profile.client.displayName} | Earthly`;
    }

    if (error) {
      document.title = `Not found | Earthly`;
    }
  }, [title, error, awaitingProfile, awaitingProjects, awaitingClient, token, profile, slug, dispatch]);

  if (error) {
    return <NotFound />;
  }

  return (
    <Page className='profile'>
      <Section className='section-profile-logo'>
        <div className='profile-header'>
          <Avatar size='medium' publicProfile={true} />
          <ProfileLink />
        </div> 
        <Overview name={profile?.client.displayName ?? ''} />
      </Section>

      <Section className='section-profile-impact'>
        <Impact />
      </Section>

      <div className='section-profile-support'>
        <Section className='section-profile-support-bg'>
          <Support />
        </Section>
      </div>
      <div className='section-profile-comparison'>
        <Section className='section-profile-comparison-bg'>
          <Comparison publicProfile={true} />
        </Section>
      </div>
      <div className="section-profile-history">
        <Section>
          <Breakdown/>
        </Section>
      </div>
    </Page>
  );
};

export { Profile };
