
import React from 'react';
import { IInvoice } from '../../models/invoice';
import download from '../../icons/download.svg';
import { CircularProgress } from '@material-ui/core';

import './payment_history.scss';

interface IPaymentHistoryItemProps {
  item: IInvoice
}

interface IPaymentHistoryProps {
  items: IInvoice[],
  isLoading: boolean
}

const invoiceDate = (date: number) => (
  new Date(date * 1000).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })
);

const HistoryItem = ({item}: IPaymentHistoryItemProps) => (
  <tr className='payment-history-item'>
    <td className='payment-history-item-date'>{invoiceDate(item.created)}</td>
    <td className='payment-history-item-title'>{`${item.number}.pdf`}</td>
    <td className='payment-history-item-download'>
      <a href={item.invoice_pdf} download target='_self'><img src={download} alt={'Download'} /></a>
    </td>
  </tr>
);

const PaymentHistory = ({items, isLoading}: IPaymentHistoryProps) => (
  <div className='payment-history'>
    <h2 className='payment-history-header'>Payment history</h2>

    { isLoading && <CircularProgress /> }
    { !isLoading && (items.length === 0) && (<p className='payment-history-alert'>No payment history</p>)}
    { !isLoading && items.length > 0 && (
      <table className='payment-history-items'>
        <tbody>
          {items.map((item, index) => <HistoryItem item={item} key={index} />)}
        </tbody>
      </table>
    )}
  </div>
);

export { PaymentHistory };