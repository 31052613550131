import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { PATHS } from "../../components/routes";
import { useSelector } from "react-redux";
import { PurchaseProgress } from "../../components/progress/progress";
import { Login as LoginForm } from "../../components/forms/login_form";
import { AccountTree } from "../../components/accounts/account_tree";
import { Page } from "../../components/page";
import { PageTitle } from "../../components/page/page-title";
import { Section } from "../../components/page/section";
import { selectAuthenticated } from "../../slices/authenticationSlice";

const Login = function () {
  const title = "Login | Earthly";

  const isAuthenticated = useSelector(selectAuthenticated);

  useEffect(() => {
    document.title = `${title} Earthly`;
  }, [title]);

  if (isAuthenticated) {
    return <Redirect to={PATHS.PAYMENT} />;
  }

  return (
    <Page className="login">
      <Section>
        <PurchaseProgress current={3} />
      </Section>
      <Section>
        <div className="col-sm col-md-6">
          <PageTitle title={"Log in"} className={"trees"} />
        </div>
      </Section>
      <Section>
        <div className="col-sm col-md-6">
          <LoginForm includeForgottenLink={false} includeSignup={false} />
          <AccountTree />
        </div>
      </Section>
    </Page>
  );
};

export { Login };
