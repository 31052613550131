import React from "react";

import "./step.scss";

interface IProgressStepProps {
  number: string;
  title: string;
  current: boolean;
  done: boolean;
  next: boolean;
}

const ProgressStep = ({
  number,
  title,
  current,
  done,
  next,
}: IProgressStepProps) => (
  <div
    className={`progress-step progress-step-${number} ${
      current ? "progress-step-active" : ""
    } ${done ? "progress-step-done" : ""} ${next ? "progress-step-next" : ""}`}
  >
    <div className="progress-step-circle">
      <span>{number}</span>
    </div>
    <div className="progress-step-title">{title}</div>
    <div className="progress-step-bar-left"></div>
    <div className="progress-step-bar-right"></div>
  </div>
);

export { ProgressStep };
