import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  IInvoiceRequest,
  IInvoiceResponse,
  IRetryInvoiceRequest,
  Invoice,
} from '../../services/stripe/invoice';

enum InvoiceStatus {
  IDLE = 'idle',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed',
  RETRY = 'retry',
  RETRY_SUCCESS = 'retry_success',
  RETRY_FAILED = 'retry_failed',
}

interface InvoiceState {
  invoices?: any;
  state: InvoiceStatus;
  error: string | null;
}

const initialState: InvoiceState = {
  state: InvoiceStatus.IDLE,
  error: null,
  invoices: [],
};

const invoiceService = new Invoice();

export const fetchInvoices = createAsyncThunk(
  'stripe/invoice/fetch',
  async (req: IInvoiceRequest) => {
    return await invoiceService.fetch(req);
  }
);

export const retryInvoice = createAsyncThunk(
  'stripe/invoice/retry',
  async (req: IRetryInvoiceRequest) => {
    return await invoiceService.retryInvoice(req);
  }
);

export const invoiceSlice = createSlice({
  name: 'stripe/payment',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // Fetch
    builder.addCase(fetchInvoices.pending, (state) => {
      state.state = InvoiceStatus.REQUEST;
      state.error = null;
    });
    builder.addCase(fetchInvoices.fulfilled, (state, action: PayloadAction<IInvoiceResponse>) => {
      state.state = InvoiceStatus.SUCCESS;
      state.error = null;
      state.invoices = action.payload.data;
    });
    builder.addCase(fetchInvoices.rejected, (state, action: any) => {
      state.state = InvoiceStatus.FAILED;
      state.error = action.error.message;
    });

    // Retry
    builder.addCase(retryInvoice.pending, (state) => {
      state.state = InvoiceStatus.RETRY;
      state.error = null;
    });
    builder.addCase(retryInvoice.fulfilled, (state) => {
      state.state = InvoiceStatus.RETRY_SUCCESS;
      state.error = null;
    });
    builder.addCase(retryInvoice.rejected, (state, action: any) => {
      state.state = InvoiceStatus.RETRY_FAILED;
      state.error = action.error.message;
    });
  }
});

export const selectPending = (state: RootState): boolean => state.stripeInvoice.state === InvoiceStatus.IDLE;

export const selectStatus = (state: RootState): InvoiceStatus => state.stripeInvoice.state;

export const selectError = (state: RootState): string | null => state.stripeInvoice.error;

export const selectInvoices = (state: RootState): any => state.stripeInvoice.invoices;

export default invoiceSlice.reducer;
