import React, { FormEvent, ChangeEvent, useState } from "react";
import { AlertSnackbar, AlertSnackbarState } from "./alert_snackbar";
import { useSelector, useDispatch } from "react-redux";
import { selectToken } from "../../slices/authenticationSlice";
import {
  changePassword,
  selectRequest,
  selectError,
  selectSuccess,
} from "../../slices/changePasswordSlice";
import {
  Button,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";

import "./change_password.scss";

export enum InputName {
  BUSINESS_NAME = "client_name",
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  EMAIL = "email",
}

const ChangePasswordForm = () => {
  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const inProgress = useSelector(selectRequest);

  const error = useSelector(selectError);

  const isSuccess = useSelector(selectSuccess);

  const [form, setState] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const { old_password, new_password, confirm_password } = form;

  const [valid, setValid] = useState("");

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...form,
      [event.target.name]: event.target.value,
    });

    if (old_password.length > 0 && old_password === new_password) {
      setValid("Your new password can't be the same as the current password");
      return true;
    }

    if (
      event.target.name === "confirm_password" &&
      new_password.length > 0 &&
      event.target.value.length > 0 &&
      new_password !== event.target.value
    ) {
      setValid("Your passwords do not match");
      return true;
    }

    if (
      event.target.name === "new_password" &&
      event.target.value.length > 0 &&
      confirm_password.length > 0 &&
      event.target.value !== confirm_password
    ) {
      setValid("Your passwords do not match");
      return true;
    }
    setValid("");
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (token === null) {
      return;
    }
    dispatch(
      changePassword({
        token,
        currentPassword: old_password,
        newPassword: new_password,
      })
    );
  };

  const disabled =
    old_password.length === 0 ||
    new_password.length === 0 ||
    confirm_password.length === 0 ||
    valid.length > 0;

  return (
    <div className="change-password">
      <h2 className="change-password-header">Change Your Password</h2>
      {isSuccess && (
        <AlertSnackbar
          message={"Password Changed"}
          severity={AlertSnackbarState.SUCCESS}
        />
      )}
      {error !== null && <AlertSnackbar message={error} />}
      {valid.length > 0 && <AlertSnackbar message={valid} />}

      <form onSubmit={onSubmit}>
        <FormControl fullWidth={true}>
          <div className="form-row">
            <TextField
              onChange={onChange}
              value={old_password}
              name="old_password"
              id="old_password"
              label="Old password"
              variant="filled"
              type="password"
              autoComplete="password"
              className="change-password-input"
            />
          </div>
          <div className="form-row">
            <TextField
              onChange={onChange}
              value={new_password}
              name="new_password"
              id="new_password"
              label="New password"
              variant="filled"
              type="password"
              className="change-password-input"
            />
          </div>
          <div className="form-row">
            <TextField
              onChange={onChange}
              value={confirm_password}
              name="confirm_password"
              id="confirm_password"
              label="Confirm password"
              variant="filled"
              type="password"
              className="change-password-input"
            />
          </div>
          <div className="btn-row">
            {inProgress && (
              <Button
                type="submit"
                disabled={true}
                variant="contained"
                className="change-password-btn btn"
              >
                <CircularProgress />
              </Button>
            )}
            {!inProgress && (
              <Button
                type="submit"
                disabled={disabled}
                variant="contained"
                className="change-password-btn btn"
              >
                Change Password
              </Button>
            )}
          </div>
        </FormControl>
      </form>
    </div>
  );
};

export { ChangePasswordForm };
