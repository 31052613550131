import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { ICountry } from "../../models/country";
import { CountrySelector, SelectorMode } from "../onboarding/country_selector";
import { Section } from "../page/section";

interface CountryPickerProps {
  country: ICountry;
  enabled: boolean;
  onChange: (country: ICountry) => void;
  onNext: () => void;
  className: string
}

const Container = styled.div`
  background: transparent;
  text-align: center;
`;



const CountryPicker: FunctionComponent<CountryPickerProps> = ({
  country,
  enabled,
  onChange,
  onNext,
  className
}) => (
  <Section className={className}>
  <Container>
    <CountrySelector
      title={"Ready to calculate your contribution?"}
      mode={SelectorMode.ExtendedPage}
      formTitle={"Which country are you based in?"}
      buttonText={"Continue"}
      country={country}
      enabled={enabled}
      onChange={onChange}
      onClick={onNext}
    />
  </Container>
  </Section>
);

export { CountryPicker };
