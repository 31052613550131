import { AxiosError } from 'axios';

const onError = (err: AxiosError) => {

  if (err.response?.data.messages &&
    Array.isArray(err.response?.data.messages) &&
    err.response?.data.messages.length >= 1) {
    return Promise.reject({
      ...{status: err.response?.status},
      ...{message: err.response?.data.messages[0]}
    });
  }

  if (err.response?.data.messages &&
    err.response?.data.messages.length >= 1) {
    return Promise.reject({
      ...{status: err.response?.status},
      ...{message: err.response?.data.messages}
    });
  }

  return Promise.reject({
    ...{status: err.response?.status},
    ...err.response?.data
  });
};


export { onError };
