import React, { useState } from 'react';

interface IProjectImageProps {
    src: string;
    alt: string;
    className: string;
}

const ProjectImage = ({src, alt, className}: IProjectImageProps) => {

    const [failed, setFailed] = useState(false);

    if (failed) {
        return (<div className={`${className} ${className}-failed`} />);
    }
    return (<img src={src} alt={alt} onError={() => setFailed(true) } className={className} />);
};

export { ProjectImage };