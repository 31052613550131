import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Section } from "../page/section";
import freshWater from "../../icons/freshwater.png";
import { Button } from "@material-ui/core";

interface IntroductionProps {
  title: string;
  subtitle: string;
  introduction: string;
  cta: string;
  onClick: () => void;
}

const PageTitle = styled.h1`
  text-align: left;
  font-family: 'Druk';
  font-size: 4.2rem;
  line-height: 4rem ;
  margin-bottom: 2rem;
  @media (max-width: 850px) {
    
    font-size: 3rem;
    line-height: 3rem ;
    margin-bottom: 1rem;
  }
`;

const Subtitle = styled.h2`
font-family: Inter;
font-size: 1rem;
margin-bottom: 30px;
font-weight: normal;

@media (min-width: 768px) {
  font-family: Inter;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
}
`;

const Details = styled.div`
  font-family: Inter;
  font-size: 1rem;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    font-family: Inter;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }
`;

const CTA = styled.div`
  font-family: Inter;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
  font-weight: bold;
`;

const Introduction: FunctionComponent<IntroductionProps> = ({
  title,
  subtitle,
  introduction,
  cta,
  onClick,
}) => (
  <Section centred={false} className="bkg-gray section-introduction">
    <div className="col-sm-12 col-md-8">
      <PageTitle>{title}</PageTitle>
      <Subtitle>{subtitle}</Subtitle>
      <Details>{introduction}</Details>
      <CTA>{cta}</CTA>
      <Button onClick={onClick} className="btn btn-earthly btn-introduction">Get started</Button>
    </div>
    <div className="col-sm-12 col-md-4 introduction-img">
      <img src={freshWater} alt="Invest in nature Banner" />
    </div>
  </Section>
);

export { Introduction };
