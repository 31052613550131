
import axios, { AxiosResponse } from 'axios';
import { onError } from '../utils/error-response';
import { Api } from './api';
import { IAsset } from './assets';
import { IClient } from './clients';
import { IProject } from '../models/project';

export interface IProfileRequest {
  token?: string,
  exclude?: string,
  slug: string,
}

interface IProfileService {
  fetch(request: IProfileRequest): Promise<IPublicProfile>;
}

export interface Transactions {
  name: string,
  projectId: string,
  unitVolume: number,
  offset: number,
  createdAt: number,
  type: string,
}

export interface IPublicProfile {
  assets: IAsset[],
  client: IClient,
  projects: IProject[],
  transactions: Transactions[]
  assetSummary: {
    noTreesPlanted: number,
    sqmAreaSupported: number,
    totalOffset: number,
    totalNumAssets: number,
    totalOffsetInTonnes: number
  }
}

class Profile extends Api implements IProfileService {

  public fetch(req: IProfileRequest): Promise<IPublicProfile> {
    let headers = {};

    if (req.token) {
      headers = {
        'Authorization': req.token,
      };
    }

    const baseUrl = `${this.baseUrl}/api/v2/clients/${req.slug}/profile?v=new`;

    const url = req.exclude ? `${baseUrl}&exclude=${req.exclude}` : baseUrl;

    return axios.get(url, { headers: headers })
      .then((rsp: AxiosResponse) => (rsp.data as unknown as IPublicProfile))
      .catch(onError);
  }
}

export { Profile }
