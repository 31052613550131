
import axios, { AxiosResponse } from 'axios';
import { onError } from '../utils/error-response';
import { Api } from './api';

enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum AssetType {
  TREE = 'TREE',
  LAND = 'LAND'
}

interface IMetadata {
  sort: {
      direction: SortDirection
  },
  pagination: {
      pageNumber: number | null,
      perPage: number | null
  }
  filter: {},
  paged: boolean,
  filtered: boolean,
  sorted: boolean
}

export interface IAsset {
  entityName: string,
  id: string,
  createdAt: number,
  lastUpdated: number,
  name: string,
  type: AssetType,
  ownerId: string,
  clientId: string,
  projectId: string,
  unitVolume: number,
  assetPrice: number,
  tonnePrice: number,
  displayAssetPrice: number,
  displayTonnePrice: number,
  offset: number,
  expirationDate: number
}

export interface IUserAsset {
  meta: IMetadata,
  assets: IAsset[],
  data: {
    totalOffset: number,
    totalNumAssets: number,
    totalOffsetInTonnes: number
  }
}

interface IAssetService {
  fetch(request: IAssetRequest): Promise<IUserAsset>;
}

export interface IAssetRequest {
  token: string,
  user: string
}

class Assets extends Api implements IAssetService {

  public fetch(request: IAssetRequest): Promise<IUserAsset> {
    return axios.get(`${this.baseUrl}/api/v2/users/${request.user}/assets`, {
      headers: {
        'Authorization': request.token,
      }
    })
    .then((rsp: AxiosResponse) => (rsp.data as unknown as IUserAsset))
    .catch(onError);
  }
}

export { Assets }
