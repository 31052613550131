

class Api {

  private url: string;

  constructor() {
    this.url = process.env.REACT_APP_API_URL ?? '';
  }

  get baseUrl() {
    return this.url;
  }
}

export { Api };
