import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../app/store';
import {Auth, IAuthResponse} from '../services/auth';
import {IRegistrationPayload, registerUserAndClient} from './registrationSlice';

enum AuthState {
  PENDING = 'pending',
  IDLE = 'idle',
  REQUEST = 'request',
  AUTHENTICATED = 'authenticated',
  FAILED = 'failed',
}

interface AuthenticationState {
  uuid: string | null;
  state: AuthState;
  invite: AuthState;
  error: string | null;
}

const initialState: AuthenticationState = {
  uuid: null,
  state: AuthState.PENDING,
  invite: AuthState.PENDING,
  error: null
};

const usersApi = new Auth();

export const authenticateUser = createAsyncThunk(
  'authentication/user',
  async ({email, password}: {[key: string]: string} ) => {
    const response: IAuthResponse = await usersApi.authenticate(email, password);
    return response.authToken;
  }
);

interface IAcceptInviteRequest {
  user: string,
  email: string,
  password: string,
  confirm_password: string,
  token: string,
  accept_terms: boolean,
}



export const acceptInvite = createAsyncThunk<string, IAcceptInviteRequest>(
  'authentication/invite',
  async ({user, password, token, email, confirm_password, accept_terms}: IAcceptInviteRequest, thunkApi) => {
    if (accept_terms === false) {
      return thunkApi.rejectWithValue('Please accept the Terms and Conditions to register');
    }

    if (password !== confirm_password) {
      return thunkApi.rejectWithValue('Your passwords do not match');
    }
    const response: IAuthResponse = await usersApi.acceptInvite(user, password, token);

    return response.authToken;
  }
);

export const checkAuthentication = createAsyncThunk<string>(
  'authentication/check',
  async (_, thunkApi) => {
    const token = await sessionStorage.getItem('token');

    if (token === null) {
      return thunkApi.rejectWithValue({});
    }
    return token;
  }
);

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: state => {
      sessionStorage.removeItem('token');
      state.uuid = null;
      state.state = AuthState.IDLE;
    },
  },
  extraReducers: builder => {
    builder.addCase(authenticateUser.pending, (state) => {
      state.state = AuthState.REQUEST
      state.error = null;
    });
    builder.addCase(authenticateUser.fulfilled, (state, action: PayloadAction<string>) => {
      state.uuid = action.payload;
      state.state = AuthState.AUTHENTICATED;
      state.error = null;
      sessionStorage.setItem('token', action.payload);
    });
    builder.addCase(authenticateUser.rejected, (state, action: any) => {
      state.uuid = null;
      state.state = AuthState.FAILED;
      state.error = action.error.message;
    });

    builder.addCase(acceptInvite.pending, (state) => {
      state.invite = AuthState.REQUEST
      state.error = null;
    });
    builder.addCase(acceptInvite.fulfilled, (state, { payload }) => {
      state.uuid = payload;
      state.state = AuthState.AUTHENTICATED;
      state.invite = AuthState.AUTHENTICATED;
      state.error = null;
      sessionStorage.setItem('token', payload);
    });
    builder.addCase(acceptInvite.rejected, (state, action: any) => {
      state.uuid = null;
      state.state = AuthState.FAILED;
      state.invite = AuthState.FAILED;
      state.error = action.error.message;
    });

    builder.addCase(registerUserAndClient.fulfilled, (state, action: PayloadAction<IRegistrationPayload>) => {
      state.uuid = action.payload.token;
      state.state = AuthState.AUTHENTICATED;
      state.error = null;
      sessionStorage.setItem('token', action.payload.token);
    });

    builder.addCase(checkAuthentication.fulfilled, (state, action: PayloadAction<string>) => {
      state.uuid = action.payload;
      state.state = AuthState.AUTHENTICATED;
      state.error = null;
    });
    builder.addCase(checkAuthentication.rejected, (state, action: any) => {
      state.state = AuthState.IDLE;
    });
  }
});

export const { logout } = authenticationSlice.actions;

export const selectPending = (state: RootState): boolean => state.authentication.state === AuthState.PENDING;

export const selectAuthenticated = (state: RootState): boolean => state.authentication.state === AuthState.AUTHENTICATED;

export const selectAttemptLogin = (state: RootState): boolean => state.authentication.state === AuthState.REQUEST || state.authentication.state === AuthState.AUTHENTICATED;

export const selectError = (state: RootState): string | null => state.authentication.error;

export const selectToken = (state: RootState): string | null => state.authentication.uuid;

export const selectInviteRequested = (state: RootState): boolean => state.authentication.invite === AuthState.REQUEST;

export const selectInviteFailed = (state: RootState): boolean => state.authentication.invite === AuthState.FAILED;

export const selectInviteAccepted = (state: RootState): boolean => state.authentication.invite === AuthState.AUTHENTICATED;

export const selectResetAccepted = (state: RootState): string | null => state.authentication.error;

export const selectResetFailer = (state: RootState): string | null => state.authentication.error;


export default authenticationSlice.reducer;
