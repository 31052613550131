import React from 'react';

import './header.scss';

const AccountHeader = () => (
  <div className='account-header'>
    <h1>Account Settings</h1>
  </div>
);

export { AccountHeader };