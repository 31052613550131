import React from "react";
import { useSelector } from "react-redux";
import {
  selectTreesPlanted,
  selectLandSupported,
  selectOffsetInTonnes,
} from "../../slices/profileSlice";
import { formatNumber } from "../../utils/number-format";

import "./impact.scss";

interface IItemProps {
  itemType: string;
  itemValue: string;
  itemLabel: string;
}

const ImpactItem = ({ itemType, itemValue, itemLabel }: IItemProps) => (
  <div className={`profile-impact-item profile-impact-${itemType}`}>
    <div className={`icon icon-${itemType}`} />
    <div className="profile-impact-item-value">{itemValue}</div>
    <div className="profile-impact-item-label">{itemLabel}</div>
  </div>
);

const Impact = () => {

  const treesPlanted = useSelector(selectTreesPlanted)
  const landSupported = useSelector(selectLandSupported);
  const offsetInTonnes = useSelector(selectOffsetInTonnes);

  const hasTrees = treesPlanted === 0 ? false : true;
  const hasLand = landSupported === 0 ? false : true;

  return (
    <div className={`profile-impact ${hasTrees ? '' : 'profile-impact-has-no-trees'} ${hasLand ? '' : 'profile-impact-has-no-land'}`}>
      {hasTrees && (<ImpactItem
        itemType="trees"
        itemValue={formatNumber(treesPlanted.toFixed(0))}
        itemLabel="Trees planted"
      />)}
      {hasTrees && (<div className="profile-impact-divider" />)}
      <ImpactItem
        itemType="carbon"
        itemValue={`${offsetInTonnes?.toFixed(2)}t`}
        itemLabel="Tonnes of carbon removed"
      />
      {hasLand && (<div className="profile-impact-divider" />)}
      {hasLand && (<ImpactItem
        itemType="forest"
        itemValue={formatNumber(landSupported.toFixed(0))}
        itemLabel="Sq/m area supported"
      />)}
    </div>
  );
};

export { Impact };
