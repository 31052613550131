import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PATHS } from "../../components/routes";
import { Link } from "react-router-dom";
import { Page } from "../../components/page";
import { Section } from "../../components/page/section";
import { Column } from "../../components/page/column";
import {
  fetchClients,
  selectClient,
  selectPending,
} from "../../slices/clientSlice";
import {
  selectProject,
  selectAmount,
  reset as resetProject,
} from "../../slices/projectSelectionSlice";
import { selectToken } from "../../slices/authenticationSlice";
import { PageTitle } from "../../components/page/page-title";
import { PurchaseProgress } from "../../components/progress/progress";
import { OrderSummary } from "../../components/purchase/summary";
import { reset } from "../../slices/userSlice";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

const Congratulations = function () {

  // Sep 13, 2023, 00:00 BST
  const cutOffDateInMillis = 1694559600000;

  const dispatch = useDispatch();

  const title = "Thank you for your order!";

  const [redirect, setRedirect] = useState(false);

  const token = useSelector(selectToken);

  const project = useSelector(selectProject);

  const amount = useSelector(selectAmount);

  const client = useSelector(selectClient);

  const awaitingClient = useSelector(selectPending);

  const [animated, setAnimated] = useState(true);

  const { width, height } = useWindowSize();

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (project === undefined) {
      setRedirect(true);
    }

    if (awaitingClient && token !== null) {
      dispatch(fetchClients(token));
    }
    const timer = setTimeout(() => setAnimated(false), 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [project, dispatch, token, awaitingClient]);

  useEffect(
    () => () => {
      dispatch(reset());
      dispatch(resetProject());
    },
    []
  );

  if (redirect) {
    return <Redirect to={PATHS.PROJECT} />;
  }

  return (
    <Page className="congratualations">
      <Section>
        <PurchaseProgress current={5} />
      </Section>
      <Section>
        <Confetti recycle={animated} width={width} height={height} />
        <Column className="col-sm col-md-8">
          <PageTitle title={title} className={"congratulations"} />
          <OrderSummary project={project} amount={amount} />
          {client?.profileUrlSlug && cutOffDateInMillis > client.createdAt && (
            <Link
              className={`btn`}
              to={`${PATHS.PROFILE}/${client?.profileUrlSlug}/edit`}
            >
              Go to your dashboard
            </Link>
          )}
          {
            client && cutOffDateInMillis < client.createdAt && (
              <a
                href={`https://dashboard.earthly.org/${client?.id}`}
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-inner">Go to dashboard</span>
              </a>
            )
          }
        </Column>
      </Section>
    </Page>
  );
};

export { Congratulations };
