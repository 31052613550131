import React, { useEffect, useCallback, useState } from "react";
import { Page } from "../../components/page";
import { Section } from "../../components/page/section";
import { PageTitle } from "../../components/page/page-title";
import { CarbonFootprint } from "../../components/onboarding/carbon_footprint";
import { EmployeeSelector } from "../../components/onboarding/employee_selector";
import { EmployeeLevel } from "../../slices/employeeSlice";
import { useSelector, useDispatch } from "react-redux";
import { EmployeeTotal } from "../../components/onboarding/employee_total";
import { Column } from "../../components/page/column";
import { selectToken } from "../../slices/authenticationSlice";
import { Button, CircularProgress } from "@material-ui/core";
import {
  AlertSnackbar,
  AlertSnackbarState,
} from "../../components/forms/alert_snackbar";
import {
  profile as fetch,
  selectIdle,
  selectProfile,
} from "../../slices/userSlice";
import {
  fetchClients,
  selectPending,
  selectClient,
} from "../../slices/clientSlice";
import {
  reset,
  updateSubscription,
  selectUpdateRequested,
  selectUpdateSuccess,
} from "../../slices/stripe/subscriptionSlice";
import { selectCountry } from "../../slices/countrySlice";

const UpdateSubscription = () => {
  const dispatch = useDispatch();

  const country = useSelector(selectCountry);

  const [average, setAverage] = useState(0);

  const [medium, setMedium] = useState(0);

  const [high, setHigh] = useState(0);

  const token = useSelector(selectToken);

  const awaitingProfile = useSelector(selectIdle);

  const awaitingClient = useSelector(selectPending);

  const profile = useSelector(selectProfile);

  const client = useSelector(selectClient);

  const isUpdating = useSelector(selectUpdateRequested);

  const isUpdated = useSelector(selectUpdateSuccess);

  useEffect(() => {
    document.title = `Update Subscription | Earthly`;

    if (awaitingProfile && token) {
      dispatch(fetch(token));
    }

    if (awaitingClient && token) {
      dispatch(fetchClients(token));
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, token, awaitingProfile, awaitingClient, profile]);

  const onAverageSelected = useCallback(
    (level: EmployeeLevel, value: number) => {
      setAverage(value);
    },
    []
  );

  const onMediumSelected = useCallback(
    (level: EmployeeLevel, value: number) => {
      setMedium(value);
    },
    []
  );

  const onHighSelected = useCallback((level: EmployeeLevel, value: number) => {
    setHigh(value);
  }, []);

  const onSubmit = useCallback(() => {
    if (token === null || client === undefined || profile === null) {
      return;
    }
    dispatch(
      updateSubscription({
        token,
        subscriptionId: profile.activeStripeSubscriptions[0],
        projectId: client.productData.teams.projectIds[0],
        buckets: {
          low: average,
          medium: medium,
          high: high,
        },
        country: country.code,
      })
    );
  }, [token, country, client, average, medium, high, dispatch, profile]);

  const total = average + medium + high;

  return (
    <Page className="onboarding">
      <Section>
        <Column className="col-sm col-md-8">
          <PageTitle className={"onboarding"}>
            <h2>Update your subscription</h2>
          </PageTitle>
        </Column>
      </Section>
      <Section>
        <Column className="col-sm col-md-8">
          <CarbonFootprint country={country} />
        </Column>
      </Section>
      <Section>
        {isUpdated && (
          <AlertSnackbar
            message={"Subscription updated"}
            severity={AlertSnackbarState.SUCCESS}
          />
        )}
        <Column className="col-lg-4">
          <EmployeeSelector
            level={EmployeeLevel.AVERAGE}
            title={"Average"}
            subtitle={"Remote Worker"}
            points={[
              "2 hours driving per week",
              "6 hours flying annually (3 short haul)",
            ]}
            value={average}
            onChange={onAverageSelected}
          />
        </Column>
        <Column className="col-lg-4">
          <EmployeeSelector
            level={EmployeeLevel.MEDIUM}
            title={"Medium"}
            subtitle={"City-Hopper"}
            points={[
              "10 hours driving per week",
              "12 hours flying annually (6 short haul, 1 long haul)",
            ]}
            value={medium}
            onChange={onMediumSelected}
          />
        </Column>
        <Column className="col-lg-4">
          <EmployeeSelector
            level={EmployeeLevel.HIGH}
            title={"High"}
            subtitle={"Globe-Trotter"}
            points={[
              "20 hours driving per week",
              "24 hours flying annually (12 short haul, 2 long haul)",
            ]}
            value={high}
            onChange={onHighSelected}
          />
        </Column>
      </Section>
      <Section centred={false}>
        <Column>
          <EmployeeTotal total={total} />
        </Column>
      </Section>
      <Section>
        <Column>
          {!isUpdating && (
            <Button
              onClick={onSubmit}
              disabled={total === 0}
              type="submit"
              variant="contained"
              className="btn"
            >
              Update Subsription
            </Button>
          )}
          {isUpdating && (
            <Button
              type="submit"
              disabled={true}
              variant="contained"
              className="btn login-form-btn"
            >
              <CircularProgress />
            </Button>
          )}
        </Column>
      </Section>
    </Page>
  );
};

export { UpdateSubscription };
