import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  Users,
  IUserProfile,
  IProfileUpdateRequest,
  IProfileUpdateImageRequest
} from '../services/users';
import {
  InputName
} from '../components/forms/profile_form';

export enum UserStatus {
  IDLE = 'idle',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed',
  UPDATE_PROFILE = 'update_profile',
  PROFILE_UPDATED = 'profile_update',
  FAILED_UPDATE = 'failed_update',
}

interface UserState {
  profile: IUserProfile | null;
  state: UserStatus;
  error: string | null;
  profile_image: {
    state: UserStatus;
    error: string | null;
  }
}

const initialState: UserState = {
  profile: null,
  state: UserStatus.IDLE,
  error: null,
  profile_image: {
    state: UserStatus.IDLE,
    error: null
  }
};

const usersApi = new Users();

export const profile = createAsyncThunk(
  'users/profile',
  async (token: string) => {
    return await usersApi.profile(token);
  }
);

export const updateProfile = createAsyncThunk(
  'users/update-profile',
  async (req: IProfileUpdateRequest) => {
    return await usersApi.update(req);
  }
);

export const updateProfileImage = createAsyncThunk(
  'users/update-profile-image',
  async (req: IProfileUpdateImageRequest) => {
    return await usersApi.profileImage(req);
  }
);

interface IProfileUpdate {
  name: InputName,
  value: string
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    reset: (state) => {
      state.state = UserStatus.IDLE;
      state.profile_image.state = UserStatus.IDLE;
    },
    update: (state, action: PayloadAction<IProfileUpdate>) => {
      if (!state.profile) {
        return;
      }

      if(action.payload.name === InputName.FIRST_NAME) {
        state.profile.firstName = action.payload.value;
      }

      if(action.payload.name === InputName.LAST_NAME) {
        state.profile.lastName = action.payload.value;
      }

      if(action.payload.name === InputName.EMAIL) {
        state.profile.username = action.payload.value;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(profile.pending, state => {
      state.state = UserStatus.REQUEST;
      state.error = null;
    });
    builder.addCase(profile.fulfilled, (state, action: PayloadAction<IUserProfile>) => {
      state.profile = action.payload;
      state.state = UserStatus.SUCCESS;
      state.error = null;
    });
    builder.addCase(profile.rejected, (state, action: any) => {
      state.state = UserStatus.FAILED;
      state.error = action.error.message;
    });

    builder.addCase(updateProfile.pending, state => {
      state.state = UserStatus.UPDATE_PROFILE;
      state.error = null;
    });
    builder.addCase(updateProfile.fulfilled, (state, action: PayloadAction<IUserProfile>) => {
      state.profile = action.payload;
      state.state = UserStatus.PROFILE_UPDATED;
      state.error = null;
    });
    builder.addCase(updateProfile.rejected, (state, action: any) => {
      state.state = UserStatus.FAILED_UPDATE;
      state.error = action.error.message;
    });

    builder.addCase(updateProfileImage.pending, state => {
      state.profile_image.state = UserStatus.UPDATE_PROFILE;
      state.error = null;
    });
    builder.addCase(updateProfileImage.fulfilled, state => {
      state.profile_image.state = UserStatus.PROFILE_UPDATED;
      state.error = null;
    });
    builder.addCase(updateProfileImage.rejected, (state, action: any) => {
      state.profile_image.state = UserStatus.FAILED_UPDATE;
      state.error = action.error.message;
    });
  }
});

export const { reset, update } = usersSlice.actions;

export const selectIdle = (state: RootState): boolean => state.user.state === UserStatus.IDLE;

export const selectLoaded = (state: RootState): boolean => state.user.state === UserStatus.SUCCESS;

export const selectRequest = (state: RootState): boolean => state.user.state === UserStatus.REQUEST;

export const selectStatus = (state: RootState): UserStatus => state.user.state;

export const selectError = (state: RootState): string | null => state.user.error;

export const selectUpdating = (state: RootState): boolean => state.user.state === UserStatus.UPDATE_PROFILE;

export const selectUpdated = (state: RootState): boolean => state.user.state === UserStatus.PROFILE_UPDATED;

export const selectUploading = (state: RootState): boolean => state.user.profile_image.state === UserStatus.REQUEST;

export const selectUploadError = (state: RootState): string | null => state.user.profile_image.error;

export const selectProfile = (state: RootState): IUserProfile | null => state.user.profile;

export default usersSlice.reducer;
