
import React, { FormEvent, ChangeEvent, useCallback, useState } from 'react';
import { PATHS } from '../routes';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { authenticateUser, selectAttemptLogin, selectError } from '../../slices/authenticationSlice';
import { Link } from 'react-router-dom';
import { AlertSnackbar } from '../forms/alert_snackbar';
import { Button, FormControl, TextField, CircularProgress } from '@material-ui/core';

import './login-form.scss';

interface ILoginFormProps {
  includeSignup: boolean;
  includeForgottenLink: boolean;
}

enum InputName {
  EMAIL = 'email',
  PASSWORD = 'password'
}

const Login = ({ includeForgottenLink = true, includeSignup = true }: ILoginFormProps) => {

  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');

  const isLoggingIn = useSelector(selectAttemptLogin);

  const error = useSelector(selectError);

  const dispatch = useDispatch();

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = (event.target.value as string);
    const name = (event.target.name as InputName);

    if (name === InputName.EMAIL) {
      setEmail(value);
    } else {
      setPassword(value);
    }
  }, [setEmail, setPassword]);


  const onSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    dispatch(authenticateUser({ email: email, password: password }));
    event.preventDefault();
  }, [dispatch, email, password]);

  return (
    <div className="login-form">
      {error !== null && (<AlertSnackbar message={error} />)}
      <form onSubmit={onSubmit}>
        <FormControl fullWidth={true}>
          <TextField onChange={onChange} name="email" id="email" label="Email" variant="filled" type="email" autoComplete="username" className="login-form-input" />
          <TextField onChange={onChange} name="password" id="password" label="Password" variant="filled" autoComplete="current-password" type="password" className="login-form-input" />
          {includeForgottenLink && <Link to="/forgotten-password" className="login-form-forgotten">Forgot your password?</Link>}
          { isLoggingIn && (
            <Button type="submit" disabled={true} variant="contained" className="btn login-form-btn">
              <CircularProgress />
            </Button>
          )}
          { !isLoggingIn && (
            <Button type="submit" variant="contained" className="login-form-btn btn">Log in</Button>
          )}
        </FormControl>
      </form>
      {includeSignup && <p className="login-form-register">Don't have an account? <Link to={PATHS.HOME}>Sign up</Link></p>}
    </div>
  )
}

export { Login };
