import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Page } from "../../components/page";
import { Column } from "../../components/page/column";
import { Section } from "../../components/page/section";
import { AccountHeader } from "../../components/accounts/header";
import { AccountNavigation } from "../../components/accounts/navigation";
import { Profile as ProfileForm } from "../../components/forms/profile_form";
import { profile as fetch, selectIdle } from "../../slices/userSlice";
import { reset } from "../../slices/profileSlice";
import { selectToken } from "../../slices/authenticationSlice";
import { fetchClients, selectPending } from "../../slices/clientSlice";

const Profile = function () {
  const title = "Profile";

  const dispatch = useDispatch();

  const [modified, setModified] = useState(false);

  const token = useSelector(selectToken);

  const awaitingProfile = useSelector(selectIdle);

  const awaitingClient = useSelector(selectPending);

  useEffect(() => {
    document.title = `${title} | Earthly`;

    if (awaitingProfile && token) {
      dispatch(fetch(token));
    }

    if (awaitingClient && token) {
      dispatch(fetchClients(token));
    }
  }, [title, dispatch, token, awaitingProfile, awaitingClient]);

  useEffect(() => {
    return () => {
      if (modified) {
        dispatch(reset());
      }
    };
  }, [dispatch, modified]);

  const onProfileChanged = useCallback(() => setModified(true), []);

  return (
    <Page className="login">
      <AccountHeader />
      <Section>
        <Column className="col col-lg-4">
          <AccountNavigation current="profile" />
        </Column>
        <Column className="col col-lg-8">
          <ProfileForm onModified={onProfileChanged} />
        </Column>
      </Section>
    </Page>
  );
};

export { Profile };
