import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { IProject } from '../models/project';
import { IProjectResponse, Projects} from '../services/projects';

enum ProjectStatus {
  IDLE = 'idle',
  PROJECTS_REQUESTED = 'projects_requested',
  PROJECTS_AVAILABLE = 'projects_available',
  PROJECTS_FAILED = 'projects_failed',
  PROJECT_SELECTED = 'project_selected',
  SUBMITTED = 'submitted'
}

interface ProjectState {
  project?: IProject;
  availableProjects: IProject[];
  state: ProjectStatus;
  error: string | null;
}

const initialState: ProjectState = {
  project: undefined,
  availableProjects: [],
  state: ProjectStatus.IDLE,
  error: null
};

const projectsApi = new Projects();

export const fetchProjects = createAsyncThunk(
  'onboarding/projects',
  async () => {
    const response: IProjectResponse = await projectsApi.fetch();
    return response.data;
  }
);


export const allProjects = createAsyncThunk(
  'onboarding/all-projects',
  async () => {
    const response: IProjectResponse = await projectsApi.all();
    return response.data;
  }
);


export const onboardingSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    project: (state, action: PayloadAction<IProject>) => {
      state.project = action.payload;
      state.state = ProjectStatus.PROJECT_SELECTED;
    },
    reset: (state) => {
      state = initialState;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.state = ProjectStatus.PROJECTS_REQUESTED
      state.error = null;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action: PayloadAction<IProject[]>) => {
      state.availableProjects = action.payload;
      state.state = ProjectStatus.PROJECTS_AVAILABLE;
      state.error = null;
    });
    builder.addCase(fetchProjects.rejected, (state, action: any) => {
      state.availableProjects = [];
      state.state = ProjectStatus.PROJECTS_FAILED;
      state.error = action.error.message;
    });

    builder.addCase(allProjects.pending, (state) => {
      state.state = ProjectStatus.PROJECTS_REQUESTED
      state.error = null;
    });
    builder.addCase(allProjects.fulfilled, (state, action: PayloadAction<IProject[]>) => {
      state.availableProjects = action.payload;
      state.state = ProjectStatus.PROJECTS_AVAILABLE;
      state.error = null;
    });
    builder.addCase(allProjects.rejected, (state, action: any) => {
      state.availableProjects = [];
      state.state = ProjectStatus.PROJECTS_FAILED;
      state.error = action.error.message;
    });
  }
});

export const { project, reset } = onboardingSlice.actions;

export const selectProject = (state: RootState): IProject | undefined => state.project.project;

export const selectPending = (state: RootState): boolean => state.project.state === ProjectStatus.IDLE;

export const selectReady = (state: RootState): boolean => (
  state.project.state === ProjectStatus.PROJECTS_AVAILABLE ||
  state.project.state === ProjectStatus.PROJECT_SELECTED ||
  state.project.state === ProjectStatus.SUBMITTED
);

const disabledProjectIds = [
  '601adffe6fc1d70018fef075', // BAM - staging/prod
];
export const selectAvailableProjects = (state:RootState): IProject[] => state
  .project
  .availableProjects
  .filter(project => !disabledProjectIds.includes(project.id));

export default onboardingSlice.reducer;
