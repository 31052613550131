import React from "react";
import { Section } from "../components/page/section";
import { PageTitle } from "../components/page/page-title";
import { PasswordResetForm } from "../components/forms/password_reset_form";
import { Page } from "../components/page";

const ForgottenPassword = () => {
  document.title = "Forgotten Password | Earthly";

  return (
    <Page className="forgotten-password">
      <Section>
        <div className="col-sm">
          <PageTitle title={"Forgotten Password"} className={"trees"} />
        </div>
      </Section>
      <Section>
        <div className="col-sm col-md-6">
          <PasswordResetForm />
        </div>
      </Section>
    </Page>
  );
};

export { ForgottenPassword };
