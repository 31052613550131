import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import {
  IClient,
  ClientService,
  ICreateClientParams,
  ICreateClientResponse,
  IUpdateClientRequest,
  IUpdateImageRequest
} from '../services/clients';
import {
  InputName
} from '../components/forms/profile_form';

enum ClientStatus {
  IDLE = 'idle',
  CREATE_CLIENT = 'create',
  CLIENT_CREATED = 'created',
  CREATION_FAILED = 'create_failed',
  REQUEST = 'request',
  SUCCESS = 'success',
  FAILED = 'failed',
  UPDATE_CLIENT = 'update',
  UPDATE_SUCEEDED = 'updated',
  UPDATE_FAILED = 'update_failed',
}

interface ClientState {
  client?: IClient;
  state: ClientStatus;
  error: string | null;
  image: {
    state: ClientStatus;
    error: string | null;
  }
}

const initialState: ClientState = {
  client: undefined,
  state: ClientStatus.IDLE,
  error: null,
  image: {
    state: ClientStatus.IDLE,
    error: null
  }
};

const clientsApi = new ClientService();

export const fetchClients = createAsyncThunk(
  'clients/fetch',
  async (token: string) => {
    return await clientsApi.get(token);
  }
);

export const createClient = createAsyncThunk(
  'clients/create',
  async (req: ICreateClientParams) => {
    return await clientsApi.create(req, req.token);
  }
);

export const updateClient = createAsyncThunk(
  'clients/update',
  async (req: IUpdateClientRequest) => {
    await clientsApi.update(req.client, req.token);
    return await clientsApi.get(req.token);
  }
);

export const updateVisibility = createAsyncThunk(
  'clients/update-visibility',
  async (req: IUpdateClientRequest) => {
    const client = {...req.client, ...{public: !req.client.public}}
    await clientsApi.update(client, req.token);
    return client;
  }
);

export const updateImage = createAsyncThunk<IClient, IUpdateImageRequest>(
  'users/update-image',
  async (req: IUpdateImageRequest) => {
    const image = await clientsApi.updateImage(req);

    if (image.uploaded === false) {
      return Promise.reject('Image failed upload');
    }
    const client = {
      ...req.client,
      ...{ image: image.path }
    };

    await clientsApi.update(client, req.token);
    return await clientsApi.get(req.token);
  }
);

interface IClientUpdate {
  name: InputName,
  value: string | boolean
}

export const clientSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    resetClient: (state) => {
      state.state = ClientStatus.IDLE;
      state.image.state = ClientStatus.IDLE;
    },
    update: (state, action: PayloadAction<IClientUpdate>) => {
      if (!state.client) {
        return;
      }

      if(action.payload.name === InputName.BUSINESS_NAME) {
        state.client.displayName = action.payload.value as string;
      }

      if(action.payload.name === InputName.PROFILE_VISIBILITY) {
        state.client.public = action.payload.value as boolean;
      }
    },
  },
  extraReducers: builder => {
    // Fetch
    builder.addCase(fetchClients.pending, (state) => {
      state.state = ClientStatus.REQUEST;
      state.error = null;
    });
    builder.addCase(fetchClients.fulfilled, (state, action: PayloadAction<IClient>) => {
      state.client = action.payload;
      state.state = ClientStatus.SUCCESS;
      state.error = null;
    });
    builder.addCase(fetchClients.rejected, (state, action: any) => {
      state.client = undefined;
      state.state = ClientStatus.FAILED;
      state.error = action.error.message;
    });

    // Creation
    builder.addCase(createClient.pending, (state) => {
      state.state = ClientStatus.CREATE_CLIENT;
      state.error = null;
    });
    builder.addCase(createClient.fulfilled, (state, action: PayloadAction<ICreateClientResponse>) => {
      state.state = ClientStatus.CLIENT_CREATED;
      state.error = null;
    });
    builder.addCase(createClient.rejected, (state, action: any) => {
      state.client = undefined;
      state.state = ClientStatus.CREATION_FAILED;
      state.error = action.error.message;
    });

    // Update
    builder.addCase(updateClient.pending, (state) => {
      state.state = ClientStatus.UPDATE_CLIENT;
      state.error = null;
    });
    builder.addCase(updateClient.fulfilled, (state, action: PayloadAction<IClient>) => {
      state.state = ClientStatus.UPDATE_SUCEEDED;
      state.client = action.payload;
      state.error = null;
    });
    builder.addCase(updateClient.rejected, (state, action: any) => {
      state.client = undefined;
      state.state = ClientStatus.UPDATE_FAILED;
      state.error = action.error.message;
    });


    // updateVisibility
    builder.addCase(updateVisibility.pending, (state) => {
      state.state = ClientStatus.UPDATE_CLIENT;
      state.error = null;
    });
    builder.addCase(updateVisibility.fulfilled, (state, action: PayloadAction<IClient>) => {
      state.state = ClientStatus.UPDATE_SUCEEDED;
      state.client = action.payload;
      state.error = null;
    });
    builder.addCase(updateVisibility.rejected, (state, action: any) => {
      state.client = undefined;
      state.state = ClientStatus.UPDATE_FAILED;
      state.error = action.error.message;
    });

    // Update
    builder.addCase(updateImage.pending, (state) => {
      state.image.state = ClientStatus.UPDATE_CLIENT;
      state.image.error = null;
    });
    builder.addCase(updateImage.fulfilled, (state, action: PayloadAction<IClient>) => {
      state.client = action.payload;
      state.image.state = ClientStatus.UPDATE_SUCEEDED;
      state.image.error = null;
    });
    builder.addCase(updateImage.rejected, (state, action: any) => {
      state.image.state = ClientStatus.UPDATE_FAILED;
      state.image.error = null;
    });
  }
});

export const { resetClient, update } = clientSlice.actions;

export const selectClient = (state: RootState): IClient | undefined => state.client.client;

export const selectPending = (state: RootState): boolean => state.client.state === ClientStatus.IDLE;

export const selectStatus = (state: RootState): ClientStatus => state.client.state;

export const selectUpdating = (state: RootState): boolean => state.client.state === ClientStatus.UPDATE_CLIENT;

export const selectError = (state: RootState): string | null => state.client.error;

export const selectUploading = (state: RootState): boolean => state.client.image.state === ClientStatus.REQUEST;

export const selectUploadError = (state: RootState): string | null => state.client.image.error;

export default clientSlice.reducer;
