import React from 'react';

import './overview.scss';

interface IOverviewProps {
  name: string,
}

//const getTitle = (name: string) => name.endsWith('s') ? `${name}' World` : `${name}'s World`

const Overview = ({name} : IOverviewProps) => (
    <div className='profile-overview'>
      
      <p className='profile-overview-intro'>Welcome to our impact dashboard, where you can learn more about our efforts to remove carbon, restore nature and improve livelihoods through Earthly's <a href="https://earthly.org/nature-based-projects" title='Earthly Nature-based Solutions'>nature-based solutions.</a> </p>
    </div>
);

export { Overview };
