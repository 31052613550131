
import countries from '../config/countries.json';

import userCountries from '../config/available_countries.json';

export interface ICountry {
    code: string,
    country: string,
    high: number,
    high_bucket: number,
    medium: number,
    medium_bucket: number,
    low: number,
    low_bucket: number
}

export interface IUserCountry {
  code: string,
  name: string
}

export const COUNTRY_LIST: ICountry[] = countries.sort((a, b) => (a.country > b.country) ? 1 : -1);

export const USER_COUNTRY_LIST: IUserCountry[] = userCountries.sort((a, b) => (a.name > b.name) ? 1 : -1);

export const DEFAULT_COUNTRY = COUNTRY_LIST.filter(c => c.code === 'GB')[0];
