import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from '../routes';

import  './profile-link.scss';

const ProfileLink = () => {
  return (
    <div className='profile-edit'>
      <Link className='profile-edit-link' to={PATHS.ACCOUNT}>Edit Profile</Link>
    </div>
  );
};

export { ProfileLink };
