import React from "react";
import { useSelector } from "react-redux";
import { Logo, PlaceHolderLogo } from "./logo";
import { selectClient } from "../../slices/clientSlice";

import { selectProfile } from "../../slices/profileSlice";

import "./avatar.scss";

interface IAvatarProps {
  size: "small" | "medium";
  publicProfile?: boolean;
}

const Avatar = ({ size, publicProfile = false }: IAvatarProps) => {
  const client = useSelector(selectClient);

  const profile = useSelector(selectProfile);

  const image = publicProfile ? profile?.client.image : client?.image;

  if (image) {
    return (
      <div className={`avatar avatar-${size}`}>
        <Logo src={image} alt="Profile image" />
      </div>
    );
  }
  return (
    <div className={`avatar avatar-${size}`}>
      <PlaceHolderLogo />
    </div>
  );
};

export { Avatar };
