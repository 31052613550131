import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { IProject } from '../models/project';

enum ProjectStatus {
  IDLE = 'idle',
  PROJECT_SELECTED = 'project_selected',
}

interface IProjectSelection {
  project: IProject;
  amount: number
}

interface ProjectState {
  project?: IProject;
  amount: number;
  state: ProjectStatus;
}

const initialState: ProjectState = {
  project: undefined,
  amount: 0,
  state: ProjectStatus.IDLE,
};

export const projectSelectionSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    project: (state, action: PayloadAction<IProjectSelection>) => {
      state.project = action.payload.project;
      state.amount = action.payload.amount;
      state.state = ProjectStatus.PROJECT_SELECTED;
    },
    reset: (state) => {
      state = initialState;
    }
  }
});

export const { project, reset } = projectSelectionSlice.actions;

export const selectProject = (state: RootState): IProject | undefined => state.projectSelection.project;

export const selectAmount = (state: RootState): number => state.projectSelection.amount;

export const selectMonies = (state: RootState): string | undefined => {
  if (state.projectSelection.project === undefined || state.projectSelection.amount === 0) {
    return undefined;
  }
  const { project, amount } = state.projectSelection;
  return ((project.asset.tonnePrice / 100) * amount).toFixed(2);
}



export const selectPending = (state: RootState): boolean => state.projectSelection.state === ProjectStatus.IDLE;

export const selectProjectSelected = (state: RootState): boolean => (
  state.projectSelection.state === ProjectStatus.PROJECT_SELECTED
);

export default projectSelectionSlice.reducer;
