
import axios, { AxiosResponse } from 'axios';
import { onError } from '../utils/error-response';
import { Api } from './api';

interface IConfigService {
  fetch(): Promise<IConfigResponse>;
}

export interface IConfigResponse {
    meta: {},
    data: any[]
}

class ConfigService extends Api implements IConfigService {

  public fetch(): Promise<IConfigResponse> {
    return axios.get(`${this.baseUrl}/api/v2/admin-data`)
    .then((rsp: AxiosResponse) => (rsp.data as unknown as IConfigResponse))
    .catch(onError);
  }
}

export { ConfigService }
