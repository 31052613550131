import React from "react";
import { IProject, projectPrice } from "../../models/project";

import warning from "../../icons/warning.svg";

import "./summary.scss";
import { currency, formatNumber } from "../../utils/number-format";

interface IProjectBasketProps {
  project?: IProject;
  amount: number;
}

interface IProjectDetailsProps {
  project?: IProject;
  amount: number;
}

const ProjectDetails = ({ project, amount }: IProjectDetailsProps) => {
  if (project === undefined) {
    return (
      <p className={"order-summary-no-project"}>
        <img src={warning} alt="Warning:" /> No project has been selected yet
      </p>
    );
  }
  const itemCost = projectPrice(project).toFixed(2).toString();

  return (
    <div className={"order-summary-project-details"}>
      <p className={"order-summary-project-title"}>Project Details</p>
      <div className={"order-summary-project-cost"}>
        <div className={"order-summary-project-cost-label"}>
          {project.displayName}
        </div>
        <div className={"order-summary-project-cost-value"}>
          £{currency(itemCost)}
        </div>
      </div>
      <p className={"order-summary-project-value"}></p>
      <div className={"order-summary-project-cost"}>
        <div className={"order-summary-project-cost-label"}>Total Units</div>
        <div className={"order-summary-project-cost-value"}>
          {formatNumber(amount.toString())}
        </div>
      </div>
    </div>
  );
};

const OrderSummary = ({ project, amount }: IProjectBasketProps) => {
  if (project === undefined) {
    return <div />;
  }

  const monthlyCost = (projectPrice(project) * amount).toFixed(2);

  return (
    <div className={"order-summary"}>
      <h5 className={"order-summary-title"}>Order summary</h5>
      <div className={"order-summary-product"}>
        <p className={"order-summary-product-title"}>Product:</p>
        <p className={"order-summary-product-value"}>Buy Now</p>
      </div>
      <div className={"order-summary-project"}>
        <ProjectDetails project={project} amount={amount}></ProjectDetails>
      </div>
      <div className={"order-summary-cost"}>
        <div className={"order-summary-cost-label"}>Total cost</div>
        <div className={"order-summary-cost-value"}>
          £{currency(monthlyCost)}
        </div>
      </div>
    </div>
  );
};

export { OrderSummary };
