import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { EmployeeLevel } from "../../slices/employeeSlice";

import "./employee_selector.scss";

interface IEmployeeSelectorProps {
  level: EmployeeLevel;
  title: string;
  subtitle: string;
  points: string[];
  value?: number;
  onChange: (level: EmployeeLevel, value: number) => void;
}

const MAX_EMPLOYEES = 200;
const EMPLOYEE_OPTIONS = Array.from(Array(MAX_EMPLOYEES + 1).keys());

const EmployeeSelector = ({
  level,
  title,
  subtitle,
  points,
  value,
  onChange,
}: IEmployeeSelectorProps) => (
  <div className={`employee-selector employee-selector-${level}`}>
    <div className={"employee-selector-header"}>{title}</div>
    <div className={"employee-selector-content"}>
      <h3 className={"employee-selector-subtitle"}>{subtitle}</h3>
      <FormControl className={"employee-selector-select"}>
        <InputLabel id="employee-selector-select-label">Employees</InputLabel>
        <Select
          labelId="employee-selector-select-label"
          value={value}
          variant="outlined"
          onChange={(
            event: React.ChangeEvent<{ name?: string; value: unknown }>
          ) => {
            if ((event.target.value as string).length === 0) {
              onChange(level, 0);
            }
            onChange(level, event.target.value as number);
          }}
          label="Employees"
        >
          {EMPLOYEE_OPTIONS.map((e) => (
            <MenuItem key={`item-${e}`} value={e}>
              {e}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText>Choose number of employees</FormHelperText>
      <ul className={"employee-selector-points"}>
        {points.map((p, index) => (
          <li key={index} className="employee-selector-point">
            {p}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export { EmployeeSelector };
